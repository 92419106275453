import { useNavigation } from "@react-navigation/native";

import { TutorialModal } from "./TutorialModal";
import { useCompleteTutorial } from "../../hooks/useCompleteTutorial";
import { ScreenNavigationProps } from "../../navigation/RootStackParamList";

type TutorialModalHomeProps = {
  refetch: () => void;
};

export function TutorialModalHome({ refetch }: TutorialModalHomeProps) {
  const { completeTutorial } = useCompleteTutorial();

  async function handleCompleteTutorial() {
    await completeTutorial();
    refetch();
  }

  const navigation = useNavigation<ScreenNavigationProps>();

  function handleNext() {
    navigation.navigate("Discover");
  }

  return (
    <TutorialModal
      handleNext={handleNext}
      pageName="Home"
      handleCompleteTutorial={handleCompleteTutorial}
      nextText="Next"
      descriptionText="Access your subscribed programs, pick up where you left off, discover recommendations, and explore top creators."
      screenNumber={1}
    />
  );
}
