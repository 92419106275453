import { Image } from "expo-image";
import { View, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";

type OneTimeProductRowProps = {
  priceString: string;
  selected: boolean;
  onPress: () => void;
};

export function OneTimeProductRow({
  priceString,
  selected,
  onPress,
}: OneTimeProductRowProps) {
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={onPress}>
      <View
        className={`bg-[#00000024] w-[95%] flex flex-col justify-center rounded-tl-3xl py-4 border-[2px] ${
          selected ? `border-[#80EFAD]` : `border-transparent`
        }`}
      >
        <View className="flex flex-row space-x-[10px] w-[90%] self-center">
          <Image
            style={{ width: 30, height: 30 }}
            source={require("../../../assets/icons/lightbulb.png")}
          />
          <View className="flex flex-col w-[90%] space-y-[20px]">
            <View className="flex flex-col justify-between w-[100%]">
              <Text className="font-unbounded text-white text-sm">
                One Time Purchase
              </Text>
              <Text className="font-unbounded text-white text-sm">
                {priceString}
              </Text>
            </View>
            <View>
              <Text className="text-white text-sm">
                • You own the material and can download it
              </Text>
              <Text className="text-white text-sm">
                • Unlimited access to this program
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}
