import { Image } from "expo-image";
import { useEffect } from "react";

import { useProgramPreviewQuery } from "../../graphql/generated";
import { stripHtml } from "../../lib/stripHtml";
import {
  getImagePreviewUrlMedium,
  getImagePreviewUrlSmall,
  getImagePreviewUrlThumbnail,
} from "../../utils/image-preview-url";
import { isNew } from "../../utils/is-new";
import { notEmpty } from "../../utils/not-empty";
import { useEpisodesProgressions } from "../useEpisodesProgressions";

export type ProgramPreview = {
  id: string;
  uuid: string;
  title: string;
  description: string;
  imageUrl: string;
  isAccessible: boolean;
  programProductId?: string;
  isNew: boolean;
  introVideoUrl?: string;
  previewAudioUrl?: string;
  isFree: boolean;
  isPreviewSubscriptionAvailable: boolean;
  priceInCents: number | null;
};

export type ProgramPreviewCreator = {
  id: string;
  uuid: string;
  name: string;
  bio: string;
  avatarUrl: string;
  programCount: number;
  listenerCount: number;
  hoursListened: number;
  memberSinceYear: number;
  creatorSubscriptionProductId?: string;
  location: string;
  subscriptionPriceInCents: number | null;
};

export type ProgramPreviewDocument = {
  uuid: string;
  title: string;
  description: string;
  imageUrl: string;
  fileUrl: string;
};

export type ProgramPreviewEpisode = {
  uuid: string;
  title: string;
  description: string;
  trackUrl: string;
  trackMime: string;
  imageUrl: string;
  imageUrlMedium: string;
  lengthInSeconds: number;
  episodeNumber: number;
  streams: number;
  progressPercentage: number;
  progressInSeconds: number;
  programUUID: string;
};

export function useProgramPreview(programUUID: string) {
  const { data, loading, refetch } = useProgramPreviewQuery({
    variables: {
      programUUID,
    },
    skip: !programUUID,
    fetchPolicy: "cache-and-network",
  });

  const programData = data?.programPreview;

  const program: ProgramPreview | undefined = programData
    ? {
        id: programData.id.toString(),
        uuid: programData.uuid,
        title: programData.title || "",
        description: stripHtml(programData.description || ""),
        imageUrl: programData.image
          ? getImagePreviewUrlSmall(programData.image)
          : "",
        isFree: programData.isFree || false,
        isAccessible:
          programData.isPurchased || programData.isSubscribed || false,
        programProductId: programData.revenueCatProductId ?? undefined,
        isNew: isNew(programData.publishedAt),
        introVideoUrl: programData.previewVideo?.url || "",
        previewAudioUrl: programData.previewAudio?.url || "",
        priceInCents:
          typeof programData.priceInCents === "number"
            ? programData.priceInCents
            : null,
        isPreviewSubscriptionAvailable:
          (programData.isPreviewSubscriptionAvailable &&
            !programData.isPurchased &&
            !programData.isSubscribed) ||
          false,
      }
    : undefined;

  const creatorData = programData?.creator;

  const creator: ProgramPreviewCreator | undefined = creatorData
    ? {
        id: creatorData.id.toString(),
        uuid: creatorData.uuid,
        name: creatorData.displayName || "",
        bio: creatorData.bio || "",
        location: creatorData.location || "",
        avatarUrl: creatorData.avatar
          ? getImagePreviewUrlThumbnail(creatorData.avatar)
          : "",
        programCount: creatorData.programCount,
        listenerCount: creatorData.listenerCount,
        hoursListened: creatorData.hoursListened || 0,
        memberSinceYear: new Date(
          creatorData.createdAt ?? "2023"
        ).getFullYear(),
        subscriptionPriceInCents:
          typeof creatorData.subscriptionPriceInCents === "number"
            ? creatorData.subscriptionPriceInCents
            : null,
        creatorSubscriptionProductId:
          creatorData.revenueCatProductId ?? undefined,
      }
    : undefined;

  const documents: ProgramPreviewDocument[] = (programData?.documents || [])
    .filter(notEmpty)
    .map((document) => ({
      uuid: document.uuid || "",
      title: document.title,
      imageUrl: document.thumbnail?.data?.attributes?.url || "",
      fileUrl: document.file?.data?.attributes?.url || "",
      description: document.description || "",
    }));

  const episodesWithoutProgression: ProgramPreviewEpisode[] = (
    programData?.episodes || []
  )
    .filter(notEmpty)
    .map((episode) => ({
      uuid: episode.uuid,
      title: episode.title,
      description: episode.description || "",
      trackUrl: episode.media?.url || "",
      trackMime: episode.media?.mime || "",
      imageUrl: episode.image ? getImagePreviewUrlThumbnail(episode.image) : "",
      imageUrlMedium: episode.image
        ? getImagePreviewUrlMedium(episode.image)
        : "",
      lengthInSeconds: episode.lengthInSeconds || 0,
      episodeNumber: episode.episodeNumber || 0,
      streams: episode.streams || 0,
      progressPercentage: 0,
      progressInSeconds: 0,
      programUUID,
    }))
    .filter((episode) => episode.trackUrl !== ""); // avoid episodes without audio so the playlist doesn't break

  const { episodes } = useEpisodesProgressions(episodesWithoutProgression);

  useEffect(() => {
    if (program?.imageUrl) {
      Image.prefetch(program.imageUrl);
    }
  }, [program]);

  useEffect(() => {
    if (creator?.avatarUrl) {
      Image.prefetch(creator?.avatarUrl);
    }
  }, [program]);

  useEffect(() => {
    Image.prefetch(episodes.map((episode) => episode.imageUrl));
  }, [episodes]);

  useEffect(() => {
    Image.prefetch(documents.map((document) => document.imageUrl));
  }, [documents]);

  return {
    creator,
    program,
    documents,
    episodes,
    loading,
    refetch,
  };
}
