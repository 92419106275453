import { Image } from "expo-image";
import { View, Text, TouchableOpacity } from "react-native";
import { Button } from "react-native-paper";

import Avatar, { ImageSize } from "./Avatar";

type StreakScreenProps = {
  onCancel: () => void;
  currentStreak: number;
  longestStreak: number;
  imageUrl: string;
};

export function StreakScreen({
  onCancel,
  currentStreak,
  longestStreak,
  imageUrl,
}: StreakScreenProps) {
  return (
    <View className="h-full bg-main w-full p-10 flex flex-col items-center justify-center">
      <TouchableOpacity onPress={onCancel} className="absolute top-14 left-4">
        <Image
          className="h-10 w-10"
          contentFit="contain"
          source={require("../../assets/icons/back.png")}
        />
      </TouchableOpacity>
      <Avatar imageSize={ImageSize.Medium} thumbnail={imageUrl} />
      <Text className="text-xl font-bold font-unbounded text-white text-center mt-6 max-w-sm">
        Streaks help you build your daily meditation habit.
      </Text>
      <View className="flex flex-row mt-4 justify-center w-full space-x-10">
        <View className="flex flex-col items-center py-4">
          <View className="flex flex-row items-center space-x-2">
            <View className="h-10">
              <Image
                className="h-[37px] w-[24px] my-auto"
                contentFit="contain"
                source={require("../../assets/icons/streak.png")}
              />
            </View>
            <Text className="text-2xl font-bold font-unbounded text-white">
              {currentStreak}
            </Text>
          </View>
          <Text className="text-xs text-gray-400 text-center mt-2">
            Current Streak
          </Text>
        </View>
        <View className="border-r-[1px] border-white" />
        <View className="flex flex-col items-center py-4">
          <View className="flex flex-row items-center space-x-2">
            <View className="h-10">
              <Image
                className="h-[28px] w-[30px] my-auto"
                contentFit="contain"
                source={require("../../assets/icons/star.png")}
              />
            </View>
            <Text className="text-2xl font-bold font-unbounded text-white">
              {longestStreak}
            </Text>
          </View>
          <Text className="text-xs text-gray-400 text-center mt-2">
            Best Streak
          </Text>
        </View>
      </View>
      <Button
        onPress={onCancel}
        className="bg-[#80EFAD] border-[1px] border-[#80EFAD] rounded-2xl w-80 mt-8"
      >
        <Text className="self-center text-black font-unbounded">Go Back</Text>
      </Button>
    </View>
  );
}
