import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import Purchases, {
  PurchasesStoreProduct,
  LOG_LEVEL,
  CustomerInfo,
} from "react-native-purchases";

import { envConfig } from "../../env.config";
import {
  CreatorForUserDocument,
  GetMyUserProfileInfoDocument,
  MyPurchasedCreatorsDocument,
  ProgramPreviewDocument,
  useValidatePurchaseForUserByProductIdMutation,
} from "../graphql/generated";
import { useUserProfile } from "../hooks/useUserProfile";
import { ErrorReportingService } from "../services/ErrorReportingService";
import { isIOS, isRunningInExpo } from "../utils/platform";

const { storeDebug, revenuecatApiKeyIos, revenuecatDebug, revenuecatDisabled } =
  envConfig;

type StorePurchaseCtx = {
  purchaseProduct: (product: PurchasesStoreProduct) => Promise<void>;
  getProducts: (productIds: string[]) => Promise<PurchasesStoreProduct[]>;
  managementUrl?: string;
  isConfigured: boolean;
};

const StorePurchaseContext = createContext<StorePurchaseCtx | null>(null);

async function purchaseStoreProduct(product: PurchasesStoreProduct) {
  try {
    return await Purchases.purchaseStoreProduct(product);
  } catch (err) {
    const sentryErrorMessage = `[StorePurchase] purchaseStoreProduct: failed for product(${product.identifier}), error: ${err?.message}`;
    const userErrorMessage = `Error: ${err?.message}`;

    // Do not report "Purchase was cancelled." error to Sentry
    if (err?.code !== 1) {
      alert(userErrorMessage);
      ErrorReportingService.report(err);
      ErrorReportingService.message(sentryErrorMessage);
    }
    throw err;
  }
}

async function checkIsConfigured() {
  try {
    return await Purchases.isConfigured();
  } catch (error) {
    const errorMessage = `[StorePurchase] isConfigured: Failed with error: ${error?.message}`;
    console.error(errorMessage, error);
    alert(errorMessage);
    ErrorReportingService.report(error);
    ErrorReportingService.message(errorMessage);
    throw error;
  }
}

async function getCustomerInfo() {
  try {
    return await Purchases.getCustomerInfo();
  } catch (error) {
    const errorMessage = `[StorePurchase] getCustomerInfo: Failed with error: ${error?.message}`;
    console.error(errorMessage, error);
    // Do not report "The receipt is missing." error to Sentry
    if (error?.code !== 9) {
      alert(errorMessage);
      ErrorReportingService.message(errorMessage);
    }
    return undefined;
  }
}

function configurePurchases(appUserID: string) {
  try {
    return Purchases.configure({
      apiKey: revenuecatApiKeyIos,
      appUserID,
      usesStoreKit2IfAvailable: false,
    });
  } catch (error) {
    const sentryErrorMessage = `[StorePurchase] Failed to configure: ${error?.message}. Using apikey: (${revenuecatApiKeyIos}) and appUserID: ${appUserID}`;
    const userErrorMessage = `Failed to configure: ${error?.message}.`;
    alert(userErrorMessage);
    ErrorReportingService.report(error);
    ErrorReportingService.message(sentryErrorMessage);
    throw error;
  }
}

export const StorePurchaseProvider: FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const inAppPurchasesAvailable = !isRunningInExpo() && isIOS;
  const [isConfigured, setIsConfigured] = useState(false);
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>();

  const managementUrl = customerInfo?.managementURL ?? undefined;

  const { userProfile } = useUserProfile();
  const appUserID = userProfile?.uuid;
  const [validatePurchase] = useValidatePurchaseForUserByProductIdMutation();

  useEffect(() => {
    async function loadPurchases() {
      if (!appUserID) {
        return;
      }

      if (!inAppPurchasesAvailable) {
        console.warn("[StorePurchase] inAppPurchasesAvailable not available");
        return;
      }

      if (revenuecatDisabled) {
        console.warn("[StorePurchase] Purchases disabled");
        return;
      }

      try {
        if (revenuecatDebug) {
          await Purchases.setLogLevel(LOG_LEVEL.DEBUG);
        }

        configurePurchases(appUserID);
        const result = await checkIsConfigured();
        setIsConfigured(result);
        const newCustomerInfo = await getCustomerInfo();
        setCustomerInfo(newCustomerInfo);
      } catch (error) {
        const errorMessage = `[StorePurchase] Failed to configure: ${error?.message}. Using apikey: (${revenuecatApiKeyIos}) and appUserID: ${appUserID}`;
        console.error(errorMessage, error);
        alert(errorMessage);
        setIsConfigured(false);
        ErrorReportingService.report(error);
        ErrorReportingService.message(errorMessage);
      }
    }

    void loadPurchases();
  }, [inAppPurchasesAvailable, appUserID]);

  async function getProducts(productIds: string[]) {
    storeDebug && console.log("[StorePurchase] product ids", productIds);
    if (!isConfigured) {
      const errorMessage = `[StorePurchase] getProducts: Purchases not configured`;
      console.error(errorMessage);
      ErrorReportingService.message(errorMessage);
      return [];
    }

    const products = await Purchases.getProducts(productIds);

    storeDebug && console.log("[StorePurchase] getProducts", products);

    return products;
  }

  async function purchaseProduct(product: PurchasesStoreProduct) {
    if (!isConfigured) {
      console.error(
        "[StorePurchase] purchaseProduct: Purchases not configured"
      );
      throw new Error("Purchases not configured");
    }

    const purchase = await purchaseStoreProduct(product);

    if (!customerInfo) {
      const newCustomerInfo = await getCustomerInfo();
      setCustomerInfo(newCustomerInfo);
    }

    try {
      await validatePurchase({
        variables: {
          productId: purchase.productIdentifier,
        },
        refetchQueries: [
          GetMyUserProfileInfoDocument,
          ProgramPreviewDocument,
          CreatorForUserDocument,
          MyPurchasedCreatorsDocument,
        ],
      });
    } catch (error) {
      const errorMessage = `[StorePurchase] purchaseProduct: validatePurchase for product ${purchase.productIdentifier} failed with error: ${error?.message}`;
      alert(errorMessage);
      ErrorReportingService.report(error);
      ErrorReportingService.message(errorMessage);
      throw error;
    }
  }

  const context: StorePurchaseCtx = {
    purchaseProduct,
    getProducts,
    managementUrl,
    isConfigured,
  };

  return (
    <StorePurchaseContext.Provider value={context}>
      {children}
    </StorePurchaseContext.Provider>
  );
};

export function useStorePurchase() {
  const context = useContext(StorePurchaseContext);
  if (!context) {
    throw new Error(
      "useStorePurchase must be used within a StorePurchaseProvider"
    );
  }
  return context;
}
