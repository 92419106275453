import {
  Enum_Flagcontent_Reason,
  useFlagContentMutation,
} from "../graphql/generated";

type CreateFlagContentProps = {
  reason: Enum_Flagcontent_Reason;
  comment: string;
  programUUID: string;
  episodeUUID: string;
  creatorProfileUUID: string;
};

export function useCreateFlagContent({
  reason,
  comment,
  programUUID,
  episodeUUID,
  creatorProfileUUID,
}: CreateFlagContentProps) {
  const [createFlagContent, { error: createFlagContentError }] =
    useFlagContentMutation({
      onError: (error) => console.log("error flagging content", error),
      variables: {
        creatorProfileUUID,
        programUUID,
        episodeUUID,
        reason,
        comment,
      },
    });
  return {
    createFlagContent,
    createFlagContentError,
  };
}
