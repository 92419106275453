import { useProgress as useNativeTrackProgress } from "../../lib/TrackPlayerWrapper/TrackPlayerWrapper";

export function useProgress() {
  const progress = useNativeTrackProgress();

  const percentage =
    progress.duration === 0 ? 0 : (progress.position / progress.duration) * 100;

  return {
    ...progress,
    percentage,
  };
}
