import { registerRootComponent } from "expo";

import App from "./App";
import TrackPlayer from "./src/lib/TrackPlayerWrapper/TrackPlayerWrapper";
import { PlaybackService } from "./src/services/PlaybackService";

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);

// Register playback service to allow background playback & locked screen controls
TrackPlayer.registerPlaybackService(() => PlaybackService);
