import { Image } from "expo-image";
import { View, TouchableOpacity } from "react-native";

import { usePlayerPlay } from "../../hooks/audio-player/usePlayerPlay";
import TrackPlayer, {
  useProgress,
  RepeatMode,
} from "../../lib/TrackPlayerWrapper/TrackPlayerWrapper";
import { useMyPlayer } from "../../providers/FullscreenPlayerProvider";

const activeOpacity = 0.75;

export function FullscreenControls() {
  const { play } = usePlayerPlay();
  const {
    playlist,
    currentTrackIndex,
    isPlaying,
    isPaused,
    isLoading,
    toggleRepeatMode,
    repeatMode,
  } = useMyPlayer();
  const { position, duration } = useProgress();

  function rewindToStart() {
    return TrackPlayer.seekTo(0);
  }

  function rewind() {
    const seekPosition = Math.max(position - 10, 0);
    return TrackPlayer.seekTo(seekPosition);
  }

  function forward() {
    const seekPosition = Math.min(position + 10, duration);
    return TrackPlayer.seekTo(seekPosition);
  }

  const episodesCount = playlist.length;
  const currentEpisodeNumber = currentTrackIndex + 1;

  const nextDisabled = currentEpisodeNumber === episodesCount || isLoading;
  const prevDisabled = currentEpisodeNumber === 1 || isLoading;
  const seekingDisabled = isLoading;

  return (
    <View className="w-full flex flex-row items-center justify-between">
      {/* Rewind to start button */}
      <TouchableOpacity
        onPress={rewindToStart}
        activeOpacity={activeOpacity}
        disabled={seekingDisabled}
      >
        <Image
          className={`h-[25px] w-[27px] relative rounded-full mx-1.5 ${
            seekingDisabled ? "opacity-50" : ""
          }`}
          source={require("../../../assets/player/rewind-button.png")}
        />
      </TouchableOpacity>

      {/* Prev button */}
      <TouchableOpacity
        onPress={() => TrackPlayer.skipToPrevious()}
        disabled={prevDisabled}
        activeOpacity={activeOpacity}
      >
        <Image
          className={`h-[25px] w-[25px] relative rounded-full mx-1.5 ${
            prevDisabled ? "opacity-50" : ""
          }`}
          source={require("../../../assets/player/prev-button.png")}
        />
      </TouchableOpacity>

      {/* Rewind button */}
      <TouchableOpacity
        onPress={rewind}
        activeOpacity={activeOpacity}
        disabled={seekingDisabled}
      >
        <Image
          className={`h-[25px] w-[25px] relative rounded-full mx-1.5 ${
            seekingDisabled ? "opacity-50" : ""
          }`}
          source={require("../../../assets/player/rewind10-button.png")}
        />
      </TouchableOpacity>

      {/* Play button */}
      {isPaused && (
        <TouchableOpacity
          onPress={() => play({ playlist, position })}
          activeOpacity={activeOpacity}
        >
          <Image
            className="h-[60px] w-[60px] relative rounded-full mx-1.5"
            source={require("../../../assets/player/play-button-medium.png")}
          />
        </TouchableOpacity>
      )}

      {/* Loading spinner */}
      {isLoading && (
        <Image
          className="h-[60px] w-[60px] relative rounded-full mx-1.5"
          source={require("../../../assets/icons/loading-spinner.gif")}
        />
      )}

      {/* Pause button */}
      {isPlaying && (
        <TouchableOpacity
          onPress={() => TrackPlayer.pause()}
          activeOpacity={activeOpacity}
        >
          <Image
            className="h-[60px] w-[60px] relative rounded-full mx-1.5"
            source={require("../../../assets/player/pause-button-medium.png")}
          />
        </TouchableOpacity>
      )}

      {/* Forward button */}
      <TouchableOpacity
        onPress={forward}
        activeOpacity={activeOpacity}
        disabled={seekingDisabled}
      >
        <Image
          className={`h-[25px] w-[25px] relative rounded-full mx-1.5 ${
            seekingDisabled ? "opacity-50" : ""
          }`}
          source={require("../../../assets/player/forward10-button.png")}
        />
      </TouchableOpacity>

      {/* Next button */}
      <TouchableOpacity
        onPress={() => TrackPlayer.skipToNext()}
        disabled={nextDisabled}
        activeOpacity={activeOpacity}
      >
        <Image
          className={`h-[25px] w-[25px] relative rounded-full mx-1.5 ${
            nextDisabled ? "opacity-50" : ""
          }`}
          source={require("../../../assets/player/next-button.png")}
        />
      </TouchableOpacity>

      {/* Repeat */}
      <TouchableOpacity
        onPress={toggleRepeatMode}
        className="flex flex-row justify-center"
      >
        {(repeatMode === RepeatMode.Off || repeatMode === RepeatMode.Queue) && (
          <Image
            className="h-[24px] w-[23px]"
            cachePolicy="memory"
            contentFit="contain"
            source={require("../../../assets/player/repeat.png")}
          />
        )}
        {repeatMode === RepeatMode.Track && (
          <Image
            className="h-[24px] w-[23px]"
            cachePolicy="memory"
            contentFit="contain"
            source={require("../../../assets/player/repeat-active.png")}
          />
        )}
      </TouchableOpacity>
    </View>
  );
}
