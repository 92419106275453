import { format } from "date-fns";
import { Image } from "expo-image";
import { View, Text, ImageBackground } from "react-native";

import { ChallengeDetails } from "../../graphql/generated";
import { Button } from "../Button";

export type ChallengeWelcomeScreenProps = {
  onSubmit: () => void;
  challenge?: ChallengeDetails;
};

function ChallengeWelcomeScreen({
  onSubmit,
  challenge,
}: ChallengeWelcomeScreenProps) {
  if (!challenge) {
    return null;
  }

  const { challengeStartDate, promotionImageUrl, promotionName, creatorName } =
    challenge;

  const formattedDate = format(
    new Date(challengeStartDate),
    "eeee, MMM do, 'at' h b"
  );
  return (
    <ImageBackground
      className="h-full bg-black w-full p-10"
      source={require("../../../assets/invite-background.png")}
    >
      <Image
        className="mt-10 h-8 w-32 mr-auto"
        contentFit="contain"
        source={require("../../../assets/peak-logo-white.png")}
      />
      <View className="flex flex-col justify-center h-full gap-10 items-center">
        <Text className="text-2xl font-unbounded text-white">
          Welcome aboard! 🎉
        </Text>
        {promotionImageUrl && (
          <Image
            className="h-52 w-52"
            contentFit="contain"
            source={promotionImageUrl}
          />
        )}
        <View className="flex flex-col space-y-4 px-8 justify-center items-center">
          <Text className="text-md text-white text-center">
            Thank you for joining{" "}
            <Text className="font-bold">{promotionName}</Text> Challenge by{" "}
            <Text className="font-bold">{creatorName}</Text>.
          </Text>
          <Text className="text-md text-white text-center">
            The challenge will start on{"\n"}
            <Text className="font-bold">{formattedDate}.</Text>
          </Text>
          <Text className="text-md text-white text-center">
            All your bonus programs are already available on your account. Enjoy
            this transformative experience!
          </Text>
        </View>
        <Button
          type="primary"
          text="Get Started"
          className="px-24 py-0.5"
          onPress={onSubmit}
        />
      </View>
    </ImageBackground>
  );
}

export default ChallengeWelcomeScreen;
