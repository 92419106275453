import {
  CreatorForUserDocument,
  GetMyUserProfileInfoDocument,
  MyPurchasedCreatorsDocument,
  ProgramPreviewDocument,
  useCreatePreviewSubscriptionMutation,
} from "../graphql/generated";
import { ErrorReportingService } from "../services/ErrorReportingService";

type useCreatePreviewSubscriptionProps = {
  creatorUUID: string;
  onRefetch: () => void;
};

export function useCreatePreviewSubscription({
  creatorUUID,
  onRefetch,
}: useCreatePreviewSubscriptionProps) {
  const [
    createPreviewSubscription,
    { loading: createPreviewSubscriptionLoading },
  ] = useCreatePreviewSubscriptionMutation({
    variables: {
      creatorUUID,
    },
    refetchQueries: [
      GetMyUserProfileInfoDocument,
      ProgramPreviewDocument,
      CreatorForUserDocument,
      MyPurchasedCreatorsDocument,
    ],
    onCompleted: () => {
      void onRefetch();
    },
    onError: (e) => {
      ErrorReportingService.report(e);
      console.log("Error creating preview subscription", e);
      alert("Error creating preview subscription");
      void onRefetch();
    },
  });

  return {
    createPreviewSubscription,
    createPreviewSubscriptionLoading,
  };
}
