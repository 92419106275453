import { loadAsync } from "expo-font";
import * as Notifications from "expo-notifications";
import * as SplashScreen from "expo-splash-screen";
import * as SystemUI from "expo-system-ui";
import { NativeWindStyleSheet } from "nativewind";
import { useEffect, useState } from "react";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { Provider as PaperProvider } from "react-native-paper";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { StorePurchaseProvider } from "./src/contexts/StorePurchasesContext";
import { usePWABanner } from "./src/hooks/usePWABanner";
import { setupAudioPlayer } from "./src/lib/SetupAudioPlayer";
import { DefaultNavigationContainer } from "./src/navigation/DefaultNavigationContainer";
import { AppApolloProvider } from "./src/providers/AppApolloProvider";
import { FullscreenPlayerProvider } from "./src/providers/FullscreenPlayerProvider";
import { PushNotificationProvider } from "./src/providers/PushNotificationProvider";
import { store, persistor } from "./src/redux/Store";
import { ErrorReportingService } from "./src/services/ErrorReportingService";
import { cacheAssets } from "./src/utils/cache-assets";

NativeWindStyleSheet.setOutput({
  default: "native",
});

ErrorReportingService.init();

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false);

  const { banner } = usePWABanner();

  // Load any resources or data that you need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        await SplashScreen.preventAutoHideAsync();
        await SystemUI.setBackgroundColorAsync("black");
        const imageAssets = cacheAssets([
          require("./assets/player/play-button-small.png"), // Visible on home page
        ]);

        const fontPromise = loadAsync({
          Unbounded: require("./assets/fonts/Unbounded-VariableFont_wght.ttf"),
        });

        const playerPromise = setupAudioPlayer();

        await Promise.all([imageAssets, fontPromise, playerPromise]);
      } catch (e) {
        // You might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setAppIsReady(true);
        void SplashScreen.hideAsync();
      }
    }

    void loadResourcesAndDataAsync();
  }, []);

  if (!appIsReady) {
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppApolloProvider>
          <GestureHandlerRootView style={{ flex: 1 }}>
            <StorePurchaseProvider>
              <PaperProvider>
                <PushNotificationProvider>
                  <FullscreenPlayerProvider>
                    {banner}
                    <DefaultNavigationContainer />
                  </FullscreenPlayerProvider>
                </PushNotificationProvider>
              </PaperProvider>
            </StorePurchaseProvider>
          </GestureHandlerRootView>
        </AppApolloProvider>
      </PersistGate>
    </Provider>
  );
}
