import { useResetPasswordMutation } from "../../graphql/generated";

type ResetPasswordProps = {
  newPassword1: string;
  newPassword2: string;
  resetToken: string;
};

export function useResetPassword({
  newPassword1,
  newPassword2,
  resetToken,
}: ResetPasswordProps) {
  const [resetPassword] = useResetPasswordMutation({
    variables: {
      newPassword1,
      newPassword2,
      resetToken,
    },
  });
  return {
    resetPassword,
  };
}
