import { useNavigation } from "@react-navigation/native";
import * as AppleAuthentication from "expo-apple-authentication";
import { makeRedirectUri } from "expo-auth-session";
import * as Google from "expo-auth-session/providers/google";
import { Image } from "expo-image";
import * as WebBrowser from "expo-web-browser";
import { useState, useEffect } from "react";
import { View, StatusBar, Text, ImageBackground, Platform } from "react-native";
import { Button, Snackbar, Portal } from "react-native-paper";

import { envConfig } from "../../env.config";
import TermsAndPrivacy from "../components/TermsAndPrivacy";
import { useLogin } from "../hooks/useLogin";
import { LoginScreenNavigationProps } from "../navigation/RootStackParamLoginList";

const { webClientId, expoClientId } = envConfig;

WebBrowser.maybeCompleteAuthSession();

function Prelogin() {
  const [visible, setVisible] = useState(false);
  const [error] = useState(false);

  const navigation = useNavigation<LoginScreenNavigationProps>();

  const { googleLogin, appleLogin } = useLogin();

  const [request, response, promptAsync] = Google.useAuthRequest({
    webClientId,
    expoClientId,
    iosClientId: expoClientId,
    scopes: ["email"],
    redirectUri: makeRedirectUri(),
  });

  useEffect(() => {
    if (response) {
      if (response.type === "success") {
        const token =
          response &&
          response.authentication &&
          response.authentication.accessToken;
        if (!token) {
          console.log("No token, likely cancelled");
        } else {
          void googleLogin(token);
        }
      } else if (response.type === "cancel") {
        console.log("response type cancel");
      }
    }
  }, [response]);

  async function onPressGoogleLogin() {
    try {
      await promptAsync();
    } catch (error) {
      console.log(error);
    }
  }

  async function onAppleLoginPress() {
    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [AppleAuthentication.AppleAuthenticationScope.EMAIL],
      });
      // signed in
      const { identityToken } = credential;
      console.log("identityToken", identityToken);

      if (!identityToken) {
        throw new Error("No apple identity token");
      }

      void appleLogin(identityToken);
    } catch (e) {
      if (e.code === "ERR_REQUEST_CANCELED") {
        // handle that the user canceled the sign-in flow
        console.log("user cancelled");
      } else {
        // handle other errors
        console.log("Apple auth error", e);
      }
    }
  }

  return (
    <View className="bg-black h-full">
      <ImageBackground
        className="h-full bg-black"
        source={require("../../assets/login-background.png")}
      >
        <View className="h-full flex flex-col mx-10 pt-20 md:mt-40 md:ml-40 md:max-w-sm">
          <View className="h-[24px]">
            <Image
              style={{ width: 147, height: 24 }}
              source={require("../../assets/peak-logo-white.png")}
            />
          </View>
          <StatusBar backgroundColor="#FFFFFF" barStyle="dark-content" />
          <View className="flex flex-col mt-12 w-3/4 justify-start space-y-3">
            <Text className="-ml-1 font-unbounded text-white text-xl">
              Welcome Back
            </Text>
            <Text className="font-inter text-white text-sm">
              Sign in with your social account or enter your credentials to log
              in
            </Text>
          </View>
          <View className="flex flex-col justify-between mt-4 flex-1 md:flex-grow-0 pb-10">
            <View className="flex flex-col justify-start">
              {Platform.OS === "web" ? (
                <Button
                  className="rounded-none bg-[#f2f2f2] border-solid border-black h-10"
                  disabled={!request}
                  onPress={onPressGoogleLogin}
                >
                  <View className="flex flex-row w-full h-7 overflow-hidden">
                    <Image
                      className="w-52 h-11 -mt-2.5"
                      contentFit="contain"
                      source={require("../../assets/singinwithgoogle.png")}
                    />
                  </View>
                </Button>
              ) : (
                <Button
                  className="rounded-none bg-[#f2f2f2] border-solid border-black h-10"
                  disabled={!request}
                  onPress={onPressGoogleLogin}
                >
                  <View className="flex flex-row w-full h-10">
                    <Image
                      className="w-52 h-11 -mt-2"
                      contentFit="contain"
                      source={require("../../assets/singinwithgoogle.png")}
                    />
                  </View>
                </Button>
              )}
              {Platform.OS === "ios" && (
                <Button
                  icon="apple"
                  labelStyle={{ color: "#FFFFFF" }}
                  className="rounded-none bg-[#305394] text-[#FFFFFF] border-solid border-black mt-3"
                  textColor="#FFFFFF"
                  disabled={!request}
                  onPress={onAppleLoginPress}
                >
                  <Text className="font-unbounded self-center">
                    Sign In With Apple
                  </Text>
                </Button>
              )}
              <Button
                icon="email-outline"
                labelStyle={{ color: "#FFFFFF" }}
                className="rounded-none bg-[#1D2033] border-solid border-black mt-3"
                textColor="#FFFFFF"
                onPress={() => navigation.navigate("SignIn")}
              >
                <Text className="font-unbounded self-center">
                  Sign In With Username/Email
                </Text>
              </Button>
            </View>
            <View className="flex flex-col md:mt-16">
              <Button
                labelStyle={{ color: "#000000" }}
                className="rounded-none bg-[#80EFAD] border-solid border-black mt-3"
                onPress={() => navigation.navigate("Signup")}
              >
                <Text className="font-unbounded self-center">Sign Up</Text>
              </Button>
              <TermsAndPrivacy />
            </View>
          </View>
          {/**
           * We use a portal component to render
           * the snackbar on top of everything else
           * */}
          <Portal>
            <Snackbar visible={visible} onDismiss={() => setVisible(false)}>
              {error}
            </Snackbar>
          </Portal>
        </View>
      </ImageBackground>
    </View>
  );
}

export default Prelogin;
