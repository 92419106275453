import { Image } from "expo-image";
import { ImageBackground, View } from "react-native";
import { Button, Text } from "react-native-paper";

type PushNotificationScreenProps = {
  onSubmit: () => void;
  onCancel: () => void;
};

function PushNotificationScreen({
  onSubmit,
  onCancel,
}: PushNotificationScreenProps) {
  return (
    <ImageBackground
      className="h-full w-full bg-[#151827] pt-10 flex flex-col"
      source={require("../../assets/invite-background.png")}
    >
      <Image
        className="ml-8 mt-8 h-8 w-32 mr-auto"
        contentFit="contain"
        source={require("../../assets/peak-logo-white.png")}
      />

      <View className="flex flex-col flex-0 my-auto">
        <Image
          className="h-40 w-48 mx-auto"
          contentFit="contain"
          source={require("../../assets/push-notification-logo.png")}
        />
        <Text className="text-2xl font-unbounded text-white text-center mt-8">
          Enable Notifications
        </Text>
        <Text className="text-md font-unbounded text-white text-center mt-6 px-[10%]">
          Stay on track with your mindfulness journey! Enable notifications for
          event updates, progress tracking, and timely reminders.
        </Text>
        <Button
          onPress={onSubmit}
          className="rounded-2xl text-black w-48 mx-auto mt-8 bg-[#80EFAD]"
        >
          <Text className="text-[16px] font-bold">Allow</Text>
        </Button>
        <Text
          onPress={onCancel}
          className="text-sm font-unbounded mt-5 text-slate-400 mx-auto font-thin"
        >
          Maybe later
        </Text>
      </View>
    </ImageBackground>
  );
}
export default PushNotificationScreen;
