import { useGetProgramsAndEpisodesQuery } from "../graphql/generated";
import { getImagePreviewUrlSmall } from "../utils/image-preview-url";
import { isNew } from "../utils/is-new";
import { notEmpty } from "../utils/not-empty";

export function useGetMyProgramsAndEpisodes() {
  const { data, loading, refetch } = useGetProgramsAndEpisodesQuery({
    fetchPolicy: "cache-and-network",
  });

  const programsData =
    data?.myProgramsAndEpisodes.programs.filter(notEmpty) || [];
  const programs = programsData.map((row) => ({
    uuid: row.uuid || "",
    title: row.title || "",
    imageUrl: row.image?.data?.attributes
      ? getImagePreviewUrlSmall(row.image?.data?.attributes)
      : "",
    isAccessible: true,
    episodesCount: row.episodes?.data.length || 0,
    publishedAt: row.publishedAt,
    programProductId: row.revenueCatProductId || undefined,
    isNew: isNew(row.publishedAt),
    priceInCents:
      typeof row.priceInCents === "number" ? row.priceInCents : null,
    isFree: row.isFree || false,
  }));

  return {
    programs,
    loading,
    refetch,
  };
}
