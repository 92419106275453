import { ApolloClient, InMemoryCache, ServerError } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { envConfig } from "../../env.config";
import { RootState } from "../redux/Store";
import { deleteUser } from "../redux/actions/UserActions";
import { User } from "../redux/reducers/UserReducer";
import { ErrorReportingService } from "../services/ErrorReportingService";

const { backendUrl } = envConfig;

function getHttpLink(authToken: string | null) {
  return createUploadLink({
    uri: `${backendUrl}/graphql`,
    headers: authToken
      ? {
          authorization: `Bearer ${authToken}`,
        }
      : {},
  });
}

const cache = new InMemoryCache({});

export function useAppApolloClient() {
  const jwt = useSelector<RootState, string | null>((state) => state.jwt);
  const user = useSelector<RootState, User | null>((state) => state.user);

  useEffect(() => {
    if (!user) return;
    ErrorReportingService.setUser(user);
  }, [user]);

  useEffect(() => {
    // Reset the cache when the jwt changes to avoid data leaking between users
    void cache.reset();
  }, [jwt]);

  return new ApolloClient({
    uri: `${backendUrl}/graphql`,
    cache,
    link: errorLink.concat(getHttpLink(jwt)),
  });
}

const errorLink = onError(({ graphQLErrors, operation, networkError }) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      const { code, action } = error.extensions;

      // Error message for GraphQL errors
      const message = `[ApolloClient] GraphQL error. Message: ${
        error.message
      }, Location: ${JSON.stringify(error.locations)}, Path: ${
        error.path
      }, Extensions: ${code}, ${action}`;

      // Log it
      console.error(message);

      switch (action) {
        case "LOGOUT":
          console.info(
            "[ApolloClient] Force logout",
            `operation=${operation.operationName}`
          );
          return deleteUser();
      }

      switch (code) {
        case "UNAUTHENTICATED":
          console.info(
            "[ApolloClient] Force logout",
            `operation=${operation.operationName}`
          );
          return deleteUser();
      }
    }
  }

  if (networkError) {
    if ((networkError as ServerError).statusCode === 401) {
      console.info(
        "[ApolloClient] Force logout",
        `operation=${operation.operationName} - code 401`
      );
      return deleteUser();
    }
  }
});
