import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import * as ImagePicker from "expo-image-picker";
import { useEffect, useState } from "react";
import {
  ScrollView,
  View,
  Text,
  TextInput,
  KeyboardAvoidingView,
} from "react-native";
import { Button, IconButton } from "react-native-paper";

import Avatar, { ImageSize } from "../components/Avatar";
import { AudioPlayer } from "../components/audio-player/AudioPlayer";
import { ConfirmDeactivateModal } from "../components/settings-page/ConfirmDeactivateModal";
import { DiscordLinking } from "../components/settings-page/DiscordLinking";
import { useUpdateMyUserProfileMutation } from "../graphql/generated";
import { useStrapiUpload } from "../hooks/useStrapiUpload";
import { useUserProfile } from "../hooks/useUserProfile";
import {
  EditProfileScreenProps,
  ScreenNavigationProps,
} from "../navigation/RootStackParamList";
import { store } from "../redux/Store";

function EditProfile({ route }: EditProfileScreenProps) {
  const { userBio, userLocation, userUsername } = route.params;
  const [username, setUsername] = useState<string>(userUsername);
  const [bio, setBio] = useState<string>(userBio);
  const [location, setLocation] = useState<string>(userLocation);
  const [status, requestPermission] = ImagePicker.useCameraPermissions();
  const [uploadFileId, setUploadFileId] = useState<string>();
  const [editFileThumbnail, setEditFileThumbnail] = useState<string>();
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [userImage, setUserImage] = useState<string>();
  const [userImageId, setUserImageId] = useState<string>();

  const { uploadToStrapi } = useStrapiUpload();

  const navigation = useNavigation<ScreenNavigationProps>();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const { userProfile, refetch: userProfileRefetch } = useUserProfile();

  const [updateMyProfileMutation] = useUpdateMyUserProfileMutation({
    variables: {
      ...(uploadFileId || userImageId
        ? { image: uploadFileId || userImageId }
        : {}),
      bio: bio || "",
      location: location || "",
      username: username || "",
      completedTutorial: userProfile?.completedTutorial || false,
      isFirstTimeUse: userProfile?.isFirstTimeUse || false,
    },
    onCompleted: (useUpdateMyUserProfileMutation) => {
      // TODO fixme - should update all fields? or just refetech whole user profile?
      setUserImage(
        useUpdateMyUserProfileMutation?.updateMyUserProfile?.image?.data
          ?.attributes?.url
      );
    },
  });

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    if (status) {
      // TODO fixme - add error handling
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      requestPermission();
    }
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });
    if (!result.canceled) {
      if (result.assets[0]) {
        setIsLoadingSpinner(true);
        const uploadRes = (await uploadToStrapi({
          resource_uri: result.assets[0].uri,
        }).catch((err) => {
          console.log("error check", err.networkError.result.errors);
        })) as { body: string };
        const parsedRes = JSON.parse(uploadRes.body);
        setUploadFileId(parsedRes[0].id);
        setEditFileThumbnail(parsedRes[0].url);
        setIsLoadingSpinner(false);
      }
    }
  };

  useEffect(() => {
    if (userProfile) {
      if (userProfile.image && userProfile.image.data) {
        setUserImageId(`${userProfile.image.data.id}`);
      }
      if (userProfile.image && userProfile.image.data?.attributes) {
        setUserImage(userProfile.image.data.attributes.url);
      }
    }
  }, [userProfile]);

  async function handleOnEdit() {
    try {
      await updateMyProfileMutation();
      const new_user = store.getState().user;
      if (new_user) {
        new_user.thumbnail = `${userImage}`;
        new_user.location = `${userLocation}`;
        new_user.description = `${userBio}`;
        new_user.username = `${username}`;
      }
      await userProfileRefetch();
      navigation.navigate("Account");
      return true;
    } catch (err) {
      alert(err.message);
      console.log("edit profile: ", err.message);
    }
  }

  async function handleOnCancel() {
    try {
      navigation.navigate("Account");
      return true;
    } catch (err) {
      console.log("edit profile: ", err);
    }
  }

  return (
    <View className="h-full w-full bg-main">
      <>
        <ScrollView
          scrollEnabled={!showConfirmDeleteModal}
          scrollEventThrottle={16}
          className="pt-[80px] mb-[10px]"
        >
          <KeyboardAvoidingView style={{ flex: 1 }} behavior="position">
            <View className="relative w-full z-100 flex flex-col justify-start space-y-[2px] bg-main pb-[100px]">
              <IconButton
                iconColor="black"
                className="ml-3 mt-[10px] bg-[#80EFAD]"
                onPress={() => handleOnCancel()}
                icon="arrow-left"
              />
              <Text className="font-unbounded text-white text-lg self-start mt-[2px] px-[20px] py-[10px]">
                Edit Profile
              </Text>
              {!isLoadingSpinner && (
                <View className="flex flex-col justify-center w-full mb-[20px]">
                  <View className="flex flex-row justify-center">
                    <Avatar
                      imageSize={ImageSize.Large}
                      thumbnail={
                        editFileThumbnail ? editFileThumbnail : userImage
                      }
                    />
                  </View>
                  <View className="flex flex-row justify-center -mt-[45px]">
                    <IconButton
                      iconColor="black"
                      className="h-[40px] w-[40px] self-center bg-[#80EFAD] ml-[80px]"
                      onPress={pickImage}
                      icon="camera"
                    />
                  </View>
                </View>
              )}
              {isLoadingSpinner && (
                <View className="h-[130px] w-full flex flex-row justify-center mt-[60px] self-center mb-[30px]">
                  <Image
                    className="w-10 h-10 self-center"
                    source={{
                      uri: "https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif",
                    }}
                  />
                </View>
              )}
              <View>
                <View className="mb-7 px-3">
                  <DiscordLinking />
                </View>
                <Text className="text-[#C4C6D4] w-[90%] self-center">
                  Username
                </Text>
                <TextInput
                  autoCapitalize="none"
                  className="bg-[#FFFFFF00] text-white w-[90%] self-center h-[50px] border-b border-1 border-[#FFFFFF80] font-unbounded mb-3"
                  value={username}
                  onChangeText={(value) =>
                    setUsername(value.toLocaleLowerCase())
                  }
                  selectionColor="#FFFFFF"
                />
              </View>
              <View>
                <Text className="text-[#C4C6D4] w-[90%] self-center">
                  Location
                </Text>
                <TextInput
                  className="bg-[#FFFFFF00] text-white w-[90%] self-center h-[50px] border-b border-1 border-[#FFFFFF80] font-unbounded mb-3"
                  value={location}
                  onChangeText={(value) => setLocation(value)}
                  selectionColor="#FFFFFF"
                />
              </View>
              <View className="mb-[15px]">
                <Text className="text-[#C4C6D4] w-[90%] self-center mt-[10px]">
                  Bio
                </Text>
                <TextInput
                  multiline
                  maxLength={200}
                  className="bg-[#FFFFFF00] text-white w-[90%] self-center h-[100px] border-b border-1 border-[#FFFFFF80] font-unbounded"
                  onChangeText={(value) => setBio(value)}
                  value={bio}
                  selectionColor="#FFFFFF"
                />
              </View>
              <View className="flex flex-row justify-end w-full space-x-2 pr-[20px]">
                <Button
                  onPress={() => handleOnCancel()}
                  className="px-1 bg-transparent border border-1 border-[#80EFAD] "
                >
                  <Text className="text-[#80EFAD] font-unbounded text-xs">
                    Cancel
                  </Text>
                </Button>
                <Button
                  onPress={() => handleOnEdit()}
                  className={`px-1 ${
                    isLoadingSpinner ? `bg-gray-500` : `bg-[#80EFAD]`
                  }`}
                  disabled={isLoadingSpinner}
                >
                  <Text className="text-black font-unbounded text-xs">
                    Submit Changes
                  </Text>
                </Button>
              </View>
            </View>
          </KeyboardAvoidingView>
        </ScrollView>
        <Button
          onPress={() => setShowConfirmDeleteModal(true)}
          className="px-1 absolute bottom-[0px] rounded-none left-0"
          disabled={isLoadingSpinner}
        >
          <Text className="text-red-500 font-unbounded text-xs">
            Delete Account
          </Text>
        </Button>
        {showConfirmDeleteModal === true && (
          <ConfirmDeactivateModal
            setShowConfirmDeleteModal={setShowConfirmDeleteModal}
          />
        )}
      </>
      <AudioPlayer />
    </View>
  );
}

export default EditProfile;
