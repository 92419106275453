import React from "react";
import { View } from "react-native";

import LoadingSpinner from "./LoadingSpinner";

function LoadingScreen() {
  return (
    <View className="h-full w-full flex flex-col justify-center">
      <LoadingSpinner />
    </View>
  );
}

export default LoadingScreen;
