import { Image } from "expo-image";
import { useEffect } from "react";

import { useSearchCreatorsByDisplayNameQuery } from "../../graphql/generated";
import { getImagePreviewUrlThumbnail } from "../../utils/image-preview-url";
import { notEmpty } from "../../utils/not-empty";

export function useDiscoverCreators(name?: string) {
  const { data, loading, refetch } = useSearchCreatorsByDisplayNameQuery({
    variables: {
      displayName: name,
      pagination: { pageSize: 12 },
    },
    onError: (err) => console.error("Error fetching creators", err),
  });

  const creatorsData =
    data?.searchCreatorsByDisplayName?.data?.filter(notEmpty) || [];

  const creators = creatorsData.map((creator) => ({
    uuid: creator.uuid,
    name: creator.displayName,
    isSubscribed: creator.isSubscribed,
    avatarUrl: creator.avatar
      ? getImagePreviewUrlThumbnail(creator.avatar)
      : "",
  }));

  useEffect(() => {
    Image.prefetch(creators.map((creator) => creator.avatarUrl));
  }, [creators]);

  return {
    creators,
    loading,
    refetch,
  };
}
