import { Image } from "expo-image";
import { useEffect } from "react";

import { useMyPurchasedCreatorsQuery } from "../../graphql/generated";
import { getImagePreviewUrlThumbnail } from "../../utils/image-preview-url";
import { notEmpty } from "../../utils/not-empty";

export function usePurchasedCreators() {
  const { data, refetch, loading } = useMyPurchasedCreatorsQuery({
    fetchPolicy: "cache-and-network",
  });

  const purchasedCreatorsData =
    data?.myPurchasedCreators?.filter(notEmpty) || [];

  const creators = purchasedCreatorsData.map((row) => ({
    uuid: row.uuid,
    name: row.displayName,
    avatarUrl: row.avatar ? getImagePreviewUrlThumbnail(row.avatar) : "",
    discordChannel: row.discordChannel ?? undefined,
    discordServer: row.discordServer ?? undefined,
  }));

  useEffect(() => {
    Image.prefetch(creators.map((creator) => creator.avatarUrl));
  }, [creators]);

  return {
    creators,
    refetch,
    loading,
  };
}
