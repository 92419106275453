import { Button as RnButton, Text } from "react-native-paper";

export type ButtonProps = {
  type: "primary" | "danger";
  children?: React.ReactNode;
  loading?: boolean;
  text?: string;
} & Omit<React.ComponentProps<typeof RnButton>, "children">;

export function Button(props: ButtonProps) {
  const { type, loading, text } = props;

  const buttonVariants = {
    primary: `px-1 ${loading ? `bg-gray-500` : `bg-[#80EFAD]`}`,
    danger: `px-1 bg-transparent border border-1 ${
      loading ? `bg-gray-500` : `border-red-500`
    }`,
  } as const;

  const textVariant = {
    primary: `text-black font-unbounded text-xs`,
    danger: `${loading ? "text-black" : "text-red-500"} font-unbounded text-xs`,
  } as const;

  return (
    <RnButton {...props} className={buttonVariants[type]} disabled={loading}>
      {text && <Text className={textVariant[type]}>{text}</Text>}
    </RnButton>
  );
}
