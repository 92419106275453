import { Dispatch, SetStateAction } from "react";
import { Text, View } from "react-native";

import LoadingSpinner from "./LoadingSpinner";
import SubscriptionManageButton from "./SubscriptionManageButton";

export type CreatorSubscriptionItem = {
  uuid: string;
  name: string;
  avatarUrl: string;
  isFreeSubscription: boolean;
};

type ManageSubscriptionsProps = {
  creators: CreatorSubscriptionItem[];
  creatorsLoading: boolean;
  setShowConfirmUnsubscribeModal: Dispatch<SetStateAction<undefined | string>>;
};

function ManageSubscriptions({
  creators,
  creatorsLoading,
  setShowConfirmUnsubscribeModal,
}: ManageSubscriptionsProps) {
  const noSubscriptions =
    !creatorsLoading && (creators.length === 0 || !creators);

  return (
    <View className="flex flex-col space-y-2 w-[95%] ml-[2.5%]">
      {creators.map((creator) => (
        <View
          key={creator.uuid}
          className="flex flex-col justify-start h-[90px] w-full bg-[#00000034]"
        >
          <View className="w-[90%] h-[90px] flex flex-col justify-center ml-[5%]">
            <SubscriptionManageButton
              setShowConfirmUnsubscribeModal={setShowConfirmUnsubscribeModal}
              creatorUUID={creator.uuid}
              isFreeSubscription={creator.isFreeSubscription}
              creatorName={creator.name}
              creatorAvatarUrl={creator.avatarUrl}
            />
          </View>
        </View>
      ))}
      {creatorsLoading && (
        <View className="h-full w-[90%] ml-[5%] flex flex-row justify-center">
          <View className="mt-[100px]">
            <LoadingSpinner />
          </View>
        </View>
      )}
      {noSubscriptions && (
        <View className="h-full w-[90%] ml-[5%] flex flex-row justify-center">
          <Text className="text-[#FFFFFF50] mt-[100px] text-sm font-unbounded text-center">
            You have not subscribed to any creators.
          </Text>
        </View>
      )}
    </View>
  );
}
export default ManageSubscriptions;
