import debounce from "lodash.debounce";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AppState } from "react-native";
import { useSelector } from "react-redux";

import { usePlayerPlay } from "../hooks/audio-player/usePlayerPlay";
import TrackPlayer, {
  State,
  Event,
  Track,
  useTrackPlayerEvents,
  RepeatMode,
} from "../lib/TrackPlayerWrapper/TrackPlayerWrapper";
import { RootState } from "../redux/Store";
import { User } from "../redux/reducers/UserReducer";

export type MyTrack = Track & {
  episodeUUID: string | null;
  programUUID: string;
  mime: string;
};

export type Episode = {
  uuid: string | null; // Null to cover playing tracks which are not episodes
  trackUrl: string;
  trackMime: string;
  title: string;
  imageUrlMedium: string;
  progressInSeconds: number;
  programUUID: string;
};

type FullscreenPlayerContextType = {
  isFullscreen: boolean;
  setFullscreen: (isFullscreen: boolean) => void;
  playlist: MyTrack[];
  currentTrack: MyTrack | undefined;
  currentTrackIndex: number;
  playPlaylist: (episodes: Episode[], index?: number) => Promise<void>;
  isPlaying: boolean;
  isPaused: boolean;
  isLoading: boolean;
  repeatMode: RepeatMode;
  toggleRepeatMode: () => Promise<void>;
};

function episodeToTrack({
  uuid,
  trackUrl,
  title,
  imageUrlMedium,
  programUUID,
  trackMime,
}: Episode): MyTrack {
  return {
    url: trackUrl,
    mime: trackMime,
    episodeUUID: uuid,
    programUUID,
    title,
    artwork: imageUrlMedium,
  };
}

export const FullscreenPlayerContext =
  createContext<FullscreenPlayerContextType | null>(null);

export const FullscreenPlayerProvider: FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const [isFullscreen, setFullscreen] = useState(false);
  const [savedRepeatModeState, setSavedRepeatModeState] = useState<RepeatMode>(
    RepeatMode.Off
  );
  const user = useSelector<RootState, User | null>((state) => state.user);
  const [playlist, setPlaylist] = useState<MyTrack[]>([]);
  const [currentTrackIndex, setCurrentTrackIndex] = useState<
    number | undefined
  >();

  const { play } = usePlayerPlay();

  const currentTrack: MyTrack | undefined =
    typeof currentTrackIndex !== "undefined"
      ? playlist[currentTrackIndex]
      : undefined;

  const [playbackState, setPlaybackState] = useState<State>(State.None);

  const isPlaying = [State.Playing].includes(playbackState);
  const isPaused = [State.Paused, State.Stopped, State.Ready].includes(
    playbackState
  );
  const isLoading = [State.Connecting, State.Buffering].includes(playbackState);

  const debouncedChangeHandler = useMemo(
    () => debounce(setPlaybackState, 800, { leading: false }),
    []
  );

  async function updatePlayback(state: State) {
    // Checking on connecting state is too early and produces 0 track index
    // Checking without ready, won't update track on track change
    const states = [State.Buffering, State.Playing, State.Ready];
    if (!states.includes(state)) return;
    const track = await TrackPlayer.getCurrentTrack();
    if (track !== null) {
      setCurrentTrackIndex(track);
    }
  }

  // This is used to avoid flickering bugs
  useTrackPlayerEvents([Event.PlaybackState], ({ state }) => {
    // Immediately update state if:
    if (
      [State.Paused, State.Playing].includes(state) || // Case: play/pause button click
      playbackState === State.None || // Case: first player interaction
      (playbackState === State.Paused && state === State.Connecting) // Case: show loader after track change
    ) {
      debouncedChangeHandler.cancel();
      setPlaybackState(state);
    } else {
      debouncedChangeHandler(state);
    }
    void updatePlayback(state);
  });

  useEffect(() => {
    return () => debouncedChangeHandler.cancel();
  }, []);

  useEffect(() => {
    void setPlayerRepeatMode(RepeatMode.Queue);
  }, []);

  async function setPlayerRepeatMode(repeatMode: RepeatMode) {
    await TrackPlayer.setRepeatMode(repeatMode);
    setSavedRepeatModeState(repeatMode);
  }

  async function toggleRepeatMode() {
    if (savedRepeatModeState === RepeatMode.Queue) {
      await setPlayerRepeatMode(RepeatMode.Track);
    } else {
      await setPlayerRepeatMode(RepeatMode.Queue);
    }
  }

  async function playPlaylist(episodes: Episode[], index = 0) {
    const progressInSeconds = episodes[index]?.progressInSeconds ?? 0;
    const tracks = episodes.map(episodeToTrack);
    // Update the local states first to avoid flickering & update the UI immediately
    setPlaylist(tracks);
    setCurrentTrackIndex(index);
    await TrackPlayer.reset();
    await TrackPlayer.add(tracks);
    await TrackPlayer.skip(index);
    await TrackPlayer.seekTo(progressInSeconds);
    await play();
  }

  useEffect(() => {
    // Resync the player when app goes to foreground
    const subscription = AppState.addEventListener(
      "change",
      async (newState) => {
        if (newState !== "active") return;
        console.log("resyncing player because of active state");
        const currentTrackIndex = await TrackPlayer.getCurrentTrack();
        setCurrentTrackIndex(currentTrackIndex ?? 0);
      }
    );

    return () => subscription.remove();
  }, []);

  useEffect(() => {
    // cleanup on user logout
    if (!user) {
      void TrackPlayer.reset();
      setPlaylist([]);
      setCurrentTrackIndex(0);
    }
  }, [user]);

  const context: FullscreenPlayerContextType = {
    isFullscreen,
    setFullscreen,
    playlist,
    currentTrack,
    currentTrackIndex: currentTrackIndex ?? 0,
    playPlaylist,
    isPlaying,
    isPaused,
    isLoading,
    repeatMode: savedRepeatModeState,
    toggleRepeatMode,
  };

  return (
    <FullscreenPlayerContext.Provider value={context}>
      {children}
    </FullscreenPlayerContext.Provider>
  );
};

export function useMyPlayer() {
  const context = useContext(FullscreenPlayerContext);

  if (!context) {
    throw new Error(
      "useFullscreenPlayer must be used within a FullscreenPlayerProvider"
    );
  }

  return context;
}
