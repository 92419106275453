import { Image } from "expo-image";
import { View, Text, Platform, Alert, Share } from "react-native";
import { IconButton } from "react-native-paper";

import { FullscreenControls } from "./FullscreenControls";
import { FullscreenProgressBar } from "./FullscreenProgressBar";
import { VideoPlayer } from "./VideoPlayer";
import { envConfig } from "../../../env.config";
import { useUserProfile } from "../../hooks/useUserProfile";
import { useMyPlayer } from "../../providers/FullscreenPlayerProvider";

const { appStoreLink } = envConfig;

export type FullscreenAudioPlayerProps = {
  onHide: () => void;
};

function FullscreenAudioPlayer({ onHide }: FullscreenAudioPlayerProps) {
  const { currentTrack, playlist, currentTrackIndex } = useMyPlayer();
  const { userProfile } = useUserProfile();

  if (!currentTrack) {
    return null;
  }

  const { title, artwork } = currentTrack;

  const episodesCount = playlist.length;
  const currentEpisodeNumber = currentTrackIndex + 1;

  const webStyles = Platform.OS === "web" ? { height: 300 } : undefined;

  async function onShare() {
    try {
      await Share.share({
        message: `@${userProfile?.username} has invited you to check out Peak.audio ${appStoreLink}`,
      });
    } catch (error) {
      Alert.alert(error.message);
    }
  }

  const isVideoTrack = String(currentTrack.mime).startsWith("video");

  const showVideo = isVideoTrack && Platform.OS !== "web";

  return (
    <View className="absolute h-full w-full bg-black pt-6 md:hidden">
      <View className="h-full w-full max-w-md flex-col bg-black pt-6 justify-around mx-auto">
        <View className="flex flex-row items-center justify-between mx-4">
          <IconButton
            iconColor="white"
            className="h-[30px] w-[30px] m-0 rounded-none"
            onPress={onHide}
            icon="chevron-down"
          />
          <IconButton
            iconColor="white"
            className="h-[30px] w-[30px] m-0 rounded-none"
            onPress={onShare}
            icon="export-variant"
          />
        </View>
        <View className="mt-4 px-6 max-h-[300px] mx-auto" style={webStyles}>
          {artwork && !showVideo && (
            <Image
              className="h-full aspect-square"
              source={{
                uri: String(artwork),
              }}
            />
          )}
        </View>
        {showVideo && <VideoPlayer url={String(currentTrack.url)} />}
        <View className="mx-4">
          <View className="w-full mt-12 justify-end">
            <Text className="text-white text-xl font-unbounded self-center">
              {title}
            </Text>
            <Text className="text-gray-500 text-sm self-center font-semibold">
              Episode {currentEpisodeNumber} of {episodesCount}
            </Text>
          </View>
          <View className="mt-8 justify-end">
            <FullscreenProgressBar />
          </View>
          <View className="pb-6 mt-6 justify-self-end">
            <FullscreenControls />
          </View>
        </View>
      </View>
    </View>
  );
}

export default FullscreenAudioPlayer;
