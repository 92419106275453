import { UploadFile } from "../graphql/generated";

export function getImagePreviewUrlSmall(file: Partial<UploadFile>): string {
  return (
    file.formats?.small?.url ||
    file.formats?.thumbnail?.url ||
    file.previewUrl ||
    file.formats?.medium?.url ||
    file.url ||
    ""
  );
}

export function getImagePreviewUrlThumbnail(file: Partial<UploadFile>): string {
  return (
    file.formats?.thumbnail?.url ||
    file.formats?.small?.url ||
    file.previewUrl ||
    file.formats?.medium?.url ||
    file.url ||
    ""
  );
}

export function getImagePreviewUrlMedium(file: Partial<UploadFile>): string {
  return file.formats?.medium?.url || file.url || "";
}
