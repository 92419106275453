import {
  ResponseType,
  makeRedirectUri,
  useAuthRequest,
} from "expo-auth-session";

import { envConfig } from "../../env.config";
import {
  useDiscordAccountQuery,
  useLinkDiscordAccountMutation,
  useUnlinkDiscordAccountMutation,
} from "../graphql/generated";
import { ErrorReportingService } from "../services/ErrorReportingService";

const { discordClientId } = envConfig;

const redirectUri = makeRedirectUri({
  path: "discord",
});

export function useDiscordAuth() {
  const { data, refetch, loading: queryLoading } = useDiscordAccountQuery();

  const [linkDiscordAccount, { loading: linkLoading }] =
    useLinkDiscordAccountMutation({
      onCompleted: () => refetch(),
      onError: (error) => {
        ErrorReportingService.report(error);
        alert("Failed to link discord account, please try again later");
        const errorMessage = `Failed to link Discord account - ${error.message}`;
        console.error(errorMessage);
        ErrorReportingService.message(errorMessage);
        void refetch();
      },
    });

  const [unlinkDiscordAccount, { loading: unlinkLoading }] =
    useUnlinkDiscordAccountMutation({
      onCompleted: () => refetch(),
      onError: (error) => {
        ErrorReportingService.report(error);
        alert("Failed to unlink discord account, please try again later");
        const errorMessage = `Failed to unlink Discord account - ${error.message}`;
        console.error(errorMessage);
        ErrorReportingService.message(errorMessage);
        void refetch();
      },
    });

  const loading = queryLoading || linkLoading || unlinkLoading;

  const username = data?.discordAccount?.discordUsername;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [req, _res, makeRequest] = useAuthRequest(
    {
      responseType: ResponseType.Code,
      clientId: discordClientId,
      scopes: ["identify", "email", "guilds.join"],
      redirectUri,
      usePKCE: true,
    },
    {
      authorizationEndpoint: "https://discord.com/api/oauth2/authorize",
    }
  );

  async function linkAccount() {
    const res = await makeRequest();

    if (res.type === "error") {
      const { error } = res;
      const errorMEssage = `Authentication failed - description: ${error?.description}, code: ${error?.code}, message: ${error?.message}`;
      alert(errorMEssage);
      ErrorReportingService.report(error);
      throw new Error(errorMEssage);
    }

    if (res.type !== "success") {
      return;
    }

    const { code, state } = res.params;

    if (state !== req?.state) {
      const errorMessage = "Authentication failed - invalid state";
      alert(errorMessage);
      ErrorReportingService.report(new Error(errorMessage));
      throw new Error(errorMessage);
    }

    console.log("code", code);
    console.log("codeVerifier", req.codeVerifier);
    console.log("redirectUri", redirectUri);

    const codeVerifier = req.codeVerifier;

    if (!codeVerifier) {
      const errorMessage = "Authentication failed - no code verifier";
      alert(errorMessage);
      ErrorReportingService.report(new Error(errorMessage));
      throw new Error(errorMessage);
    }

    await linkDiscordAccount({
      variables: {
        code,
        codeVerifier,
        redirectUri,
      },
    });

    return true;
  }

  function unlinkAccount() {
    return unlinkDiscordAccount();
  }

  return {
    linkAccount,
    unlinkAccount,
    username,
    loading,
    refetch,
  };
}
