import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { ScrollView, View, Text, RefreshControl } from "react-native";
import { Button, IconButton } from "react-native-paper";

import Avatar, { ImageSize } from "../components/Avatar";
import LoadingScreen from "../components/Loading";
import ProgramGridContainer from "../components/ProgramGridContainer";
import { TabNavigation } from "../components/TabNavigation";
import { TrialConfirmationScreen } from "../components/TrialConfirmationScreen";
import { AudioPlayer } from "../components/audio-player/AudioPlayer";
import { EventRow } from "../components/events/EventRow";
import { useCreator } from "../hooks/creators/useCreator";
import { useCreatePreviewSubscription } from "../hooks/useCreatePreviewSubscription";
import {
  CreatorScreenProps,
  ScreenNavigationProps,
} from "../navigation/RootStackParamList";
import { goBack } from "../navigation/goBack";

function CreatorScreen({ route }: CreatorScreenProps) {
  const [navFocus, setNavFocus] = useState("programs");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showTrailModal, setShowTrailModal] = useState(false);

  const { creatorUUID, backToHome } = route?.params || {};

  const navigation = useNavigation<ScreenNavigationProps>();

  const {
    loading: creatorLoading,
    programs,
    creator,
    refetch,
    events,
  } = useCreator(creatorUUID);

  const refreshControl = (
    <RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} />
  );

  const { createPreviewSubscription, createPreviewSubscriptionLoading } =
    useCreatePreviewSubscription({
      creatorUUID: creator?.uuid || "",
      onRefetch: refetch,
    });

  function handleTrialSubmit() {
    setShowTrailModal(false);
    return createPreviewSubscription();
  }

  async function handleRefresh() {
    setIsRefreshing(true);
    try {
      await refetch();
    } catch (e) {
      console.log("Error refreshing creator data", e);
    }
    setIsRefreshing(false);
  }

  function onSubscribe() {
    const { isSubscribed, creatorSubscriptionProductId, uuid } = creator || {};
    if (isSubscribed) {
      alert("You are already subscribed to this creator");
      return;
    }

    if (!uuid) {
      alert("This creator does not have a valid UUID");
      return;
    }

    navigation.navigate("Purchase", {
      creatorUUID: uuid,
      creatorSubscriptionProductId: creatorSubscriptionProductId || null,
      programProductId: null,
      subscriptionPriceInCentsUsd:
        typeof creator?.subscriptionPriceInCentsUsd === "number"
          ? creator?.subscriptionPriceInCentsUsd
          : null,
      programUUID: null,
      programPriceInCentsUsd: null,
    });
  }

  function onCancel() {
    if (backToHome) {
      navigation.navigate("Home");
    } else {
      goBack(navigation);
    }
  }

  const loading = creatorLoading || createPreviewSubscriptionLoading;

  const tabs = [
    {
      key: "programs",
      title: "Programs",
      content: (
        <View className="h-full w-full self-start flex flex-col space-y-[20px]">
          {programs.length !== 0 && (
            <View className="mb-[10px]">
              <ProgramGridContainer programs={programs} gridSize={10} />
            </View>
          )}
          {programs.length === 0 && (
            <View className="h-full w-[90%] flex flex-row justify-center">
              <Text className="text-[#FFFFFF50] mt-[70px] text-sm font-unbounded text-center ml-[10%]">
                This creator has no programs- check back later!
              </Text>
            </View>
          )}
        </View>
      ),
    },
    {
      key: "events",
      title: "Events",
      content: (
        <View className="flex flex-col justify-center w-full mb-[30px] px-3">
          {events.map((event) => (
            <EventRow key={event.uuid} event={event} />
          ))}
          {events.length === 0 && (
            <View className="h-full w-[90%] flex flex-row justify-center">
              <Text className="text-[#FFFFFF50] mt-[70px] text-sm font-unbounded text-center ml-[10%]">
                This creator has no events - check back later!
              </Text>
            </View>
          )}
        </View>
      ),
    },
    {
      key: "about",
      title: "About",
      content: (
        <>
          {creator?.bio && (
            <View className="h-full w-[90%] self-center mt-[10px]">
              <Text className="text-sm font-inter text-white">
                {creator?.bio}
              </Text>
            </View>
          )}
          {!creator?.bio && (
            <View className="h-full w-[90%] flex flex-row justify-center">
              <Text className="text-[#FFFFFF50] ml-[5%] mt-[70px] text-sm font-unbounded text-center">
                This creator has no bio.
              </Text>
            </View>
          )}
        </>
      ),
    },
  ];

  return (
    <View className="h-full w-full bg-main">
      <ScrollView className="" refreshControl={refreshControl}>
        <View className="flex flex-row h-[50px] w-[90%] justify-left self-center mt-[50px] space-x-3">
          <IconButton
            className="h-[35px] w-[35px] bg-[#80EFAD] self-center"
            iconColor="black"
            onPress={onCancel}
            icon="arrow-left"
          />
          <Text className="text-lg font-unbounded text-white self-center">
            About Creator
          </Text>
        </View>
        <View className="flex flex-row h-[150px] w-[53%] justify-left self-start ml-[5%] space-x-5">
          <Avatar imageSize={ImageSize.Large} thumbnail={creator?.avatarUrl} />
          <View className="flex flex-col justify-end mb-[20px] space-y-2">
            <Text
              numberOfLines={2}
              className="text-[14px] font-unbounded text-white self-start"
            >
              {creator?.name || "---"}
            </Text>
            <Text className="text-sm font-inter text-white self-start">
              {creator?.location || "---"}
            </Text>
            {creator?.isSubscribed && (
              <View className="w-full flex flex-row justify-start">
                <View className="bg-[#7523DD] px-2 py-1 rounded-md">
                  <Text className="text-xs font-bold text-white">
                    SUBSCRIBED
                  </Text>
                </View>
              </View>
            )}
            <Text className="text-sm font-inter text-[#FFFFFF55] self-start mt-[15px]">
              Member since {creator?.memberSinceYear || "---"}
            </Text>
          </View>
        </View>
        <View className="flex flex-row space-x-5 justify-start ml-[5%]">
          <View className="flex flex-col">
            <Text className="text-sm font-inter text-[#C4C6D4] mt-2 self-start">
              Programs
            </Text>
            <Text className="text-lg font-unbounded text-[#FFFFFF] self-start">
              {programs.length}
            </Text>
          </View>
          <View className="flex flex-col">
            <Text className="text-sm font-inter text-[#C4C6D4] ml-3 mt-2 self-start">
              Subscribers
            </Text>
            <Text className="text-lg font-unbounded text-[#FFFFFF] ml-3 self-start">
              {creator?.subscriberCount || 0}
            </Text>
          </View>
          <View className="flex flex-col">
            <Text className="text-sm font-inter text-[#C4C6D4] ml-3 mt-2 self-start">
              Hours Listened
            </Text>
            <Text className="text-lg font-unbounded text-[#FFFFFF] ml-3 self-start">
              {creator?.hoursListened || 0}
            </Text>
          </View>
        </View>
        <View className="flex flex-row mt-[15px] justify-center content-center px-3 space-x-2">
          {!creator?.isSubscribed && (
            <View className="flex-1 bg-green-300 rounded-xl border-[1px]">
              <Button className="" onPress={onSubscribe}>
                <Text className="font-unbounded text-black">Subscribe</Text>
              </Button>
            </View>
          )}
          {creator?.isPreviewSubscriptionAvailable && (
            <View className="flex-1 border-[1px] border-[#80EFAD] rounded-xl">
              <Button
                labelStyle={{
                  color: "#80EFAD",
                }}
                className="bg-transparent"
                onPress={() => setShowTrailModal(true)}
              >
                <Text className="font-unbounded text-[#80EFAD]">Trial</Text>
              </Button>
            </View>
          )}
        </View>
        {/* <View className="flex flex-row w-full mt-2 justify-center md:justify-start md:ml-12">
          <Button
            icon="play"
            labelStyle={{ color: "grey" }}
            className="bg-[#1D2033] border-[1px] border-gray-500 rounded-xl w-[90%]"
          >
            <Text className="font-unbounded self-center text-gray-500">
              Video Intro
            </Text>
          </Button>
        </View> */}
        <View className="m-3 mt-5">
          <TabNavigation
            activeKey={navFocus}
            onTabChange={setNavFocus}
            tabs={tabs}
          />
        </View>
      </ScrollView>
      {loading && <LoadingScreen />}
      {showTrailModal && (
        <TrialConfirmationScreen
          onCancel={() => setShowTrailModal(false)}
          onConfirm={handleTrialSubmit}
          creatorName={creator?.name || ""}
          creatorImageUrl={creator?.avatarUrl || ""}
        />
      )}
      <AudioPlayer />
    </View>
  );
}

export default CreatorScreen;
