import { View, Text } from "react-native";

export type SettingsRowProps = {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
};

export function SettingsRow({ title, subtitle, children }: SettingsRowProps) {
  return (
    <View className="w-full bg-gray-900 flex flex-row rounded-xl p-4 justify-items-center space-x-3">
      <View className="flex flex-col space-y-2 flex-1">
        <Text className="text-white text-md font-medium">{title}</Text>
        <Text className="text-gray-600">{subtitle}</Text>
      </View>
      <View className="justify-center">{children}</View>
    </View>
  );
}
