import { TouchableOpacity } from "react-native";

import FullscreenAudioPlayer from "./FullscreenAudioPlayer";
import MinimizedAudioPlayer from "../../components/audio-player/MinimizedAudioPlayer";
import { useMyPlayer } from "../../providers/FullscreenPlayerProvider";

export function AudioPlayer() {
  const { isFullscreen, setFullscreen } = useMyPlayer();

  return (
    <>
      {!isFullscreen && (
        <TouchableOpacity
          className="mt-auto"
          activeOpacity={1}
          onPress={() => setFullscreen(true)}
        >
          <MinimizedAudioPlayer />
        </TouchableOpacity>
      )}
      {isFullscreen && (
        <FullscreenAudioPlayer onHide={() => setFullscreen(false)} />
      )}
    </>
  );
}
