import { Image } from "expo-image";
import { View, Text } from "react-native";

import { FullscreenControls } from "./FullscreenControls";
import { FullscreenProgressBar } from "./FullscreenProgressBar";
import { VolumeBar } from "./VolumeBar";
import { useMyPlayer } from "../../providers/FullscreenPlayerProvider";

export function WebMinimizedAudioPlayer() {
  const { currentTrack, playlist, currentTrackIndex } = useMyPlayer();

  if (!currentTrack) {
    return null;
  }

  const { title, artwork } = currentTrack;

  return (
    <View className="hidden md:flex md:flex-row bg-[#171717] py-4 px-8">
      <View className="flex flex-row items-center mr-auto min-w-[180px] w-[25%] overflow-hidden max-w-[250px]">
        {artwork && (
          <View className="w-[55px] h-[55px] relative">
            <Image
              className="w-[55px] h-[55px] my-[1.5px]"
              source={{
                uri: String(artwork),
              }}
            />
          </View>
        )}
        <View className="flex flex-col space-y-1 justify-center pl-4">
          <Text
            numberOfLines={1}
            className="text-white font-unbounded text-md overflow-ellipsis whitespace-nowrap"
          >
            {title}
          </Text>

          <Text className="text-[#FFFFFF60] font-unbounded text-sm">
            Episode {currentTrackIndex + 1} of {playlist.length}
          </Text>
        </View>
      </View>
      <View className="flex flex-col flex-1 max-w-2xl px-4 mx-auto w-[50%]">
        <View className="max-w-[384px] mx-auto">
          <FullscreenControls />
        </View>
        <FullscreenProgressBar />
      </View>
      <View className="ml-auto w-[25%] max-w-[250px]">
        <VolumeBar />
      </View>
    </View>
  );
}
