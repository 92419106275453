import TrackPlayer, {
  Capability,
  AppKilledPlaybackBehavior,
} from "./TrackPlayerWrapper/TrackPlayerWrapper";

const AUDIO_CAPABILITIES: Capability[] = [
  Capability.Play,
  Capability.Pause,
  Capability.SkipToNext,
  Capability.SkipToPrevious,
  Capability.SeekTo,
];

export async function setupAudioPlayer() {
  await TrackPlayer.setupPlayer();
  await TrackPlayer.updateOptions({
    capabilities: AUDIO_CAPABILITIES,
    compactCapabilities: AUDIO_CAPABILITIES,
    android: {
      appKilledPlaybackBehavior: AppKilledPlaybackBehavior.PausePlayback,
    },
    notificationCapabilities: AUDIO_CAPABILITIES,
    progressUpdateEventInterval: 1,
  });
}
