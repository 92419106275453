// Copied from "react-native-track-player" to avoid importing the whole library for web as it breaks the build & runtime
export enum State {
  /** Indicates that no media is currently loaded */
  None,
  /** Indicates that the player is ready to start playing */
  Ready,
  /** Indicates that the player is currently playing */
  Playing,
  /** Indicates that the player is currently paused */
  Paused,
  /** Indicates that the player is currently stopped */
  Stopped,
  /** Indicates that the player is currently buffering (in "play" state) */
  Buffering,
  /** Indicates that the player is currently buffering (in "pause" state) */
  Connecting,
}
