import { Image } from "expo-image";
import { Dispatch, SetStateAction } from "react";
import { View, Alert, Text } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import PlayButton from "../../components/PlayButton";
import { ProgramPreviewEpisode } from "../../hooks/programs/useProgramPreview";
import TrackPlayer from "../../lib/TrackPlayerWrapper/TrackPlayerWrapper";
import { useMyPlayer } from "../../providers/FullscreenPlayerProvider";

type ProgramPreviewEpisodesProps = {
  doesHaveAccess: boolean;
  episodes: ProgramPreviewEpisode[];
  setShowFlagContentModal?: Dispatch<SetStateAction<undefined | string>>;
  handleScroll: () => void;
};

export function ProgramPreviewEpisodes({
  handleScroll,
  doesHaveAccess,
  episodes,
  setShowFlagContentModal,
}: ProgramPreviewEpisodesProps) {
  const { currentTrack, playPlaylist, isPlaying } = useMyPlayer();

  const currentlyPlayingEpisodeUUID = isPlaying
    ? currentTrack?.episodeUUID
    : undefined;

  async function onClick(episode: ProgramPreviewEpisode) {
    if (!doesHaveAccess) {
      Alert.alert("Purchase the program to begin listening");
      return;
    }
    if (currentlyPlayingEpisodeUUID === episode.uuid && doesHaveAccess) {
      void TrackPlayer.pause();
    } else {
      const index = episodes.findIndex((e) => e.uuid === episode.uuid);
      void playPlaylist(episodes, index);
    }
  }

  function handleShowFlag(episode: ProgramPreviewEpisode) {
    handleScroll();
    setShowFlagContentModal?.(episode.uuid);
  }

  return (
    <View className="flex flex-col space-y-4 mt-3">
      {episodes.map((episode, index) => (
        <TouchableOpacity
          key={episode.uuid}
          activeOpacity={0.75}
          onPress={() => onClick(episode)}
          className="flex flex-col h-fit w-full bg-[#00000030] p-3 rounded-lg"
        >
          <Text className="text-[#FFFFFF50] font-unbounded text-xs">
            {`EPISODE ${index + 1}`}
          </Text>
          <View className="flex flex-row w-full mt-2">
            <View className="mx-2">
              <PlayButton episodeUUID={episode.uuid} />
            </View>
            <View className="flex flex-col space-y-3 mx-2 flex-1">
              <Text
                className="text-sm font-unbounded text-white"
                numberOfLines={3}
              >
                {episode.title}
              </Text>
              <View className="flex flex-row items-center w-min h-min">
                <Image
                  className="h-[12px] w-[12px]"
                  source={require("../../../assets/icons/clock.png")}
                />
                <Text className="text-[#FFFFFF50] font-unbounded text-xs ml-1">
                  {Math.floor(episode.lengthInSeconds / 60)} MIN
                </Text>
              </View>
            </View>
            <Image
              className="h-[85px] w-[85px] bg-black rounded-2xl mr-2 ml-auto"
              source={{
                uri: episode.imageUrl,
              }}
              cachePolicy="memory-disk"
            />
          </View>
          <Text className="text-white text-sm">
            {episode.description || ""}
          </Text>
          {setShowFlagContentModal && (
            <TouchableOpacity
              onPress={() => handleShowFlag(episode)}
              className="ml-auto"
            >
              <Image
                className="h-[20px] w-[20px]"
                source={require("../../../assets/icons/flag.png")}
              />
            </TouchableOpacity>
          )}
        </TouchableOpacity>
      ))}
    </View>
  );
}
