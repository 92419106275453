import { useRef } from "react";
import Video, {
  LoadError,
  OnBufferData,
  OnProgressData,
} from "react-native-video";

import {
  useTrackPlayerEvents,
  Event,
} from "../../lib/TrackPlayerWrapper/TrackPlayerWrapper";
import { useMyPlayer } from "../../providers/FullscreenPlayerProvider";
import { ErrorReportingService } from "../../services/ErrorReportingService";

export type VideoPlayerProps = {
  url: string;
};

const updateVideoPositionIntervalMs = 50;
const acceptableDiff = 0.2;

// Dedicated component to optimize rerendering intervals
export function VideoPlayer({ url }: VideoPlayerProps) {
  const videoRef = useRef<Video>(null);
  const { isPlaying } = useMyPlayer();

  const videoPositionRef = useRef(0);
  const lastUpdateAudioPositionRef = useRef(0);

  useTrackPlayerEvents([Event.PlaybackProgressUpdated], (data) => {
    const audioPosition = data.position;
    const videoPosition = videoPositionRef.current;

    // avoid reprocessing for the same audio position twice
    if (lastUpdateAudioPositionRef.current === audioPosition) {
      return;
    }
    lastUpdateAudioPositionRef.current = audioPosition;

    const ref = videoRef.current;
    if (!ref) return;

    if (audioPosition > videoPosition) {
      // seek forwards
      const diff = audioPosition - videoPosition;
      if (diff <= acceptableDiff) {
        // console.log("skipping, audio in future by ", diff);
        return;
      }
      const increment = (updateVideoPositionIntervalMs * 5) / 1000;
      console.log(
        `audio is in the future by ${diff} - adjusting video up by ${increment}`
      );
      ref.seek(audioPosition + increment);
    } else {
      // seek backwards
      const diff = videoPosition - audioPosition;
      if (diff <= acceptableDiff) {
        // console.log("skipping, audio in past by ", diff);
        return;
      }

      const increment = (updateVideoPositionIntervalMs * 2) / 1000;
      console.log(
        `audio is in the past by ${diff} - adjusting video down to audio position + ${increment}`
      );
      // if diff is greater than 100ms adjust the video position
      ref.seek(audioPosition + increment);
    }
  });

  function onVideoBuffer(data: OnBufferData) {
    console.log("onVideoBuffer", data);
  }

  function onVideoError(error: LoadError) {
    console.error("onVideoError", error);
    ErrorReportingService.report(error);
  }

  function onVideoProgress(data: OnProgressData) {
    // Set via ref to avoid rerendering
    videoPositionRef.current = data.currentTime;
  }

  return (
    <Video
      source={{ uri: String(url) }} // Can be a URL or a local file.
      ref={videoRef}
      onBuffer={onVideoBuffer}
      onError={onVideoError}
      paused={!isPlaying}
      progressUpdateInterval={updateVideoPositionIntervalMs}
      onProgress={onVideoProgress}
      muted
      style={{
        height: 240,
      }}
    />
  );
}
