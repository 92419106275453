import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import { ImageBackground, Text, View } from "react-native";
import { Button } from "react-native-paper";

import LoadingSpinner from "../components/LoadingSpinner";
import { useUpdateMyUserProfileMutation } from "../graphql/generated";
import { useUserProfile } from "../hooks/useUserProfile";
import { ScreenNavigationProps } from "../navigation/RootStackParamList";

function FirstTime() {
  const navigation = useNavigation<ScreenNavigationProps>();

  const {
    userProfile,
    loading,
    refetch: userProfileRefetch,
  } = useUserProfile();

  const [updateMyProfileMutation] = useUpdateMyUserProfileMutation({
    variables: {
      image: userProfile?.image?.data?.id || "",
      isFirstTimeUse: false,
      completedTutorial: userProfile?.completedTutorial || false,
      bio: userProfile?.bio || "",
      location: userProfile?.location || "",
      username: userProfile?.username || "",
    },
  });

  async function handleCompleteFTU() {
    await updateMyProfileMutation();
    await userProfileRefetch();
    navigation.navigate("Home");
  }

  return (
    <View className="h-full w-full">
      {!loading && (
        <ImageBackground
          className="h-[100%] bg-black w-full"
          source={require("../../assets/first-time-background.png")}
        >
          <View className="h-full w-full bg-[#00000080] flex flex-col">
            <Image
              className="mx-12 mt-16 w-[147px] h-[24px]"
              source={require("../../assets/peak-logo-white.png")}
            />
            <View className="flex flex-col mt-20 flex-1 mx-5">
              <View className="flex flex-col space-y-3 mx-11">
                <Text className="text-white text-2xl font-bold font-unbounded self-center mb-[40px]">
                  Welcome aboard! 🎉
                </Text>
                <Text className="text-sm text-white">
                  Experience the ultimate audio journey, featuring content from
                  the best creators. From relaxing sounds to guided meditations,
                  we've got it all.
                </Text>
                <Text className="text-sm text-white">
                  Embark on your journey into meditation today!
                </Text>
              </View>
              <Button
                className="rounded-2xl bg-[#80EFAD] border-solid border-black mt-auto mb-16"
                textColor="#000000"
                onPress={() => handleCompleteFTU()}
              >
                <Text className="font-unbounded text-black">
                  Start Exploring
                </Text>
              </Button>
            </View>
          </View>
        </ImageBackground>
      )}
      {loading && (
        <View className="flex flex-col h-full bg-main justify-center">
          <LoadingSpinner />
        </View>
      )}
    </View>
  );
}
export default FirstTime;
