import { Text, TouchableOpacity } from "react-native";

export type WarningTextboxProps = {
  children: React.ReactNode;
} & React.ComponentProps<typeof TouchableOpacity>;

export function WarningTextbox(props: WarningTextboxProps) {
  return (
    <TouchableOpacity
      {...props}
      className="bg-yellow-900/30 rounded-xl px-4 py-2"
      activeOpacity={0.75}
    >
      <Text className="font-inter text-lg text-amber-400/80">
        {props.children}
      </Text>
    </TouchableOpacity>
  );
}
