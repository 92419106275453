import { Text, View } from "react-native";

type IconStreakNumberProps = {
  number: number;
  size: "small" | "large";
};

export function IconStreakNumber({ number, size }: IconStreakNumberProps) {
  if (size === "small") {
    return (
      <View className="border-[0.5px] border-slate-600 rounded-[10px]">
        <Text className="px-[10px] py-[5.5px] text-[13px] text-slate-500">
          {number}
        </Text>
      </View>
    );
  } else {
    return (
      <View className="border-[1px] border-slate-600 rounded-[15px]">
        <Text className="px-[13px] py-[7px] text-[15px] text-slate-500">
          {number}
        </Text>
      </View>
    );
  }
}
