import { Image } from "expo-image";
import { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";

import Avatar, { ImageSize } from "./Avatar";
import { TabNavigation } from "./TabNavigation";

type UserBannerProps = {
  index: number;
  name: string;
  imageUrl: string;
  totalListenedSeconds: number;
  currentStreak: number;
  longestStreak: number;
  onPress: () => void;
};

function UserBanner({
  index,
  name,
  imageUrl,
  totalListenedSeconds,
  currentStreak,
  longestStreak,
  onPress,
}: UserBannerProps) {
  return (
    <View className="w-full">
      <TouchableOpacity onPress={onPress}>
        <View className="bg-[#00000085] rounded-xl h-[81px] w-full flex flex-row justify-start space-x-3 mt-2 overflow-hidden">
          <View className="flex flex-col h-[81px] w-[20px] justify-start ml-3 mt-3">
            <Text className="text-xs font-unbounded text-[#FFFFFF80]">
              #{index}
            </Text>
          </View>
          <View className="flex flex-col justify-center">
            <Avatar imageSize={ImageSize.Small} thumbnail={imageUrl} />
          </View>
          <View className="flex flex-col w-full justify-center pl-2">
            <View className="flex flex-row">
              <Text
                numberOfLines={1}
                className="text-sm font-unbounded text-white self-start"
              >
                {name}
              </Text>
            </View>
            <View className="flex flex-row space-x-8 mt-2">
              <View className="flex flex-col">
                <View className="flex flex-row space-x-1 items-center">
                  <Image
                    className="h-[15px] w-[12px]"
                    contentFit="contain"
                    source={require("../../assets/icons/streak.png")}
                  />
                  <Text className="text-sm font-unbounded text-white self-start">
                    {currentStreak}
                  </Text>
                </View>
                <Text className="text-sm font-inter text-[#FFFFFF55] self-start">
                  Streak
                </Text>
              </View>
              <View className="flex flex-col">
                <Text className="text-sm font-unbounded text-white ml-3 self-start">
                  {longestStreak}
                </Text>
                <Text className="text-sm font-inter text-[#FFFFFF55] ml-3 self-start">
                  Best Streak
                </Text>
              </View>
              {/* <View className="flex flex-col">
                <Text className="text-sm font-unbounded text-white self-start">
                  {hours}
                </Text>
                <Text className="text-sm font-inter text-[#FFFFFF55] self-start">
                  Hours Listened
                </Text>
              </View> */}
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

type BillboardUser = Omit<
  UserBannerProps & { userProfileUUID: string },
  "index" | "onPress"
>;

type BillboardProps = {
  users: BillboardUser[];
  monthlyUsers: BillboardUser[];
  onUserPress: (user: BillboardUser) => void;
};

function Billboard({ users, monthlyUsers, onUserPress }: BillboardProps) {
  const [navFocus, setNavFocus] = useState("this-month");

  const tabs = [
    {
      key: "this-month",
      title: "This Month",
      content: monthlyUsers.map((user, index) => (
        <UserBanner
          key={user.userProfileUUID}
          index={index + 1}
          {...user}
          onPress={() => onUserPress(user)}
        />
      )),
    },
    {
      key: "all-time",
      title: "All Time",
      content: users.map((user, index) => (
        <UserBanner
          key={user.userProfileUUID}
          index={index + 1}
          {...user}
          onPress={() => onUserPress(user)}
        />
      )),
    },
  ];

  return (
    <View className="max-w-2xl mt-3 ml-1">
      <TabNavigation
        activeKey={navFocus}
        onTabChange={setNavFocus}
        tabs={tabs}
      />
    </View>
  );
}
export default Billboard;
