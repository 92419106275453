import { Image } from "expo-image";
import { View, Text } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import PlayButton from "../PlayButton";

type Episode = {
  uuid: string;
  title: string;
  imageUrl: string;
  progressPercentage: number;
};

type SquareEpisodeProps = {
  episode: Episode;
  onPress: () => void;
};

export function SquareEpisode({ onPress, episode }: SquareEpisodeProps) {
  const { title, imageUrl, progressPercentage } = episode;

  return (
    <TouchableOpacity activeOpacity={0.75} onPress={onPress}>
      <View className="self-center mb-2 flex flex-row rounded-xl bg-[#00000085] h-[88px] w-full overflow-hidden">
        <Image
          className="h-[88px] w-[88px] rounded-l-xl rounded-tr-xl z-10"
          cachePolicy="memory-disk"
          contentFit="cover"
          source={imageUrl}
        />
        <View className="flex flex-col flex-1">
          <View className="h-full flex flex-row justify-between px-1.5">
            <View className="h-full flex flex-col justify-center w-full max-w-[65%]">
              <Text
                numberOfLines={3}
                className="text-sm font-unbounded text-white ml-4"
              >
                {title}
              </Text>
            </View>
            <View className="flex flex-col justify-center">
              <PlayButton episodeUUID={episode.uuid} />
            </View>
          </View>
          <View
            style={{
              width: `${progressPercentage}%`,
            }}
            className="h-[3px] bg-[#80EFAD] -mt-[3px] z-0"
          />
        </View>
      </View>
    </TouchableOpacity>
  );
}
