import { Text } from "react-native";

import { EventStatus } from "../../graphql/generated";

type EventStatusProps = {
  status: EventStatus;
};

export function EventStatusView({ status }: EventStatusProps) {
  const readableStatus = getStatusName(status);

  if (!readableStatus) {
    return null;
  }

  const styling = getStatusStyling(status);

  return (
    <Text
      className="px-1 py-[1px] text-[10px] font-extrabold overflow-hidden whitespace-nowrap break-keep min-w-fit"
      style={{
        ...styling,
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: "solid",
      }}
    >
      {readableStatus}
    </Text>
  );
}

function getStatusName(status: EventStatus) {
  switch (status) {
    case EventStatus.Available:
      return "AVAILABLE";
    case EventStatus.Completed:
      return "COMPLETED";
    case EventStatus.InProgress:
      return "IN PROGRESS";
    default:
      return null;
  }
}

function getStatusStyling(status: EventStatus) {
  const color = getColorByEventStatus(status);
  switch (status) {
    case EventStatus.Available:
      return {
        backgroundColor: color,
        borderColor: color,
      } as const;
    case EventStatus.Completed:
      return {
        color,
        borderColor: color,
        backgroundColor: "#00000085",
      } as const;
    case EventStatus.InProgress:
      return {
        backgroundColor: color,
        borderColor: color,
      } as const;
    default:
      return {} as const;
  }
}

export function getColorByEventStatus(status: EventStatus) {
  switch (status) {
    case EventStatus.Available:
      return "rgb(227, 163, 38)";
    case EventStatus.Completed:
      return "rgb(103, 108, 132)";
    case EventStatus.InProgress:
      return "rgb(128, 239, 173)";
    default:
      return "";
  }
}
