import { Dispatch, SetStateAction } from "react";
import { Text, View, Linking } from "react-native";
import { IconButton, Button } from "react-native-paper";
import Pdf from "react-native-pdf";

import { useMyPlayer } from "../../providers/FullscreenPlayerProvider";

type PdfViewerProps = {
  setShowPdfViewerModal: Dispatch<SetStateAction<undefined | string>>;
  pdfSrc: string;
};

export function PdfViewerModal({
  setShowPdfViewerModal,
  pdfSrc,
}: PdfViewerProps) {
  const { currentTrack } = useMyPlayer();

  function handleCloseViewer() {
    setShowPdfViewerModal(undefined);
  }

  function handleOpenPdf() {
    Linking.openURL(pdfSrc).catch((err) => {
      console.error("Failed to open PDF URL:", err);
    });
  }

  const source = { uri: pdfSrc, cache: true };

  return (
    <View className="h-full w-full absolute top-0 right-0 bg-main pt-20">
      <View
        className={`mb-[10px] w-full h-full overflow-hidden self-center mt-[20px] ${
          currentTrack ? `pb-20` : `pb-6`
        }`}
      >
        <Pdf
          source={source}
          style={{
            height: "100%",
            width: "100%",
            flex: 1,
            overflow: "hidden",
          }}
        />
      </View>
      <IconButton
        className="rounded-full bg-[#80EFAD] border-solid border-black mx-5 absolute top-8 left-0 z-20 self-center"
        onPress={() => handleCloseViewer()}
        icon="arrow-left"
      />
      <Button
        onPress={() => handleOpenPdf()}
        className="rounded-2xl bg-[#80EFAD] border-solid border-black mx-5 absolute top-10 right-0 z-20 self-center"
      >
        <Text className="font-unbounded self-center text-black">
          Open in Browser
        </Text>
      </Button>
    </View>
  );
}
