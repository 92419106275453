import { useNavigation } from "@react-navigation/native";

import { TutorialModal } from "./TutorialModal";
import { useCompleteTutorial } from "../../hooks/useCompleteTutorial";
import { ScreenNavigationProps } from "../../navigation/RootStackParamList";

type TutorialModalSocialProps = {
  refetch: () => void;
};

export function TutorialModalSocial({ refetch }: TutorialModalSocialProps) {
  const { completeTutorial } = useCompleteTutorial();

  async function handleCompleteTutorial() {
    await completeTutorial();
    refetch();
  }

  const navigation = useNavigation<ScreenNavigationProps>();

  function handleNext() {
    navigation.navigate("Account");
  }
  return (
    <TutorialModal
      handleNext={handleNext}
      pageName="Social"
      handleCompleteTutorial={handleCompleteTutorial}
      nextText="Next"
      descriptionText="Connect with creator communities and engage through our Discord integration."
      screenNumber={3}
    />
  );
}
