import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import { View, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";

import { useStoreProducts } from "../hooks/useStoreProducts";
import { ScreenNavigationProps } from "../navigation/RootStackParamList";
import { notEmpty } from "../utils/not-empty";

type ProgramsThumbnailContainerItem = {
  uuid: string;
  title: string;
  imageUrl: string;
  isAccessible: boolean;
  episodesCount: number;
  programProductId: string | undefined;
  isNew: boolean;
  isFree: boolean;
  priceInCents: number | null;
};

type ProgramsBannerContainerProps = {
  program: ProgramsThumbnailContainerItem;
};

function ProgramBanner({ program }: ProgramsBannerContainerProps) {
  const navigation = useNavigation<ScreenNavigationProps>();

  function onProgramPress(program: ProgramsThumbnailContainerItem) {
    navigation.navigate("ProgramPreview", {
      programUUID: program.uuid,
    });
  }

  const productIds = [program.programProductId].filter(notEmpty);

  const { products, getPlatformPriceString } = useStoreProducts(productIds);

  const oneTimeProduct = products.find(
    (product) => product.identifier === program.programProductId
  );

  const programPrice = getPlatformPriceString(
    oneTimeProduct,
    program.priceInCents
  );

  return (
    <TouchableOpacity
      activeOpacity={0.75}
      onPress={() => onProgramPress(program)}
      key={program.uuid}
    >
      <View className="self-center mb-2 flex flex-row rounded-xl bg-[#00000085] h-[88px] w-full overflow-hidden">
        <Image
          className="h-[88px] w-[88px] rounded-xl"
          cachePolicy="memory-disk"
          contentFit="cover"
          source={program.imageUrl}
        />
        {program.isNew && (
          <View className="absolute left-12 top-2">
            <View className="bg-[#80EFAD] h-[22px] px-1 rounded-lg justify-center">
              <Text className="text-black font-bold text-xs">NEW</Text>
            </View>
          </View>
        )}
        <View className="flex flex-col flex-1">
          <View className="h-full flex flex-row justify-between px-1.5">
            <View className="h-full flex flex-col justify-center w-full max-w-[65%]">
              <Text
                numberOfLines={3}
                className="text-sm font-unbounded text-white ml-4"
              >
                {program.title}
              </Text>
            </View>
            <View className="flex flex-col justify-center pr-0.5">
              {!program.isFree && !program.isAccessible && (
                <Text
                  numberOfLines={2}
                  className="text-xs font-unbounded text-white self-end"
                >
                  {programPrice}
                </Text>
              )}
              {program.isAccessible && (
                <Text
                  numberOfLines={2}
                  className="text-xs font-unbounded text-white self-end"
                >
                  Owned
                </Text>
              )}
              {program.isFree && !program.isAccessible && (
                <Text
                  numberOfLines={2}
                  className="text-xs font-unbounded text-white self-end"
                >
                  {"FREE" || "$ ---"}
                </Text>
              )}
              <Text
                numberOfLines={2}
                className="text-sm font-unbounded text-[#FFFFFF55] self-end"
              >
                {program.episodesCount} EP
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}
export default ProgramBanner;
