import { Dispatch, SetStateAction, useState } from "react";
import { KeyboardAvoidingView, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Text, Button, TextInput } from "react-native-paper";

import { Enum_Flagcontent_Reason } from "../../graphql/generated";
import { useCreateFlagContent } from "../../hooks/useCreateFlagContent";
import { useMyPlayer } from "../../providers/FullscreenPlayerProvider";

const reasons = [
  {
    value: Enum_Flagcontent_Reason.OffensiveLanguage,
    label: "Offensive Language",
  },
  {
    value: Enum_Flagcontent_Reason.InappropriateContent,
    label: "Inappropriate Content",
  },
  { value: Enum_Flagcontent_Reason.Spam, label: "Spam" },
  { value: Enum_Flagcontent_Reason.Other, label: "Other" },
];

type FlagContentModalProps = {
  setShowFlagContentModal: Dispatch<SetStateAction<undefined | string>>;
  episodeUUID: string;
  programUUID: string;
  creatorUUID: string;
};

export function FlagContentModal({
  setShowFlagContentModal,
  programUUID,
  creatorUUID,
  episodeUUID,
}: FlagContentModalProps) {
  const [comment, setComment] = useState("");
  const [focus, setFocus] = useState<Enum_Flagcontent_Reason>(
    Enum_Flagcontent_Reason.OffensiveLanguage
  );
  const { currentTrack } = useMyPlayer();

  const { createFlagContent } = useCreateFlagContent({
    reason: focus,
    comment,
    episodeUUID,
    programUUID,
    creatorProfileUUID: creatorUUID,
  });

  async function handleSubmitFlag() {
    await createFlagContent().then(() => setShowFlagContentModal(undefined));
  }

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior="padding"
      className="absolute z-20 h-screen w-full flex flex-col justify-end"
    >
      <View className="h-full bg-[#00000095]" />
      <View
        className={`h-fit pt-[50px] ${
          currentTrack ? "pb-[160px]" : "pb-[100px]"
        } w-full bg-[#151827] flex flex-col justify-end px-[20px] space-y-[30px]`}
      >
        <Text className="text-white font-unbounded text-lg">Flag Content</Text>
        <Text className="text-white text-sm"> Reason for flagging: </Text>
        <View className="h-fit w-full flex flex-col justify-center space-y-[10px]">
          {reasons.map((reason) => (
            <TouchableOpacity
              key={reason.value}
              onPress={() => setFocus(reason.value)}
              activeOpacity={1}
            >
              <View
                className={`h-[65px] rounded-2xl w-full flex flex-row justify-start bg-[#00000085] py-[6px] border-2 ${
                  focus === reason.value
                    ? `border-[#80EFAD]`
                    : `border-transparent`
                }`}
              >
                <Text className="text-white h-full font-unbounded text-lg w-full mt-[8px] ml-[12px]">
                  {reason.label}
                </Text>
              </View>
            </TouchableOpacity>
          ))}
          <View>
            <Text className="text-[#C4C6D4] w-[90%] self-center my-[10px]">
              Comment
            </Text>
            <TextInput
              className="bg-transparent text-white w-[90%] self-center h-[100px] border border-1 border-[#FFFFFF] font-unbounded mb-3 rounded-xl"
              value={comment}
              onChangeText={(value) => setComment(value)}
              textAlignVertical="top"
              multiline
              numberOfLines={5}
              textColor="#FFFFFF"
              selectionColor="#FFFFFF"
              underlineColor="#00000000"
              activeUnderlineColor="#00000000"
            />
          </View>
        </View>
        <View className="flex flex-col mt-2 justify-center content-center mx-8">
          <Button
            onPress={() => handleSubmitFlag()}
            className="bg-[#80EFAD] rounded-xl"
          >
            <Text className="font-unbounded self-center text-[#000000]">
              Submit Report
            </Text>
          </Button>
          <Button
            onPress={() => setShowFlagContentModal(undefined)}
            className="bg-[#1D2033] border-[1px] border-[#80EFAD] rounded-xl mt-3"
          >
            <Text className="font-unbounded self-center text-[#80EFAD]">
              Cancel
            </Text>
          </Button>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
}
