import * as Linking from "expo-linking";
import { FC, PropsWithChildren, createContext, useEffect } from "react";

import { savePromotionToken } from "../redux/actions/PromotionTokenActions";

const DeeplinkContext = createContext<null>(null);

export const DeeplinkProvider: FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const url = Linking.useURL();

  useEffect(() => {
    if (!url) return;

    const { queryParams } = Linking.parse(url);

    const promotionTokenParam = queryParams?.promotionToken;

    if (promotionTokenParam && typeof promotionTokenParam === "string") {
      savePromotionToken(promotionTokenParam);
    }
    console.log("Linking url", url);
  }, [url]);

  return (
    <DeeplinkContext.Provider value={null}>{children}</DeeplinkContext.Provider>
  );
};
