import { Platform, View } from "react-native";
import WebView from "react-native-webview";

import { useUserProfile } from "../hooks/useUserProfile";
import { store } from "../redux/Store";

export type WidgetbotDiscordProps = {
  serverId: string;
  channelId: string;
};

function WidgetbotDiscord({ serverId, channelId }: WidgetbotDiscordProps) {
  const { userProfile } = useUserProfile();

  const avatarUrl =
    userProfile?.imageUrl ??
    "https://peak.audio/wp-content/uploads/2023/04/profilePlaceholder.png";

  const username = store.getState().user?.username;

  const params = new URLSearchParams({
    avatar: avatarUrl,
    username: username ?? "",
    accessibility: "forceSendButton",
  });

  const serverUrl = `https://e.widgetbot.io/channels/${serverId}/${channelId}?${params.toString()}`;

  if (Platform.OS === "web") {
    return (
      <iframe
        src={serverUrl}
        style={{
          border: "none",
          flex: 1,
          backgroundColor: "rgb(30, 41, 59)",
          borderRadius: 0,
        }}
      />
    );
  }

  return (
    <WebView
      startInLoadingState
      renderLoading={() => (
        <View className="h-full w-full bg-slate-800 fixed top-0 left-0 rounded-none" />
      )}
      className="bg-slate-800 flex-1 rounded-none"
      scrollEnabled={false}
      scalesPageToFit={false}
      sharedCookiesEnabled
      thirdPartyCookiesEnabled
      domStorageEnabled
      javaScriptEnabled
      originWhitelist={["*"]}
      source={{
        uri: serverUrl,
      }}
    />
  );
}

export default WidgetbotDiscord;
