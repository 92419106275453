import { Image } from "expo-image";
import { View, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";

type SubscriptionProductRowProps = {
  priceString: string;
  selected: boolean;
  onPress: () => void;
};

export function SubscriptionProductRow({
  priceString,
  selected,
  onPress,
}: SubscriptionProductRowProps) {
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={onPress} className="mt-4">
      <View
        className={`bg-[#00000024] pt-[20px] w-[95%] flex flex-col justify-center rounded-tl-3xl pb-4 border-[2px] ${
          selected ? `border-[#80EFAD]` : `border-transparent`
        }`}
      >
        <View className="flex flex-row space-x-[10px] w-[90%] self-center">
          <Image
            style={{ width: 30, height: 30 }}
            source={require("../../../assets/icons/user-add.png")}
          />
          <View className="flex flex-col w-[90%] space-y-[20px]">
            <View className="flex flex-col justify-between w-[100%]">
              <Text className="font-unbounded text-white text-sm">
                Subscribe to Creator
              </Text>
              <Text className="font-unbounded text-white text-sm">
                {priceString} / Mo
              </Text>
            </View>
            <View>
              <Text className="text-white text-sm">
                • Unlimited access to this program during subscription
              </Text>
              <Text className="text-white text-sm">
                • Access to full Creator’s portfolio
              </Text>
              <Text className="text-white text-sm">
                • Access to Creator’s future content
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}
