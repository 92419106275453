import { SettingsRow } from "./SettingsRow";
import { useDiscordAuth } from "../../hooks/useDiscordAuth";
import { Button } from "../Button";

export function DiscordLinking() {
  const { username, linkAccount, unlinkAccount, loading } = useDiscordAuth();

  const subtitle = username
    ? username
    : "Linking discord will let you use your own account when accessing Social";

  return (
    <SettingsRow title="Link Discord" subtitle={subtitle}>
      {!username && (
        <Button
          type="primary"
          onPress={() => linkAccount()}
          text="Link"
          loading={loading}
        />
      )}
      {username && (
        <Button
          type="danger"
          onPress={() => unlinkAccount()}
          text="Unlink"
          loading={loading}
        />
      )}
    </SettingsRow>
  );
}
