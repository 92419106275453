import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import * as Linking from "expo-linking";
import { useRef, useState } from "react";
import { RefreshControl, Text, View, Alert } from "react-native";
import { IconButton, Button } from "react-native-paper";
import { ScrollView } from "react-navigation";

import LoadingScreen from "../components/Loading";
import LoadingSpinner from "../components/LoadingSpinner";
import { CurrentStreak } from "../components/events/CurrentStreak";
import { EventStatusView } from "../components/events/EventStatusView";
import { ProgramPreviewEpisodes } from "../components/program-preview/ProgramPreviewEpisodes";
import { useEventPreview } from "../hooks/events/useEventPreview";
import { CachedImageBackground } from "../lib/CachedImageBackground/CachedImageBackground";
import {
  EventPreviewScreenProps,
  ScreenNavigationProps,
} from "../navigation/RootStackParamList";
import { goBack } from "../navigation/goBack";
import { useMyPlayer } from "../providers/FullscreenPlayerProvider";
import { getImagePreviewUrlMedium } from "../utils/image-preview-url";

export function EventPreviewScreen({
  route: {
    params: { eventUUID },
  },
}: EventPreviewScreenProps) {
  const { playPlaylist, currentTrack } = useMyPlayer();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const { event, episodes, loading, refetch } = useEventPreview(eventUUID);

  const startListeningDisabled = currentTrack?.programUUID === eventUUID; // Trick to prevent playing the same event twice

  const scrollRef = useRef<ScrollView>(null);

  async function handleRefresh() {
    try {
      setIsRefreshing(true);
      await refetch();
    } finally {
      setIsRefreshing(false);
    }
  }

  const refreshControl = (
    <RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} />
  );

  const navigation = useNavigation<ScreenNavigationProps>();

  async function handlePlayProgram() {
    if (startListeningDisabled) {
      console.error("Start listening disabled");
      return;
    }

    if (!episodes[0]) {
      console.error("No episodes found - firstEpisode is empty");
      return;
    }

    return playPlaylist(episodes);
  }

  async function onEventPreview() {
    const websiteUrl = event?.websiteUrl;
    if (websiteUrl) {
      await Linking.openURL(websiteUrl);
    } else {
      Alert.alert("No event website url found");
    }
  }

  const scrollToTop = () => {
    scrollRef.current?.scrollTo({ x: 0, y: 0, animated: false });
  };

  if (loading) {
    return <LoadingScreen />;
  }

  if (!event) {
    return <Text>No program data</Text>;
  }

  const imageUrl = event.image ? getImagePreviewUrlMedium(event?.image) : "";

  return (
    <ScrollView
      className="flex flex-col h-full w-full relative bg-[#1D2033]"
      refreshControl={refreshControl}
      ref={scrollRef}
    >
      {isRefreshing && (
        <View className="mb-[40px] -mt-[30px]">
          <LoadingSpinner />
        </View>
      )}
      <CachedImageBackground
        source={{ uri: imageUrl }}
        className="h-[255px]"
        cachePolicy="memory-disk"
        contentFit="cover"
        contentPosition="top center"
      >
        <View className="h-full flex flex-col bg-[#00000050] px-4 pt-12 justify-between pb-5">
          <IconButton
            iconColor="black"
            className="bg-[#80EFAD]"
            onPress={() => goBack(navigation)}
            icon="arrow-left"
          />
          <View className="flex flex-col gap-y-1">
            <View className="flex flex-row">
              <EventStatusView status={event.status} />
            </View>
            <Text className="text-white font-unbounded text-xl">
              {event.title}
            </Text>
          </View>
        </View>
      </CachedImageBackground>
      <View className="w-full px-4 mt-2">
        {!event.isPurchased && (
          <Button
            onPress={() => onEventPreview()}
            className="bg-green-300 rounded-xl w-full"
            contentStyle={{ flexDirection: "row-reverse" }}
          >
            <Text className="font-unbounded self-center text-black mr-1">
              View Event
            </Text>
            <View className="pl-1">
              <Image
                source={require("../../assets/icons/external.png")}
                className="w-5 h-4"
              />
            </View>
          </Button>
        )}
        {event.isPurchased && (
          <Button
            disabled={startListeningDisabled}
            onPress={handlePlayProgram}
            className={
              `rounded-xl w-full ` +
              (startListeningDisabled ? `bg-gray-500` : `bg-green-300`)
            }
          >
            <Text className="font-unbounded self-center text-black">
              Start Listening
            </Text>
          </Button>
        )}
        <View className="flex">
          <Text className="mt-6 text-lg text-white font-unbounded">
            Complete {event.streakMaxLength} Day Streak
          </Text>
          <CurrentStreak
            maxStreak={event.streakMaxLength}
            currentStreak={event.currentStreak}
            size="small"
          />
          <View className="border-b-[1px] border-slate-700 mt-4" />
        </View>
      </View>
      <View className="mx-3">
        <ProgramPreviewEpisodes
          doesHaveAccess={event.isPurchased}
          episodes={episodes}
          handleScroll={scrollToTop}
        />
      </View>
    </ScrollView>
  );
}
