import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import * as WebBrowser from "expo-web-browser";
import { useState } from "react";
import {
  View,
  StyleSheet,
  StatusBar,
  Text,
  Platform,
  KeyboardAvoidingView,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Button, Snackbar, Portal, IconButton } from "react-native-paper";

import LoginTextField from "../components/LoginTextField";
import TermsAndPrivacy from "../components/TermsAndPrivacy";
import { useNotificationContext } from "../contexts/NotificationContext";
import { useSignupMutation } from "../graphql/generated";
import { LoginScreenNavigationProps } from "../navigation/RootStackParamLoginList";
import { saveUser } from "../redux/actions/UserActions";
import type { User } from "../redux/reducers/UserReducer";
import { hasWhiteSpace } from "../utils/has-white-space";

export const isAndroid = () => Platform.OS === "android";

WebBrowser.maybeCompleteAuthSession();

function Signup() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("");
  const [signup, { loading }] = useSignupMutation();
  const { refetch: refetchNotifications } = useNotificationContext();

  const navigation = useNavigation<LoginScreenNavigationProps>();

  const validateInput = () => {
    if (!username || username.length === 0) {
      setError("Please fill out all *required fields");
      return false;
    }
    if (hasWhiteSpace(username)) {
      setError("Spaces are not allowed in usernames");
      return false;
    }
    if (!email || email.length === 0) {
      setError("Please fill out all *required fields");
      return false;
    }
    if (!password || password.length === 0) {
      setError("Please fill out all *required fields");
      return false;
    }

    if (password !== password2) {
      setError("Passwords do not match.");
      return false;
    }
    return true;
  };

  const registerUser = async () => {
    if (!validateInput()) {
      setVisible(true);
      return;
    }

    try {
      const userInfo = await signup({
        variables: {
          username: `${username}`,
          email: `${email}`,
          password: `${password}`,
        },
      });
      const register = userInfo.data?.register;
      if (!register || !register.jwt) {
        throw new Error("Username or email is already taken.");
      }
      // TODO fix type
      saveUser(register.jwt, register.user as unknown as User);
      await refetchNotifications();
    } catch (err) {
      const message = `${{ err }.err.message}` || String(err);
      setError(message);
      setVisible(true);
    }
  };
  return (
    <View className="absolute h-full w-full bg-black overflow-hidden">
      <ImageBackground
        className="h-full bg-black"
        source={require("../../assets/login-background.png")}
      >
        <ScrollView className="mb-[30px]" keyboardShouldPersistTaps="handled">
          <KeyboardAvoidingView style={{ flex: 1 }} behavior="position">
            <View className="h-full flex flex-col justify-start mx-10 pt-[60px]">
              <View className="flex flex-row w-full justify-start mb-[20px]">
                <IconButton
                  iconColor="black"
                  className="bg-white -ml-1"
                  onPress={() => navigation.navigate("Prelogin")}
                  icon="arrow-left"
                />
              </View>
              <Image
                style={{ width: 147, height: 24 }}
                source={require("../../assets/peak-logo-white.png")}
              />
              <>
                <StatusBar backgroundColor="#FFFFFF" barStyle="dark-content" />
              </>
              <View className="flex flex-col mt-12 w-full justify-start space-y-3">
                <Text className="-ml-1 font-unbounded text-white text-xl">
                  Sign Up
                </Text>
                <Text className="font-inter text-white text-sm">
                  Enter your info to create an account
                </Text>
              </View>
              <View style={styles.divider} />
              <View className="flex flex-col space-y-3">
                <View className="flex flex-col space-y-44" />
                <LoginTextField
                  textHook={setUsername}
                  placeholder="*Username"
                />
                <LoginTextField textHook={setEmail} placeholder="*Email" />
                <LoginTextField
                  textHook={setPassword}
                  placeholder="*Password"
                  secure
                />
                <LoginTextField
                  textHook={setPassword2}
                  placeholder="*Repeat Password"
                  secure
                />
              </View>
              <>
                <View style={styles.divider} />
                <Button
                  className="rounded-none bg-[#80EFAD] border-solid border-black"
                  textColor="#000000"
                  loading={loading}
                  disabled={loading}
                  onPress={() => registerUser()}
                >
                  <Text className="font-unbounded self-center">
                    Create Account
                  </Text>
                </Button>
                <View style={styles.divider} />
                <View style={styles.divider} />
              </>
              <>
                {/**
                 * We use a portal component to render
                 * the snackbar on top of everything else
                 * */}
                <Portal>
                  <Snackbar
                    visible={visible}
                    onDismiss={() => setVisible(false)}
                  >
                    {error}
                  </Snackbar>
                </Portal>
              </>
            </View>
          </KeyboardAvoidingView>
          <TermsAndPrivacy />
          <View className="flex flex-col justify-start w-full">
            <Text className="text-xs text-center text-white mt-[9px] md:mt-3">
              Already have an account?
            </Text>
            <TouchableOpacity
              onPress={() => navigation.navigate("Prelogin")}
              className="w-fit h-fit"
            >
              <Text
                className="text-white text-xs self-center mr-[4px]"
                style={{ textDecorationLine: "underline" }}
              >
                Log In
              </Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
}
const styles = StyleSheet.create({
  divider: {
    height: 16,
  },
});
export default Signup;
