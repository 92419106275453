import { useMemo } from "react";

import { useCurrentStreakQuery } from "../../graphql/generated";

export function useEventCurrentStreak() {
  const { data, loading, error, refetch } = useCurrentStreakQuery();
  const res = data?.currentStreak;

  const streak = useMemo(
    () =>
      res
        ? {
            currentStreakLength: res.currentStreakLength,
            longestStreakLength: res.longestStreakLength,
          }
        : null,
    [res]
  );

  return {
    streak,
    loading,
    error,
    refetch,
  };
}
