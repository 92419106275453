import { View } from "react-native";
import { Text } from "react-native-paper";

type ProgramPreviewNavigationProps = {
  navEpisodes: () => void;
  navOverview: () => void;
  navSupport: () => void;
  navFocus: string;
};

export function ProgramPreviewNavigation({
  navEpisodes,
  navOverview,
  navSupport,
  navFocus,
}: ProgramPreviewNavigationProps) {
  return (
    <View className="flex flex-row space-x-3">
      <Text
        onPress={navEpisodes}
        className={`text-md font-unbounded text-white ${
          navFocus === "Episodes" ? "underline text-[#80EFAD]" : ""
        }`}
      >
        Episodes
      </Text>
      <Text
        onPress={navOverview}
        className={`text-md font-unbounded text-white ${
          navFocus === "Overview" ? "underline text-[#80EFAD]" : ""
        }`}
      >
        Overview
      </Text>
      <Text
        onPress={navSupport}
        className={`text-md font-unbounded text-white ${
          navFocus === "Support" ? "underline text-[#80EFAD]" : ""
        }`}
      >
        Support
      </Text>
    </View>
  );
}
