import Slider from "@react-native-community/slider";
import { View, Text, Platform } from "react-native";

import { useProgress } from "../../hooks/audio-player/useProgress";
import TrackPlayer from "../../lib/TrackPlayerWrapper/TrackPlayerWrapper";
import { useMyPlayer } from "../../providers/FullscreenPlayerProvider";
import { secondsToFormattedDuration } from "../../utils/duration";

export function FullscreenProgressBar() {
  const { position, duration, percentage } = useProgress();
  const { isLoading } = useMyPlayer();

  const formattedPosition = secondsToFormattedDuration(position);

  const formattedDuration = secondsToFormattedDuration(duration);

  function onSlide(value: number) {
    void TrackPlayer.seekTo(value * (duration ?? 0));
  }

  const webStyles = Platform.OS !== "web" ? "-mt-2" : "";

  return (
    <View className="w-full">
      <View className="w-full flex flex-row items-center">
        <Text className="hidden md:flex text-sm text-subtle w-16">
          {formattedPosition}
        </Text>
        <View className="flex flex-1">
          <Slider
            minimumTrackTintColor="#80EFAD"
            maximumTrackTintColor="#FFFFFF65"
            disabled={isLoading}
            value={percentage / 100}
            onSlidingComplete={onSlide}
            thumbImage={require("../../../assets/player/progress-bar-thumb.png")}
          />
        </View>
        <Text className="hidden md:flex text-sm text-subtle w-16 justify-end">
          {formattedDuration}
        </Text>
      </View>
      <View
        className={`w-full self-center justify-between flex flex-row ${webStyles} md:hidden`}
      >
        <Text className="text-sm text-white">{formattedPosition}</Text>
        <Text className="text-sm text-white">{formattedDuration}</Text>
      </View>
    </View>
  );
}
