import { useNavigation, useNavigationState } from "@react-navigation/native";
import { Image } from "expo-image";
import { View, Text, TouchableOpacity } from "react-native";
import { ScreenNavigationProps } from "src/navigation/RootStackParamList";

const menuItems = [
  {
    label: "Home",
    screen: "Home",
  },
  {
    label: "Discover",
    screen: "Discover",
  },
  {
    label: "Social",
    screen: "Social",
  },
  {
    label: "Account",
    screen: "Account",
  },
] as const;

export function SidebarNavigation() {
  const navigation = useNavigation<ScreenNavigationProps>();

  const routeState = useNavigationState(
    (state) => state?.routes[state?.index ?? 0]
  );

  const currentScreen = routeState?.name ?? "Home";

  return (
    <View className="h-full">
      <Image
        className="mx-8 mr-10 mt-6 h-8 w-32"
        contentFit="contain"
        source={require("../../../assets/peak-logo-white.png")}
      />
      <View className="mt-10">
        {menuItems.map(({ label, screen }) => (
          <View
            key={label}
            className="flex flex-row items-center mt-3.5 ml-8 cursor-pointer"
          >
            {screen === currentScreen && (
              <Image
                className="w-1 h-1 absolute -left-3"
                source={require("../../../assets/icons/dot.png")}
              />
            )}
            <TouchableOpacity onPress={() => navigation.navigate(screen)}>
              <Text
                className={`font-unbounded ${
                  screen === currentScreen ? "text-active" : "text-subtle"
                }`}
              >
                {label}
              </Text>
            </TouchableOpacity>
          </View>
        ))}
      </View>
    </View>
  );
}
