import { useEffect } from "react";
import { View } from "react-native";
import { IconButton } from "react-native-paper";
import Video from "react-native-video";

import TrackPlayer from "../lib/TrackPlayerWrapper/TrackPlayerWrapper";

export type VideoPlayerProps = {
  videoUri: string;
  onClose: () => void;
};

// TODO add mux video player wrapper for mux data https://docs.mux.com/guides/data/monitor-react-native-video

function VideoPlayer({ videoUri, onClose }: VideoPlayerProps) {
  useEffect(() => {
    void TrackPlayer.pause();
  }, []);

  return (
    <View className="absolute h-full w-full bg-black pt-20">
      <IconButton
        iconColor="white"
        className="h-[33px] w-[33px] ml-[20px] border border-1 border-white rounded-none"
        onPress={() => onClose()}
        icon="chevron-down"
      />
      <Video
        source={{
          uri: videoUri,
        }} // Can be a URL or a local file.
        //  ref={(ref) => {
        //    ref?.presentFullscreenPlayer();
        //  }}                                      // Store reference
        //  onBuffer={this.onBuffer}                // Callback when remote video is buffering
        //  onError={this.videoError}               // Callback when video cannot be loaded
        volume={1.0}
        controls
        style={{
          width: "100%",
          height: "90%",
          backgroundColor: "black",
        }}
      />
    </View>
  );
}

export default VideoPlayer;
