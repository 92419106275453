import { useState, useEffect } from "react";
import { createSwitchNavigator, createAppContainer } from "react-navigation";

import BottomNavbar from "./BottomNavbar";
import PreloginNav from "./PreloginNav";
import { store } from "../redux/Store";

const RootNavigator = () => {
  const [route, setRoute] = useState(store.getState().jwt ? "Home" : "Login");

  const Navigator = createAppContainer(
    createSwitchNavigator(
      {
        Login: {
          screen: PreloginNav,
        },
        Home: {
          screen: BottomNavbar,
        },
      },
      {
        initialRouteName: route,
      }
    )
  );

  useEffect(() => {
    // FIXME unsubscribe or use selector
    store.subscribe(() => {
      setRoute(store.getState().jwt ? "Home" : "Login");
    });
  }, []);

  return <Navigator />;
};

export default RootNavigator;
