import { useUnsubscribeFromCreatorMutation } from "../../graphql/generated";

type UnsubscribeFromCreatorProps = {
  creatorUUID: string;
};

export function useUnsubscribeFromCreator({
  creatorUUID,
}: UnsubscribeFromCreatorProps) {
  const [
    unsubscribeFromCreator,
    { error: unsubscribeError, loading: unsubscribeLoading },
  ] = useUnsubscribeFromCreatorMutation({
    variables: { creatorUUID },
  });

  return {
    unsubscribeError,
    unsubscribeFromCreator,
    unsubscribeLoading,
  };
}
