import { FC, PropsWithChildren, createContext, useEffect } from "react";
import { useSelector } from "react-redux";

import { useNotificationContext } from "./NotificationContext";
import {
  GetProgramsAndEpisodesDocument,
  useClaimPromotionPurchaseMutation,
} from "../graphql/generated";
import { RootState } from "../redux/Store";
import { deletePromotionToken } from "../redux/actions/PromotionTokenActions";
import { ErrorReportingService } from "../services/ErrorReportingService";

const PromotionContext = createContext<null>(null);

export const PromotionProvider: FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const { refetch: refetchNotifications } = useNotificationContext();
  const promotionToken = useSelector<RootState, string | null>(
    (state) => state.promotionToken
  );

  const userSession = useSelector<RootState, string | null>(
    (state) => state.jwt
  );

  const [claimPromotionPurchase] = useClaimPromotionPurchaseMutation({
    refetchQueries: [GetProgramsAndEpisodesDocument],
    onCompleted: (res) => {
      console.log("Promotion claimed successfully", res.claimPromotionPurchase);
      deletePromotionToken();
      void refetchNotifications();
    },
    onError: (err) => {
      ErrorReportingService.report(err);
      console.error("Failed to claim promotion", err);
      deletePromotionToken();
      alert(`Failed to claim promotion: ${err?.message}`);
    },
  });

  useEffect(() => {
    if (!promotionToken) return;
    if (!userSession) return;

    void claimPromotionPurchase({
      variables: {
        promotionToken,
      },
    });
  }, [promotionToken, userSession]);

  return (
    <PromotionContext.Provider value={null}>
      {children}
    </PromotionContext.Provider>
  );
};
