import { View, Image } from "react-native";

import { IconStreakNumber } from "./IconStreakNumber";

export type CurrentStreakProps = {
  currentStreak: number;
  maxStreak: number;
  size: "small" | "large";
};

export function CurrentStreak({
  currentStreak,
  maxStreak,
  size,
}: CurrentStreakProps) {
  const line = (
    <View className="border-t-[0.5px] border-slate-500 w-1.5 self-center" />
  );

  const icons = Array.from({ length: maxStreak }).map((_, i) =>
    currentStreak >= i + 1 ? (
      <View key={i} className="flex flex-row">
        <Image
          className={
            size === "small" ? `h-[28px] w-[28px]` : `h-[36px] w-[36px]`
          }
          source={require("../../../assets/icons/streak-completed.png")}
        />
        {i !== maxStreak - 1 && line}
      </View>
    ) : (
      <View key={i} className="flex flex-row">
        <IconStreakNumber key={i} number={i + 1} size={size} />
        {i !== maxStreak - 1 && line}
      </View>
    )
  );

  return <View className="flex flex-row flex-1 mt-2">{icons}</View>;
}
