import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import { View, Text } from "react-native";
import { Button } from "react-native-paper";

import {
  FailedTransactionScreenProps,
  ScreenNavigationProps,
} from "../navigation/RootStackParamList";
import { goBack } from "../navigation/goBack";

function FailedTransaction({
  route: {
    params: { creatorUUID },
  },
}: FailedTransactionScreenProps) {
  const navigation = useNavigation<ScreenNavigationProps>();

  return (
    <View className="h-full w-full flex flex-col justify-center space-y-[15px] bg-main">
      <Image
        className="self-center w-[64px] h-[64px] mr-[10px]"
        source={require("../../assets/icons/error-sign.png")}
      />
      <Text className="font-unbounded text-white text-lg  w-[65%] self-center text-center">
        There was a problem with the transaction
      </Text>
      <Text className="font-inter text-white text-sm w-[80%] mb-[15px] self-center text-center">
        It could be a temporary network issue. Please try again. If the error
        persists, contact support.
      </Text>
      <View className="flex flex-row w-full justify-center">
        <View className="flex flex-col justify-center self-center w-[85%] space-y-3">
          <Button
            onPress={() => goBack(navigation)}
            className="border border-1 bg-[#80EFAD] rounded-2xl"
          >
            <Text className="font-unbounded text-black">Try Again</Text>
          </Button>
          <Button
            onPress={() =>
              navigation.navigate("Creator", {
                creatorUUID,
                backToHome: true,
              })
            }
            className="border border-1 border-[#80EFAD] rounded-2xl"
          >
            <Text className="font-unbounded text-[#80EFAD]">Go to Creator</Text>
          </Button>
        </View>
      </View>
    </View>
  );
}

export default FailedTransaction;
