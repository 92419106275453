import "react-native-get-random-values";
import * as Application from "expo-application";
import { Platform } from "expo-modules-core";
import * as SecureStore from "expo-secure-store";
import { v4 as uuidv4 } from "uuid";

const storageKey = "uniqueId";

export async function getDeviceId() {
  const savedUniqueId = await SecureStore.getItemAsync(storageKey);

  if (savedUniqueId) {
    return savedUniqueId;
  }

  const newUniqueId =
    Platform.OS === "android"
      ? Application.androidId ?? uuidv4().toString()
      : (await Application.getIosIdForVendorAsync()) ?? uuidv4().toString();

  await SecureStore.setItemAsync(storageKey, newUniqueId);

  return newUniqueId;
}
