import { useState } from "react";
import { View, Text } from "react-native";
import { Button } from "react-native-paper";

import { Event, EventRow } from "./EventRow";

type EventsContainerProps = {
  title: string;
  events: Event[];
  minItems: number;
};

export function EventsContainer({
  title,
  events,
  minItems,
}: EventsContainerProps) {
  const [isMinimized, setIsMinimized] = useState(true);

  const viewableEvents = isMinimized ? events.slice(0, minItems) : events;

  return (
    <View className="flex flex-col justify-center w-full mb-[30px] max-w-2xl">
      <View className="flex flex-row w-full justify-between mb-1">
        <Text className="text-xl font-unbounded text-white">{title}</Text>
        {events.length >= minItems && (
          <View>
            {isMinimized ? (
              <Button
                onPress={() => setIsMinimized(false)}
                className="bg-transparent rounded-none flex flex-col justify-center"
              >
                <Text className="font-unbounded self-center text-green-300 h-full">
                  View All
                </Text>
              </Button>
            ) : (
              <Button
                onPress={() => setIsMinimized(true)}
                className="bg-transparent rounded-none flex flex-col justify-center"
              >
                <Text className="font-unbounded self-center text-green-300 h-full">
                  Minimize
                </Text>
              </Button>
            )}
          </View>
        )}
      </View>
      {viewableEvents.map((event) => (
        <EventRow key={event.uuid} event={event} />
      ))}
    </View>
  );
}
