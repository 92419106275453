import { useDeactivateMyUserProfileMutation } from "../graphql/generated";

export function useDeactivateAccount() {
  const [
    deactivateAccount,
    { error: deactivateError, loading: deactivateLoading },
  ] = useDeactivateMyUserProfileMutation();

  return {
    deactivateLoading,
    deactivateAccount,
    deactivateError,
  };
}
