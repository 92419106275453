import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import { TouchableOpacity, View, Text } from "react-native";

import { EventStatusView, getColorByEventStatus } from "./EventStatusView";
import { EventStatus } from "../../graphql/generated";
import { ScreenNavigationProps } from "../../navigation/RootStackParamList";

export type Event = {
  uuid: string;
  title: string;
  imageUrl: string;
  status: EventStatus;
  progressPercentage: number;
};

type EventRowProps = {
  event: Event;
};

export function EventRow({ event }: EventRowProps) {
  const navigation = useNavigation<ScreenNavigationProps>();

  const { uuid, title, imageUrl, status, progressPercentage } = event;

  const showProgress = [EventStatus.Completed, EventStatus.InProgress].includes(
    status
  );

  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate("EventPreview", {
          eventUUID: uuid,
        })
      }
      className="bg-[#00000085] rounded-xl h-[73px] flex flex-row w-full mt-1.5 relative overflow-hidden"
    >
      <Image
        className="w-[73px] h-[73px] bg-black rounded-2xl"
        source={{
          uri: imageUrl,
        }}
      />
      <View className="absolute bottom-3 left-10">
        <EventStatusView status={status} />
      </View>
      <Image
        className="absolute right-0 w-[28px] h-[28px] bottom-[22px]"
        style={{ width: 28, height: 28 }}
        source={require("../../../assets/icons/angle-right.png")}
      />
      <View className="flex flex-col h-full flex-grow">
        <Text
          numberOfLines={1}
          className="text-md mt-1 font-unbounded text-white py-2 pl-2 flex-grow max-w-[86%]"
        >
          {title}
        </Text>
        {showProgress && (
          <View
            className="bg-slate-400 h-[2px]"
            style={{
              width: `${progressPercentage}%`,
              backgroundColor: getColorByEventStatus(status),
            }}
          />
        )}
      </View>
    </TouchableOpacity>
  );
}
