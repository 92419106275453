import { useNavigation } from "@react-navigation/native";

import { TutorialModal } from "./TutorialModal";
import { useCompleteTutorial } from "../../hooks/useCompleteTutorial";
import { ScreenNavigationProps } from "../../navigation/RootStackParamList";

type TutorialModalAccountProps = {
  refetch: () => void;
};

export function TutorialModalAccount({ refetch }: TutorialModalAccountProps) {
  const navigation = useNavigation<ScreenNavigationProps>();

  const { completeTutorial } = useCompleteTutorial();

  async function handleCompleteTutorial() {
    await completeTutorial();
    refetch();
  }

  async function handleNext() {
    await handleCompleteTutorial();
    setTimeout(function () {
      navigation.navigate("Home");
    }, 400);
  }

  return (
    <TutorialModal
      handleNext={handleNext}
      pageName="Account"
      handleCompleteTutorial={handleCompleteTutorial}
      nextText="Get Started"
      descriptionText="Manage your profile details and subscriptions."
      screenNumber={4}
    />
  );
}
