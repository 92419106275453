import {
  CurrentStreakDocument,
  LeaderboardMonthlyUsersDocument,
  LeaderboardUsersDocument,
  useMarkStreakProgressMutation,
} from "../../graphql/generated";
import TrackPlayer from "../../lib/TrackPlayerWrapper/TrackPlayerWrapper";
import { MyTrack } from "../../providers/FullscreenPlayerProvider";

type HandlePlayPressProps = {
  position: number;
  playlist: MyTrack[];
};

export function usePlayerPlay() {
  const [markStreakProgress] = useMarkStreakProgressMutation({
    refetchQueries: [
      CurrentStreakDocument,
      LeaderboardUsersDocument,
      LeaderboardMonthlyUsersDocument,
    ],
  });

  async function play(arg?: HandlePlayPressProps) {
    if (arg) {
      const { position, playlist } = arg;
      const bufferDuration = await TrackPlayer.getDuration();
      if (position >= bufferDuration - 2 && playlist.length === 1) {
        await TrackPlayer.seekTo(0);
      }
    }

    await TrackPlayer.play();
    void markStreakProgress();
  }

  return {
    play,
  };
}
