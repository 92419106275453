import { View } from "react-native";
import { Text, Button, IconButton } from "react-native-paper";

import { useMyPlayer } from "../../providers/FullscreenPlayerProvider";

type TutorialModalProps = {
  pageName: string;
  handleCompleteTutorial: () => void;
  nextText: string;
  descriptionText: string;
  handleNext: () => void;
  screenNumber: number;
};

export function TutorialModal({
  pageName,
  handleCompleteTutorial,
  nextText,
  descriptionText,
  handleNext,
  screenNumber,
}: TutorialModalProps) {
  const { currentTrack } = useMyPlayer();

  return (
    <View className="absolute z-20 h-full w-full flex flex-col justify-end">
      <View
        className={`h-fit py-[10px] ${
          currentTrack ? "mb-[150px]" : "mb-[90px]"
        } w-[95%] bg-[#816AE2] flex flex-col justify-center px-[10px] space-y-[5px] rounded-2xl self-center pb-[15px]`}
      >
        <View className="w-full flex flex-row justify-between ml-2">
          <Text className="text-black font-unbounded text-[18px] self-center">
            {pageName}
          </Text>
          <IconButton
            size={20}
            icon="close"
            onPress={() => handleCompleteTutorial()}
          />
        </View>
        <Text className="text-black text-sm ml-2">{descriptionText}</Text>
        <View className="w-full flex flex-row justify-between ml-[11px]">
          <View className="w-fit flex flex-row space-x-2 justify-around self-center">
            <View
              className={`rounded-full w-1 h-1 ${
                screenNumber >= 1 ? `bg-white` : `bg-[#FFFFFF60]`
              }`}
            />
            <View
              className={`rounded-full w-1 h-1 ${
                screenNumber >= 2 ? `bg-white` : `bg-[#FFFFFF60]`
              }`}
            />
            <View
              className={`rounded-full w-1 h-1 ${
                screenNumber >= 3 ? `bg-white` : `bg-[#FFFFFF60]`
              }`}
            />
            <View
              className={`rounded-full w-1 h-1 ${
                screenNumber >= 4 ? `bg-white` : `bg-[#FFFFFF60]`
              }`}
            />
          </View>
          <Button className="mr-2" onPress={() => handleNext()}>
            <Text className="text-white text-[15px] font-unbounded self-center">
              {nextText}
            </Text>
          </Button>
        </View>
      </View>
    </View>
  );
}
