import { SeverityLevel } from "@sentry/types/types/severity";
import { Platform } from "react-native";
import * as Sentry from "sentry-expo";

import { envConfig } from "../../env.config";

const { sentryDsn, sentryEnv } = envConfig;

type ReportingUser = {
  username: string;
  email: string;
};

export class ErrorReportingService {
  // prevent double init crash in expo dev tools
  private static initialized = false;

  static init() {
    if (this.initialized) return;

    Sentry.init({
      dsn: sentryDsn,
      enableInExpoDevelopment: false, // disable sentry for local development
      environment: sentryEnv,
      debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
    });

    this.initialized = true;
  }

  static setUser(user: ReportingUser) {
    return Platform.OS === "web"
      ? Sentry.Browser.setUser(user)
      : Sentry.Native.setUser(user);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static report(error: any) {
    return Platform.OS === "web"
      ? Sentry.Browser.captureException(error)
      : Sentry.Native.captureException(error);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static message(message?: any, severity?: SeverityLevel) {
    return Platform.OS === "web"
      ? Sentry.Browser.captureMessage(message, severity)
      : Sentry.Native.captureMessage(message, severity);
  }
}
