import { Dispatch, SetStateAction, useState } from "react";
import { KeyboardAvoidingView, View } from "react-native";
import { Text, Button } from "react-native-paper";

import {
  CreatorForUserDocument,
  GetMyUserProfileInfoDocument,
  MyPurchasedCreatorsDocument,
  ProgramPreviewDocument,
} from "../../graphql/generated";
import { useUnsubscribeFromCreator } from "../../hooks/creators/useUnsubscribeFromCreator";
import { useMyPlayer } from "../../providers/FullscreenPlayerProvider";
import LoadingScreen from "../Loading";

type ConfirmUnsubscribeModalProps = {
  setShowConfirmUnsubscribeModal: Dispatch<SetStateAction<undefined | string>>;
  creatorUUID: string;
};

export function ConfirmUnsubscribeModal({
  setShowConfirmUnsubscribeModal,
  creatorUUID,
}: ConfirmUnsubscribeModalProps) {
  const { currentTrack } = useMyPlayer();
  const { unsubscribeFromCreator } = useUnsubscribeFromCreator({ creatorUUID });
  const [isLoading, setIsLoading] = useState(false);

  async function handleUnsubscribe() {
    setIsLoading(true);
    await unsubscribeFromCreator({
      refetchQueries: [
        GetMyUserProfileInfoDocument,
        ProgramPreviewDocument,
        CreatorForUserDocument,
        MyPurchasedCreatorsDocument,
      ],
    });

    setShowConfirmUnsubscribeModal(undefined);
    setIsLoading(false);
  }

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior="padding"
      className="absolute h-screen w-screen flex flex-col justify-end"
    >
      <View className="h-full bg-[#00000095]" />
      {!isLoading && (
        <View
          className={`h-fit pt-[50px] ${
            currentTrack ? "pb-[160px]" : "pb-[100px]"
          } w-screen bg-[#151827] flex flex-col justify-end px-[20px] space-y-[30px]`}
        >
          <Text className="text-white font-unbounded text-lg">
            Are you sure you would like to deactivate your subscription?
          </Text>
          <View className="flex flex-row w-full mt-2 justify-center content-center space-x-[3%]">
            <Button
              onPress={() => setShowConfirmUnsubscribeModal(undefined)}
              className="bg-[#1D2033] border-[1px] border-[#80EFAD] rounded-xl w-[47%]"
            >
              <Text className="font-unbounded self-center text-[#80EFAD]">
                Cancel
              </Text>
            </Button>
            <Button
              onPress={() => handleUnsubscribe()}
              className="bg-[#80EFAD] rounded-xl w-[47%]"
            >
              <Text className="font-unbounded self-center text-[#000000]">
                Unsubscribe
              </Text>
            </Button>
          </View>
        </View>
      )}
      {isLoading && <LoadingScreen />}
    </KeyboardAvoidingView>
  );
}
