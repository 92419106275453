import { Image } from "expo-image";
import { useEffect } from "react";

import { useMostPopularProgramsQuery } from "../../graphql/generated";
import { getImagePreviewUrlSmall } from "../../utils/image-preview-url";
import { isNew } from "../../utils/is-new";
import { notEmpty } from "../../utils/not-empty";

export function useMostPopularPrograms() {
  const { data, loading, refetch } = useMostPopularProgramsQuery({
    fetchPolicy: "cache-and-network",
  });

  const programsData = data?.mostPopularPrograms?.filter(notEmpty) || [];

  const programs = programsData.map((row) => ({
    uuid: row.uuid,
    title: row.title,
    imageUrl: row.image ? getImagePreviewUrlSmall(row.image) : "",
    episodesCount: row.episodeCount,
    playsCount: row.streams,
    isAccessible: row.isPurchased || row.isSubscribed,
    programProductId: row.revenueCatProductId ?? undefined,
    isNew: isNew(row.publishedAt),
    priceInCents:
      typeof row.priceInCents === "string" && row.priceInCents.length > 0
        ? parseInt(row.priceInCents, 10)
        : null,
    isFree: row.isFree || false,
  }));

  const newPrograms = programs.filter((program) => program.isNew);

  useEffect(() => {
    Image.prefetch(programs.map((program) => program.imageUrl));
  }, [programs]);

  return {
    programs,
    newPrograms,
    loading,
    refetch,
  };
}
