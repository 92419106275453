// We can import only types from react-native-track-player because the lib does not have any implementation for web and it breaks the web build & runtime
import type {
  PlaybackProgressUpdatedEvent,
  Track,
} from "react-native-track-player";

import { TrackPlayerWrapperInterface } from "./TrackPlayerWrapper.interface";
import { AppKilledPlaybackBehavior } from "./web/AppKilledPlaybackBehavior";
import { Capability } from "./web/Capability";
import { Event } from "./web/Event";
import { RepeatMode } from "./web/RepeatMode";
import { State } from "./web/State";
import { WebTrackPlayer } from "./web/WebTrackPlayer";
import { getProgressHook } from "./web/getProgressHook";
import { getTrackPlayerEventsHook } from "./web/getTrackPlayerEventsHook";

export {
  // interfaces
  PlaybackProgressUpdatedEvent,
  Track,
  // enums
  Event,
  RepeatMode,
  AppKilledPlaybackBehavior,
  Capability,
  State,
};

const webTrackPlayer = new WebTrackPlayer();

export const useTrackPlayerEvents = getTrackPlayerEventsHook(webTrackPlayer);
export const useProgress = getProgressHook(webTrackPlayer);

const TrackPlayerWrapper: TrackPlayerWrapperInterface = webTrackPlayer;

export default TrackPlayerWrapper;
