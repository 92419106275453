export function secondsToFormattedDuration(secondsArg: number) {
  const seconds = Math.floor(secondsArg);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const secondsDuration = (seconds % 60).toString().padStart(2, "0");
  const minutesDuration =
    hours > 0 ? (minutes % 60).toString().padStart(2, "0") : minutes % 60;
  const hoursDuration = hours > 0 ? `${hours}:` : "";

  return `${hoursDuration}${minutesDuration}:${secondsDuration}`;
}

export function millisToSeconds(millis: number) {
  return Math.floor(millis / 1000);
}
