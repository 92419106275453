import { Image } from "expo-image";
import { View, Text, TouchableOpacity } from "react-native";
import { Button } from "react-native-paper";

import Avatar, { ImageSize } from "./Avatar";

type TrialConfirmationScreenProps = {
  onCancel: () => void;
  onConfirm: () => void;
  creatorName: string;
  creatorImageUrl: string;
};

export function TrialConfirmationScreen({
  onCancel,
  onConfirm,
  creatorName,
  creatorImageUrl,
}: TrialConfirmationScreenProps) {
  return (
    <View className="h-full bg-main w-full p-10 flex flex-col items-center justify-center">
      <TouchableOpacity onPress={onCancel} className="absolute top-14 left-4">
        <Image
          className="h-10 w-10"
          contentFit="contain"
          source={require("../../assets/icons/back.png")}
        />
      </TouchableOpacity>
      <Avatar imageSize={ImageSize.XLarge} thumbnail={creatorImageUrl} />
      <Text className="text-2xl font-unbounded text-white mt-4">
        Free 24h Trial
      </Text>
      <Text className="text-md text-white text-center mt-1 max-w-sm">
        Entering the free trial grants 24-hour access to {creatorName}'s
        library. Please note: This trial can only be redeemed once per creator.
      </Text>
      <Button
        onPress={onConfirm}
        className="bg-[#80EFAD] border-[1px] border-[#80EFAD] rounded-2xl w-80 mt-8"
      >
        <Text className="self-center text-black font-unbounded">
          Start Trial
        </Text>
      </Button>
      <View className="border-[1px] border-[#80EFAD] rounded-2xl mt-3">
        <Button onPress={onCancel} className="bg-[#1D2033] w-80 ">
          <Text className="font-unbounded self-center text-[#80EFAD]">
            Go Back
          </Text>
        </Button>
      </View>
    </View>
  );
}
