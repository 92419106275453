import { useState } from "react";
import { View, Text } from "react-native";
import { Button } from "react-native-paper";

import ProgramBanner from "./ProgramBanner";

type ProgramContainerItem = {
  uuid: string;
  title: string;
  imageUrl: string;
  isAccessible: boolean;
  episodesCount: number;
  programProductId: string | undefined;
  isNew: boolean;
  priceInCents: number | null;
  isFree: boolean;
};

type ProgramsContainerProps = {
  programs: ProgramContainerItem[];
  title: string;
  minPrograms: number;
};

function ProgramsContainer({
  programs,
  title,
  minPrograms,
}: ProgramsContainerProps) {
  const [isMinimized, setIsMinimized] = useState(true);

  const viewablePrograms = isMinimized
    ? programs.slice(0, minPrograms)
    : programs;

  return (
    <View className="flex flex-col justify-start mb-4">
      <View className="flex flex-row w-full justify-between">
        <Text className="text-xl font-unbounded text-white mb-3">{title}</Text>
        {programs.length >= minPrograms && (
          <View>
            {isMinimized ? (
              <Button
                onPress={() => setIsMinimized(false)}
                className="bg-transparent rounded-none flex flex-col justify-center"
              >
                <Text className="font-unbounded self-center text-green-300 h-full">
                  View All
                </Text>
              </Button>
            ) : (
              <Button
                onPress={() => setIsMinimized(true)}
                className="bg-transparent rounded-none flex flex-col justify-center"
              >
                <Text className="font-unbounded self-center text-green-300 h-full">
                  Minimize
                </Text>
              </Button>
            )}
          </View>
        )}
      </View>
      {viewablePrograms.map((program) => {
        return <ProgramBanner key={program.uuid} program={program} />;
      })}
    </View>
  );
}
export default ProgramsContainer;
