import { useCallback, useEffect, useState } from "react";
import { Platform } from "react-native";
import { PurchasesStoreProduct } from "react-native-purchases";

import { useStorePurchase } from "../contexts/StorePurchasesContext";
import { ErrorReportingService } from "../services/ErrorReportingService";

function getPlatformPriceString(
  product: PurchasesStoreProduct | undefined,
  webPriceInCents: number | null
) {
  return Platform.OS === "web" && webPriceInCents !== null
    ? `$ ${webPriceInCents / 100}`
    : product?.priceString || "$ ---";
}

export function useStoreProducts(productIds: string[]) {
  const { getProducts, isConfigured } = useStorePurchase();
  const [products, setProducts] = useState<PurchasesStoreProduct[]>([]);
  const [loading, setLoading] = useState(false);

  const [inputProductIds, setInputProductIds] = useState<string[]>([]);

  // optimization to prevent unnecessary refetches
  useEffect(() => {
    if (JSON.stringify(productIds) !== JSON.stringify(inputProductIds)) {
      setInputProductIds(productIds);
    }
  }, [productIds]);

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const productsRes = await getProducts(inputProductIds);
      setProducts(productsRes);
    } catch (err) {
      const errorMessage = `Error fetching products: ${err.message}`;
      console.error(errorMessage, err);
      alert(errorMessage);
      ErrorReportingService.report(err);
      ErrorReportingService.message(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [inputProductIds, getProducts]);

  useEffect(() => {
    if (!isConfigured) return;
    void refetch();
  }, [refetch, isConfigured]);

  return {
    loading,
    products,
    refetch,
    getPlatformPriceString,
  };
}
