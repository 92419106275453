import { Dispatch, SetStateAction, useState } from "react";
import { TextInput } from "react-native-paper";

type LoginTextFieldProps = {
  textHook: Dispatch<SetStateAction<string>>;
  placeholder: string;
  secure?: boolean;
};

function LoginTextField({
  textHook,
  placeholder,
  secure,
}: LoginTextFieldProps) {
  const [hidePass, setHidePass] = useState(true);

  return (
    <TextInput
      className="bg-[#00000055] border border-[#FFFFFF50] mb-2"
      style={{ backgroundColor: "#00000075", textAlign: "auto" }}
      onChangeText={(text) =>
        textHook(secure ? text : text.toLocaleLowerCase())
      }
      autoCapitalize="none"
      placeholder={placeholder}
      placeholderTextColor="#FFFFFF55"
      textColor="#FFFFFF"
      selectionColor="#FFFFFF"
      secureTextEntry={secure && hidePass}
      right={
        secure ? (
          <TextInput.Icon
            icon={hidePass ? "eye" : "eye-off-outline"}
            iconColor="#FFFFFF80"
            onPress={() => setHidePass((arg) => !arg)}
          />
        ) : null
      }
    />
  );
}

export default LoginTextField;
