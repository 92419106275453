import { useUserProfile } from "./useUserProfile";
import { useUpdateMyUserProfileMutation } from "../graphql/generated";

export function useCompleteTutorial() {
  const { userProfile } = useUserProfile();
  const [
    completeTutorial,
    { error: updateProfileError, loading: updateProfileLoading },
  ] = useUpdateMyUserProfileMutation({
    variables: {
      image: userProfile?.image?.data?.id || "",
      isFirstTimeUse: userProfile?.isFirstTimeUse || false,
      completedTutorial: true,
      bio: userProfile?.bio || "",
      location: userProfile?.location || "",
      username: userProfile?.username || "",
    },
  });

  return {
    updateProfileLoading,
    updateProfileError,
    completeTutorial,
  };
}
