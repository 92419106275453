import { View, Text } from "react-native";
import { Button } from "react-native-paper";

type TabConfig = {
  key: string;
  title: string;
  content: React.ReactNode;
};

type TabNavigationProps = {
  tabs: TabConfig[];
  activeKey: string;
  onTabChange: (key: string) => void;
};
export function TabNavigation({
  activeKey,
  tabs,
  onTabChange,
}: TabNavigationProps) {
  return (
    <>
      <View className="flex flex-row space-x-3 mb-3">
        {tabs.map((tab) => (
          <Button
            key={tab.key}
            className={`rounded-3xl px-1 ${
              activeKey === tab.key ? "bg-[#80EFAD]" : "bg-[#34374a]"
            }`}
            onPress={() => onTabChange(tab.key)}
          >
            <Text
              className={`font-unbounded ${
                activeKey === tab.key ? "text-black" : "text-slate-300"
              }`}
            >
              {tab.title}
            </Text>
          </Button>
        ))}
      </View>
      {tabs.find(({ key }) => key === activeKey)?.content}
    </>
  );
}
