import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Image } from "expo-image";
import { useEffect, useState } from "react";
import { Dimensions, View } from "react-native";
import { Text } from "react-native-paper";

import { RootStackParamList } from "./RootStackParamList";
import { WebMinimizedAudioPlayer } from "../components/audio-player/WebMinimizedAudioPlayer";
import { SidebarNavigation } from "../components/navigation/SidebarNavigation";
import { useUserProfile } from "../hooks/useUserProfile";
import { useMyPlayer } from "../providers/FullscreenPlayerProvider";
import Creator from "../screens/Creator";
import Discover from "../screens/Discover";
import EditProfile from "../screens/EditProfile";
import { EventPreviewScreen } from "../screens/EventPreviewScreen";
import FailedTransaction from "../screens/FailedTransaction";
import FirstTime from "../screens/FirstTime";
import HomePage from "../screens/HomePage";
import ProgramPreview from "../screens/ProgramPreview";
import Purchase from "../screens/Purchase";
import Settings from "../screens/Settings";
import Social from "../screens/Social";
import SuccessfulTransaction from "../screens/SuccessfulTransaction";

export const Tab = createBottomTabNavigator<RootStackParamList>();

const mdWidth = 768;

function BottomNavbar() {
  const { isFullscreen } = useMyPlayer();
  const { userProfile } = useUserProfile();

  const [isMdWidth, setIsMdWidth] = useState(
    Dimensions.get("window").width > mdWidth
  );

  useEffect(() => {
    const subscription = Dimensions.addEventListener("change", ({ window }) => {
      if (window.width > mdWidth) {
        setIsMdWidth(true);
      } else {
        setIsMdWidth(false);
      }
    });
    return () => subscription?.remove();
  }, []);

  return (
    <View className="w-full h-full flex flex-col bg-black">
      <View className="w-full flex flex-row flex-1">
        <View className="hidden md:flex">
          <SidebarNavigation />
        </View>
        <View className="h-full flex-1 md:rounded-tl-[50px] md:overflow-hidden">
          <Tab.Navigator
            backBehavior="history"
            screenOptions={{
              headerShown: false,
              tabBarStyle: {
                display:
                  isFullscreen || userProfile?.isFirstTimeUse || isMdWidth
                    ? "none"
                    : "flex",
                backgroundColor: "#000000",
                borderTopWidth: 0,
                height: 80,
                paddingTop: 12,
                paddingBottom: 24,
              },
              tabBarLabelPosition: "below-icon",
              tabBarLabelStyle: { fontSize: 12, fontFamily: "Unbounded" },
            }}
          >
            <Tab.Screen
              options={{
                tabBarLabel: ({ focused }) => (
                  <Text
                    style={{
                      fontFamily: "Unbounded",
                      color: focused ? "white" : "gray",
                    }}
                    className="md:hidden"
                  >
                    Home
                  </Text>
                ),
                tabBarIcon: ({ focused, size }) => (
                  <Image
                    style={{ width: size, height: size }}
                    className="md:hidden"
                    source={
                      focused
                        ? require("../../assets/menu/Home.png")
                        : require("../../assets/menu/HomeGray.png")
                    }
                  />
                ),
              }}
              name="Home"
              component={HomePage}
            />
            <Tab.Screen
              options={{
                tabBarLabel: ({ focused }) => (
                  <Text
                    style={{
                      fontFamily: "Unbounded",
                      color: focused ? "white" : "gray",
                    }}
                  >
                    Discover
                  </Text>
                ),
                tabBarIcon: ({ focused, size }) => (
                  <Image
                    style={{ width: size, height: size }}
                    source={
                      focused
                        ? require("../../assets/menu/Discover.png")
                        : require("../../assets/menu/DiscoverGray.png")
                    }
                  />
                ),
              }}
              name="Discover"
              component={Discover}
            />
            <Tab.Screen
              options={{
                tabBarLabel: ({ focused }) => (
                  <Text
                    style={{
                      fontFamily: "Unbounded",
                      color: focused ? "white" : "gray",
                    }}
                  >
                    Social
                  </Text>
                ),
                tabBarIcon: ({ focused, size }) => (
                  <Image
                    style={{ width: size, height: size }}
                    source={
                      focused
                        ? require("../../assets/menu/Social.png")
                        : require("../../assets/menu/SocialGray.png")
                    }
                  />
                ),
              }}
              name="Social"
              component={Social}
            />
            <Tab.Screen
              options={{
                tabBarLabel: ({ focused }) => (
                  <Text
                    style={{
                      fontFamily: "Unbounded",
                      color: focused ? "white" : "gray",
                    }}
                  >
                    Account
                  </Text>
                ),
                tabBarIcon: ({ focused, size }) => (
                  <Image
                    style={{ width: size, height: size }}
                    source={
                      focused
                        ? require("../../assets/menu/Account.png")
                        : require("../../assets/menu/AccountGray.png")
                    }
                  />
                ),
              }}
              name="Account"
              component={Settings}
            />
            <Tab.Screen
              options={{
                tabBarButton: () => null,
              }}
              name="Creator"
              component={Creator}
            />
            <Tab.Screen
              options={{
                tabBarButton: () => null,
              }}
              name="EventPreview"
              component={EventPreviewScreen}
            />
            <Tab.Screen
              options={{
                tabBarButton: () => null,
              }}
              name="FirstTime"
              component={FirstTime}
            />
            <Tab.Screen
              options={{
                tabBarButton: () => null,
              }}
              name="ProgramPreview"
              component={ProgramPreview}
            />
            <Tab.Screen
              options={{
                tabBarButton: () => null,
              }}
              name="Purchase"
              component={Purchase}
            />
            <Tab.Screen
              options={{
                tabBarButton: () => null,
              }}
              name="FailedTransaction"
              component={FailedTransaction}
            />
            <Tab.Screen
              options={{
                tabBarButton: () => null,
              }}
              name="EditProfile"
              component={EditProfile}
            />
            <Tab.Screen
              options={{
                tabBarLabel: ({ focused }) => (
                  <Text
                    style={{
                      fontFamily: "Unbounded",
                      color: focused ? "white" : "gray",
                    }}
                  >
                    Creator
                  </Text>
                ),
                tabBarButton: () => null,
                tabBarIcon: ({ focused, size }) => (
                  <Image
                    style={{ width: size, height: size }}
                    source={
                      focused
                        ? require("../../assets/menu/Account.png")
                        : require("../../assets/menu/AccountGray.png")
                    }
                  />
                ),
              }}
              name="SuccessfulTransaction"
              component={SuccessfulTransaction}
            />
          </Tab.Navigator>
        </View>
      </View>
      {isMdWidth && <WebMinimizedAudioPlayer />}
    </View>
  );
}

export default BottomNavbar;
