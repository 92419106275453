import Constants from "expo-constants";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";

import { usePushNotificationSaveDeviceTokenMutation } from "../graphql/generated";
import { getDeviceId } from "../lib/getDeviceId";

async function getPushNotificationsTokenAsync() {
  if (Platform.OS === "android") {
    void Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();

    const finalStatus =
      existingStatus === "granted"
        ? "granted"
        : await Notifications.requestPermissionsAsync().then(
            ({ status }) => status
          );

    if (finalStatus !== "granted") {
      console.warn(
        "Failed to get push token for push notification! status: " + finalStatus
      );
      return;
    }

    const tokenRes = await Notifications.getExpoPushTokenAsync({
      projectId: Constants.expoConfig?.extra?.eas.projectId,
    });

    return tokenRes.data;
  }

  console.log("Must use physical device for Push Notifications");
  return null;
}

export function useRegisterPushNotification() {
  const [saveDeviceToken] = usePushNotificationSaveDeviceTokenMutation();

  async function register() {
    const token = await getPushNotificationsTokenAsync();

    if (!token) return;

    const deviceId = await getDeviceId();

    return saveDeviceToken({
      variables: {
        token,
        deviceId,
      },
    });
  }

  async function getStatus() {
    const res = await Notifications.getPermissionsAsync();
    return res.status;
  }

  return {
    register,
    getStatus,
  };
}
