import { useNavigation } from "@react-navigation/native";
import { View, Text, TouchableOpacity, Linking } from "react-native";
import { Button } from "react-native-paper";
import VersionInfo from "react-native-version-info";

import { envConfig } from "../../../env.config";
import packageJson from "../../../package.json";
import { ScreenNavigationProps } from "../../navigation/RootStackParamList";
import ProgramGridContainer from "../ProgramGridContainer";

type ProgramContainerElement = {
  uuid: string;
  title: string;
  imageUrl: string;
  episodesCount: number;
  isAccessible: boolean;
  programProductId: string | undefined;
  isNew: boolean;
  priceInCents: number | null;
  isFree: boolean;
};

type AccountProps = {
  programs: ProgramContainerElement[];
  freePrograms: ProgramContainerElement[];
};

function Account({ programs, freePrograms }: AccountProps) {
  const navigation = useNavigation<ScreenNavigationProps>();
  const { environment } = envConfig;

  const envString = environment ? environment?.substring(0, 1) : "";

  const version = VersionInfo.buildVersion ?? packageJson.version;

  async function openPrivacyPolicy() {
    await Linking.openURL("https://peak.audio/privacy");
  }

  async function openTermsOfService() {
    await Linking.openURL("https://peak.audio/terms");
  }

  return (
    <View className="h-full w-full self-start my-[5%]">
      <View className="h-full w-full px-2 self-start mt-[5%] pb-[50px]">
        {programs.length !== 0 && (
          <ProgramGridContainer
            title="Programs"
            programs={programs}
            gridSize={10}
          />
        )}
        {programs.length === 0 && (
          <View className="flex flex-col w-full justify-start mt-[20px] space-y-[15px]">
            <Text className="text-[#FFFFFF65] text-center">
              You haven't purchased any programs yet.
            </Text>
            <Button
              onPress={() => navigation.navigate("Discover")}
              className="border border-1 bg-[#80EFAD] rounded-2xl w-[75%] self-center"
            >
              <Text className="font-unbounded text-black">
                Discover Programs
              </Text>
            </Button>
          </View>
        )}
        <View className="w-full h-[50px] flex flex-col justify-center space-y-1">
          <View className="w-full h-[20px] mt-[20px] flex flex-row justify-center">
            <TouchableOpacity
              onPress={openTermsOfService}
              className="w-fit h-fit self-center"
            >
              <Text
                className="text-[#80EFAD] text-xs text-center mr-[4px]"
                style={{ textDecorationLine: "underline" }}
              >
                Terms of Service
              </Text>
            </TouchableOpacity>
            <Text className="text-[#80EFAD] text-xs text-center mr-[4px] self-center">
              |
            </Text>
            <TouchableOpacity
              className="w-fit h-fit self-center"
              onPress={openPrivacyPolicy}
            >
              <Text
                className="text-[#80EFAD] text-xs self-center"
                style={{ textDecorationLine: "underline" }}
              >
                Privacy Policy
              </Text>
            </TouchableOpacity>
          </View>
          <View className="w-full h-[20px] flex flex-row justify-center">
            <View className="w-fit h-fit self-center">
              <Text className="text-[#80EFAD] text-xs text-center mr-[4px]">
                {`Peak.audio v${version}.${envString || "w"}`}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

export default Account;
