import { Platform } from "react-native";
import WebView from "react-native-webview";

export type WebviewDiscordProps = {
  serverId: string;
  channelId: string;
};

export function WebviewDiscord({ serverId, channelId }: WebviewDiscordProps) {
  const discordUrl = `https://discord.com/channels/${serverId}/${channelId}`;

  if (Platform.OS === "web") {
    // Discord does not allow embedding in iframe
    return null;
  }

  return (
    <WebView
      className="bg-slate-800 flex-1 rounded-none"
      scrollEnabled={false}
      scalesPageToFit={false}
      sharedCookiesEnabled
      thirdPartyCookiesEnabled
      domStorageEnabled
      javaScriptEnabled
      originWhitelist={["*"]}
      source={{
        uri: discordUrl,
      }}
    />
  );
}
