import { Image } from "expo-image";
import { useEffect, useMemo } from "react";

import {
  useLeaderboardMonthlyUsersQuery,
  useLeaderboardUsersQuery,
} from "../../graphql/generated";
import { getImagePreviewUrlThumbnail } from "../../utils/image-preview-url";
import { notEmpty } from "../../utils/not-empty";

export function useUserLeaderboard() {
  const {
    data: dataAll,
    loading: loadingAll,
    error: errorAll,
    refetch: refetchAll,
  } = useLeaderboardUsersQuery({
    fetchPolicy: "cache-and-network",
  });

  const {
    data: dataMonthly,
    loading: loadingMonthly,
    error: errorMonthly,
    refetch: refetchMonthly,
  } = useLeaderboardMonthlyUsersQuery({
    fetchPolicy: "cache-and-network",
  });

  const users = useMemo(
    () =>
      dataAll?.leaderboardUsers?.filter(notEmpty).map((row) => ({
        userProfileUUID: row.userProfileUUID,
        name: row.name,
        imageUrl: row.image ? getImagePreviewUrlThumbnail(row.image) : "",
        longestStreak: row.longestStreak,
        currentStreak: row.currentStreak,
        totalListenedSeconds: row.totalListenedSeconds,
      })) || [],
    [dataAll]
  );

  const usersMonthly = useMemo(
    () =>
      dataMonthly?.leaderboardMonthlyUsers?.filter(notEmpty).map((row) => ({
        userProfileUUID: row.userProfileUUID,
        name: row.name,
        imageUrl: row.image ? getImagePreviewUrlThumbnail(row.image) : "",
        longestStreak: row.longestStreak,
        currentStreak: row.currentStreak,
        totalListenedSeconds: row.totalListenedSeconds,
      })) || [],
    [dataMonthly]
  );

  useEffect(() => {
    Image.prefetch(users.map((r) => r.imageUrl));
  }, [users]);

  const loading = loadingAll || loadingMonthly;

  const error = errorAll || errorMonthly;

  function refetch() {
    return Promise.all([refetchAll(), refetchMonthly()]);
  }

  return {
    users,
    usersMonthly,
    loading,
    error,
    refetch,
  };
}
