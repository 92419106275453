import { Image } from "expo-image";
import * as Linking from "expo-linking";
import { useState } from "react";
import {
  View,
  KeyboardAvoidingView,
  Text,
  RefreshControl,
  ScrollView,
  Platform,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { IconButton } from "react-native-paper";

import Avatar, { ImageSize } from "../components/Avatar";
import LoadingScreen from "../components/Loading";
import LoadingSpinner from "../components/LoadingSpinner";
import { WarningTextbox } from "../components/WarningTextbox";
import {
  WebviewDiscord,
  WebviewDiscordProps,
} from "../components/WebviewDiscord";
import WidgetbotDiscord, {
  WidgetbotDiscordProps,
} from "../components/WidgetbotDiscord";
import { AudioPlayer } from "../components/audio-player/AudioPlayer";
import { TutorialModalSocial } from "../components/tutorial/TutorialModalSocial";
import { useJoinCreatorDiscordServerMutation } from "../graphql/generated";
import { usePurchasedCreators } from "../hooks/creators/usePurchasedCreators";
import { useDiscordAuth } from "../hooks/useDiscordAuth";
import { useUserProfile } from "../hooks/useUserProfile";
import { useMyPlayer } from "../providers/FullscreenPlayerProvider";
import { ErrorReportingService } from "../services/ErrorReportingService";

function Social() {
  const [widgetbotDiscordConfig, setWidgetbotDiscordConfig] =
    useState<WidgetbotDiscordProps>();

  const [webviewDiscordConfig, setWebviewDiscordConfig] =
    useState<WebviewDiscordProps>();

  const [isRefreshing, setIsRefreshing] = useState(false);

  const { currentTrack } = useMyPlayer();

  const {
    linkAccount: linkDiscordAccount,
    loading: discordLoading,
    refetch: refetchDiscord,
    username: discordUsername,
  } = useDiscordAuth();

  const { creators, refetch, loading } = usePurchasedCreators();

  const { userProfile, refetch: userProfileRefetch } = useUserProfile();
  const [joinCreatorDiscordServer, { loading: joinDiscordServerLoading }] =
    useJoinCreatorDiscordServerMutation();

  async function handleRefresh() {
    setIsRefreshing(true);
    try {
      await Promise.all([refetchDiscord(), refetch(), userProfileRefetch()]);
    } catch (e) {
      console.log("Error refreshing social page data", e);
    }
    setIsRefreshing(false);
  }

  const refreshControl = (
    <RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} />
  );

  const isLoading = loading || discordLoading || joinDiscordServerLoading;
  const isWebviewOpen = !!webviewDiscordConfig || !!widgetbotDiscordConfig;

  async function onCreatorDiscordPress({
    discordChannel,
    discordServer,
    uuid,
  }: (typeof creators)[number]) {
    if (!discordChannel || !discordServer) {
      alert("This creator has not set up a Discord server and channel yet.");
      return;
    }

    if (!discordUsername) {
      return setWidgetbotDiscordConfig({
        channelId: discordChannel,
        serverId: discordServer,
      });
    }

    try {
      // Since discord is linked, we need to join the server before opening it
      const joinedServer = await joinCreatorDiscordServer({
        variables: {
          creatorProfileUUID: uuid,
        },
      });
      if (joinedServer.data?.joinCreatorDiscordServer) {
        // Discord profile is linked -> open real discord
        if (Platform.OS === "web") {
          // If we are on web, and discord app is not available -> open web discord in separate tab
          const discordWebUrl = `https://discord.com/channels/${discordServer}/${discordChannel}`;
          return window.open(discordWebUrl, "_blank");
        } else {
          try {
            // Try to open discord app, if it fails, open web
            const discordUrl = `discord://discord.com/channels/${discordServer}/${discordChannel}`;
            const res = await Linking.openURL(discordUrl);

            console.log("Discord app opened", res);
          } catch (e) {
            console.log(
              "Failed to open discord app, probably not installed or user canceled. Opening web",
              e
            );
            return setWebviewDiscordConfig({
              channelId: discordChannel,
              serverId: discordServer,
            });
          }
        }
      } else {
        throw new Error("Failed to join discord server");
      }
    } catch (e) {
      const errorMessage = `Error joining discord server: ${e.message}`;
      ErrorReportingService.message(errorMessage);
      ErrorReportingService.report(e);
      alert(errorMessage);
    }
  }

  return (
    <View className="h-full text-center flex flex-col bg-main">
      {!isLoading && !isWebviewOpen && (
        <View className="flex h-max w-full">
          {isRefreshing && (
            <View className="mt-[60px]">
              <LoadingSpinner />
            </View>
          )}
          <ScrollView
            scrollEnabled={!isWebviewOpen}
            nestedScrollEnabled
            scrollEventThrottle={16}
            refreshControl={refreshControl}
          >
            <View className="px-3 pt-[60px] md:px-14 flex flex-col h-full w-full space-y-5">
              {!discordUsername && !discordLoading && (
                <WarningTextbox
                  className="mx-5"
                  onPress={() => linkDiscordAccount()}
                >
                  <Text className="underline">Connect your Discord</Text>{" "}
                  account for a better experience
                </WarningTextbox>
              )}
              <Text className="text-white text-xl font-unbounded">
                Creators you follow
              </Text>
              {creators.map((creator) => (
                <TouchableOpacity
                  key={creator.uuid}
                  onPress={() => onCreatorDiscordPress(creator)}
                  className="flex flex-row w-full items-center"
                >
                  <Avatar
                    thumbnail={creator.avatarUrl}
                    imageSize={ImageSize.Small}
                    isSubscribed
                  />
                  <Text
                    numberOfLines={1}
                    className="font-inter text-lg text-[#FFFFFF] ml-3"
                  >{`${creator.name}`}</Text>
                  <Image
                    className="ml-auto"
                    style={{ width: 28, height: 28 }}
                    source={require("../../assets/icons/angle-right.png")}
                  />
                </TouchableOpacity>
              ))}
              {discordLoading && <LoadingSpinner />}
              {creators.length === 0 && (
                <View className="h-[90%] w-[90%] ml-[5%] flex flex-col justify-center">
                  <Text className="font-unbounded text-center text-md text-[#FFFFFF75]">
                    Wow - looking pretty empty. Why not try subscribing to some
                    creators?
                  </Text>
                </View>
              )}
            </View>
          </ScrollView>
          <View className={`${currentTrack ? `pb-[80px]` : `pb-[0px]`}`} />
        </View>
      )}

      {isLoading && <LoadingScreen />}
      {/* TODO fixme - show discord and hide the rest based on state or use stacked nav. Do not use absolute positioning */}
      {widgetbotDiscordConfig && (
        <KeyboardAvoidingView
          className="flex flex-col flex-grow"
          behavior="padding"
        >
          <View className="bg-[#313338] py-1 px-1 pt-[40px]">
            <IconButton
              iconColor="black"
              className="h-[35px] w-[35px] bg-[#80EFAD]"
              onPress={() => setWidgetbotDiscordConfig(undefined)}
              icon="arrow-left"
            />
          </View>
          <WidgetbotDiscord {...widgetbotDiscordConfig} />
        </KeyboardAvoidingView>
      )}
      {webviewDiscordConfig && (
        <KeyboardAvoidingView
          className="flex flex-col flex-grow"
          behavior="padding"
        >
          <View className="bg-[#313338] py-1 px-1 pt-[40px]">
            <IconButton
              iconColor="black"
              className="h-[35px] w-[35px] bg-white"
              onPress={() => setWebviewDiscordConfig(undefined)}
              icon="arrow-left-top"
            />
          </View>
          <WebviewDiscord {...webviewDiscordConfig} />
        </KeyboardAvoidingView>
      )}
      {!userProfile?.completedTutorial && (
        <View className="absolute h-full w-full bg-[#00000070]">
          <TutorialModalSocial refetch={userProfileRefetch} />
        </View>
      )}
      <AudioPlayer />
    </View>
  );
}
export default Social;
