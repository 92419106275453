import { Image } from "expo-image";
import { View, Text, TouchableHighlight, TouchableOpacity } from "react-native";
import { IconButton } from "react-native-paper";

import { usePlayerPlay } from "../../hooks/audio-player/usePlayerPlay";
import { useProgress } from "../../hooks/audio-player/useProgress";
import TrackPlayer, {
  RepeatMode,
} from "../../lib/TrackPlayerWrapper/TrackPlayerWrapper";
import { useMyPlayer } from "../../providers/FullscreenPlayerProvider";

function MinimizedAudioPlayer() {
  const { percentage: progressPercentage, position } = useProgress();
  const { play } = usePlayerPlay();

  const {
    playlist,
    currentTrackIndex,
    currentTrack,
    isPlaying,
    isPaused,
    isLoading,
    repeatMode,
    toggleRepeatMode,
  } = useMyPlayer();

  if (!currentTrack) {
    return null;
  }

  const { title, artwork } = currentTrack;

  return (
    <View className="bg-[#000000] h-[65px] w-full border-y border-y-1 border-[#FFFFFF50] flex flex-col md:hidden">
      <View className="flex flex-row pr-3 items-center">
        {artwork && (
          <View className="flex">
            <Image
              className="w-[55px] h-[55px] my-[1.5px]"
              source={{
                uri: String(artwork),
              }}
            />
          </View>
        )}
        <View className="flex flex-col space-y-1 justify-center flex-auto mx-3">
          <Text numberOfLines={1} className="text-white font-unbounded text-md">
            {title}
          </Text>

          <Text className="text-[#FFFFFF60] font-unbounded text-sm">
            Episode {currentTrackIndex + 1} of {playlist.length}
          </Text>
        </View>
        <View className="ml-auto flex flex-row items-center space-x-2">
          <TouchableOpacity onPress={toggleRepeatMode} className="items-center">
            {(repeatMode === RepeatMode.Off ||
              repeatMode === RepeatMode.Queue) && (
              <Image
                className="h-[21px] w-[21px]"
                cachePolicy="memory"
                contentFit="contain"
                source={require("../../../assets/player/repeat.png")}
              />
            )}
            {repeatMode === RepeatMode.Track && (
              <Image
                className="h-[21px] w-[21px]"
                cachePolicy="memory"
                contentFit="contain"
                source={require("../../../assets/player/repeat-active.png")}
              />
            )}
          </TouchableOpacity>
          <IconButton className="h-[55px] w-[18px] mx-1" icon="chevron-up" />
          <View
            onStartShouldSetResponder={() => true}
            onTouchEnd={(e) => {
              e.stopPropagation();
            }}
          >
            {isPaused && (
              <TouchableHighlight onPress={() => play({ playlist, position })}>
                <Image
                  className="h-[30px] w-[30px]"
                  cachePolicy="memory"
                  source={require("../../../assets/player/play-button-big.png")}
                />
              </TouchableHighlight>
            )}
            {isLoading && (
              <Image
                className="h-[30px] w-[30px]"
                cachePolicy="memory"
                source={require("../../../assets/icons/loading-spinner.gif")}
              />
            )}
            {isPlaying && (
              <TouchableHighlight onPress={() => TrackPlayer.pause()}>
                <Image
                  className="h-[30px] w-[30px]"
                  source={require("../../../assets/player/pause-button-big.png")}
                />
              </TouchableHighlight>
            )}
          </View>
        </View>
      </View>
      <View
        style={{
          width: `${progressPercentage}%`,
          height: `6%`,
        }}
        className="absolute bottom-0 bg-[#80EFAD] self-end left-0"
      />
    </View>
  );
}
export default MinimizedAudioPlayer;
