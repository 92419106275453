import { View, ScrollView } from "react-native";
import { Text } from "react-native-paper";

import ProgramThumbnail from "./ProgramThumbnail";

type ProgramsThumbnailContainerItem = {
  uuid: string;
  title: string;
  imageUrl: string;
  isAccessible: boolean;
  episodesCount: number;
  programProductId: string | undefined;
  isNew: boolean;
  isFree: boolean;
  priceInCents: number | null;
};

type ProgramsThumbnailContainerProps = {
  programs: ProgramsThumbnailContainerItem[];
  title: string;
};

function ProgramsThumbnailContainer({
  programs,
  title,
}: ProgramsThumbnailContainerProps) {
  return (
    <View className="flex flex-col justify-start pb-[15px]">
      <View className="flex flex-row w-full justify-between">
        <Text className="text-xl font-unbounded text-white mb-3">{title}</Text>
      </View>
      <ScrollView className="flex flex-row" horizontal>
        {programs.map((program) => (
          <View key={program.uuid} className="m-1">
            <ProgramThumbnail program={program} />
          </View>
        ))}
      </ScrollView>
    </View>
  );
}
export default ProgramsThumbnailContainer;
