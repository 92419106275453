import { Image } from "expo-image";
import { useEffect } from "react";

import { useMySubscribedToCreatorsQuery } from "../../graphql/generated";
import { getImagePreviewUrlThumbnail } from "../../utils/image-preview-url";
import { notEmpty } from "../../utils/not-empty";

export function useSubscribedCreators() {
  const { data, refetch, loading } = useMySubscribedToCreatorsQuery({
    fetchPolicy: "cache-and-network",
  });

  const subscribedCreatorsData =
    data?.mySubscribedToCreators?.filter(notEmpty) || [];

  const subscribedCreators = subscribedCreatorsData.map((row) => ({
    uuid: row.uuid,
    name: row.displayName,
    avatarUrl: row.avatar ? getImagePreviewUrlThumbnail(row.avatar) : "",
    discordChannel: row.discordChannel ?? undefined,
    discordServer: row.discordServer ?? undefined,
    isFreeSubscription: row.isFreeSubscription ?? false,
    isSubscribed: true,
  }));

  useEffect(() => {
    Image.prefetch(subscribedCreators.map((creator) => creator.avatarUrl));
  }, [subscribedCreators]);

  return {
    subscribedCreators,
    refetch,
    loading,
  };
}
