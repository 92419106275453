// eslint-disable-next-line no-restricted-imports
import {
  APP_DEEPLINK,
  APP_STORELINK,
  DISCORD_CLIENT_ID,
  EXPO_CLIENT_ID,
  STRAPI_URL,
  WEB_CLIENT_ID,
  REVENUECAT_APIKEY_IOS,
  REVENUECAT_DISABLED,
  REVENUECAT_DEBUG,
  STORE_DEBUG,
  AUDIO_PLAYER_DEBUG,
  SENTRY_DSN,
  SENTRY_ENV,
  ENVIRONMENT,
} from "@env";

// console.log("STRAPI_URL", process.env.STRAPI_URL);

// TODO consider using process env for all env variables
export const envConfig = {
  backendUrl: process.env.STRAPI_URL ?? STRAPI_URL,
  expoClientId: EXPO_CLIENT_ID,
  webClientId: WEB_CLIENT_ID,
  appDeepLink: APP_DEEPLINK,
  appStoreLink: APP_STORELINK,
  discordClientId: process.env.DISCORD_CLIENT_ID ?? DISCORD_CLIENT_ID,

  // RC
  revenuecatApiKeyIos: REVENUECAT_APIKEY_IOS,
  revenuecatDisabled: REVENUECAT_DISABLED,
  revenuecatDebug: REVENUECAT_DEBUG,
  storeDebug: STORE_DEBUG,

  audioPlayerDebug: AUDIO_PLAYER_DEBUG,

  // Sentry
  sentryDsn: SENTRY_DSN,
  sentryEnv: SENTRY_ENV,
  environment: ENVIRONMENT,
} as const;
