import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import { LinearGradient } from "expo-linear-gradient";
import { useEffect } from "react";
import { View, Text, ImageBackground, Platform } from "react-native";
import { Button } from "react-native-paper";

import { useValidateStripePurchaseMutation } from "../graphql/generated";
import {
  ScreenNavigationProps,
  SuccessfulTransactionScreenProps,
} from "../navigation/RootStackParamList";

function SuccessfulTransaction({
  route: {
    params: { creatorUUID },
  },
}: SuccessfulTransactionScreenProps) {
  const navigation = useNavigation<ScreenNavigationProps>();

  const [validateStripePurchase] = useValidateStripePurchaseMutation();

  useEffect(() => {
    if (Platform.OS !== "web") {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const session_id = searchParams.get("session_id");

    if (!session_id) {
      return;
    }

    void validateStripePurchase({
      variables: {
        checkoutSessionId: session_id,
      },
    });
  }, []);

  return (
    <ImageBackground
      className="absolute z-100 h-[100vh] w-full flex flex-col justify-start space-y-[15px] -mt-[70px]"
      source={require("../../assets/beach-background.png")}
    >
      <LinearGradient
        // Background Linear Gradient
        colors={["transparent", "#1c2032"]}
        className="w-full h-full self-start"
      >
        <View className="flex flex-col justify-center space-y-[10px] h-[100vh]">
          <Image
            className="h-[120px] w-[120px] self-center"
            source={require("../../assets/icons/transaction-success.png")}
          />
          <Text className="font-unbounded text-white text-lg  w-[80%] self-center text-center pr-[10px]">
            The transaction has been completed successfully
          </Text>
          <Text className="font-inter text-white text-sm w-[80%] mb-[15px] self-center text-center">
            We will email you the purchase receipt
          </Text>
          <Button
            onPress={() =>
              navigation.navigate("Creator", {
                creatorUUID,
                backToHome: true,
              })
            }
            className="border border-1 bg-[#80EFAD] rounded-2xl w-[90%] ml-[5%]"
          >
            <Text className="font-unbounded text-black">Go to Creator</Text>
          </Button>
        </View>
      </LinearGradient>
    </ImageBackground>
  );
}

export default SuccessfulTransaction;
