import { NavigationContainer } from "@react-navigation/native";
import Constants from "expo-constants";
import * as Linking from "expo-linking";

import RootNavigator from "./RootNavigator";
import { DeeplinkProvider } from "../contexts/DeeplinkContext";
import { NotificationProvider } from "../contexts/NotificationContext";
import { PromotionProvider } from "../contexts/PromotionContext";

function getLinkingPrefixes() {
  const appScheme = Constants.expoConfig?.scheme;

  const prefix = Linking.createURL("/");

  if (appScheme) {
    const appSchemeArray = Array.isArray(appScheme) ? appScheme : [appScheme];
    return [...appSchemeArray, prefix];
  }
  return [prefix];
}

export function DefaultNavigationContainer() {
  const linking = {
    prefixes: getLinkingPrefixes(),
    config: {
      screens: {
        SignIn: "sign-in",
        Signup: "sign-up",
        RequestReset: "request-reset",
        ResetPassword: "reset-password",
        Prelogin: "prelogin",
        // After log in
        Home: "home",
        Account: "account",
        Discover: "discover",
        Social: "discord", // to support discord linking
        ProgramPreview: "programs/:programUUID",
        FailedTransaction: "failed-transaction",
        SuccessfulTransaction: "successful-transaction",
        Creator: "creators/:creatorUUID",
        FirstTime: "first-time",
      },
    },
  };

  return (
    <NavigationContainer linking={linking}>
      <NotificationProvider>
        <DeeplinkProvider>
          <PromotionProvider>
            <RootNavigator />
          </PromotionProvider>
        </DeeplinkProvider>
      </NotificationProvider>
    </NavigationContainer>
  );
}
