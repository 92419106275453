export function isNew(programDateString: string | null | undefined) {
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  if (programDateString) {
    const programDate = new Date(programDateString);
    return programDate > oneWeekAgo;
  } else {
    return false;
  }
}
