import { Image } from "expo-image";
import { View } from "react-native";

export enum ImageSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
  XLarge = "xlarge",
}

type AvatarProps = {
  thumbnail?: string;
  imageSize?: ImageSize;
  isSubscribed?: boolean;
};

function Avatar({ thumbnail, imageSize, isSubscribed }: AvatarProps) {
  const imageSource = thumbnail
    ? { uri: thumbnail }
    : require("../../assets/avatar-placeholder.png");
  return (
    <View className="flex flex-row">
      <Image
        className={`self-center ${
          imageSize === "small" && `w-[64px] h-[64px]`
        } ${imageSize === "medium" && `w-[85px] h-[85px]`} ${
          imageSize === "large" && `w-[130px] h-[130px]`
        } ${
          imageSize === "xlarge" && `w-[164px] h-[164px]`
        } rounded-full bg-black`}
        source={imageSource}
        cachePolicy="disk"
      />
      {isSubscribed && (
        <Image
          style={{ width: 25, height: 25 }}
          className={`rounded-full ${imageSize === "medium" && `-ml-6`} ${
            imageSize === "small" && `-ml-3`
          } self-end`}
          source={require("../../assets/icons/checkmark.png")}
          cachePolicy="memory-disk"
        />
      )}
    </View>
  );
}
export default Avatar;
