import { Image } from "expo-image";
import { useEffect } from "react";

import { useSearchProgramsByProgramNameQuery } from "../../graphql/generated";
import { getImagePreviewUrlSmall } from "../../utils/image-preview-url";
import { isNew } from "../../utils/is-new";
import { notEmpty } from "../../utils/not-empty";

export function useDiscoverPrograms(name?: string) {
  const { data, loading, refetch } = useSearchProgramsByProgramNameQuery({
    variables: {
      programName: name,
    },
    fetchPolicy: "cache-and-network",
  });

  const programsData =
    data?.searchProgramsByProgramName?.data?.filter(notEmpty) || [];

  const programs = programsData.map((row) => ({
    uuid: row.uuid,
    title: row.title,
    imageUrl: row.image ? getImagePreviewUrlSmall(row.image) : "",
    episodesCount: row.episodeCount,
    isAccessible: row.isPurchased || row.isSubscribed,
    programProductId: row.revenueCatProductId ?? undefined,
    isNew: isNew(row.publishedAt),
    priceInCents:
      typeof row.priceInCents === "string" && row.priceInCents.length > 0
        ? parseInt(row.priceInCents, 10)
        : null,
    isFree: row.isFree || false,
  }));

  useEffect(() => {
    Image.prefetch(programs.map((program) => program.imageUrl));
  }, [programs]);

  return {
    programs,
    loading,
    refetch,
  };
}
