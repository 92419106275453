import { IconButton } from "react-native-paper";

import { useMyPlayer } from "../providers/FullscreenPlayerProvider";

export type PlayButtonProps = {
  episodeUUID: string;
};

function PlayButton({ episodeUUID }: PlayButtonProps) {
  const { currentTrack, isPlaying } = useMyPlayer();
  return (
    <IconButton
      className="bg-[#80EFAD] h-[32px] w-[32px]"
      iconColor="black"
      icon={
        isPlaying && currentTrack?.episodeUUID === episodeUUID
          ? "pause"
          : "play"
      }
    />
  );
}
export default PlayButton;
