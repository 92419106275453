import { useGetMyUserProfileInfoQuery } from "../graphql/generated";
import { getImagePreviewUrlThumbnail } from "../utils/image-preview-url";

export function useUserProfile() {
  const { data, loading, refetch, error } = useGetMyUserProfileInfoQuery();

  const attributes = data?.myUserProfile;

  const userProfile = attributes
    ? {
        id: attributes.id.toString(),
        uuid: attributes.uuid,
        bio: attributes.bio ?? undefined,
        isFirstTimeUse: attributes.isFirstTimeUse,
        completedTutorial: attributes.completedTutorial,
        location: attributes.location ?? undefined,
        username: attributes.username ?? undefined,
        image: attributes.image ?? undefined,
        imageUrl: attributes.image?.data?.attributes
          ? getImagePreviewUrlThumbnail(attributes.image?.data?.attributes)
          : undefined,
        createdAt: new Date(attributes.createdAt || 0),
      }
    : undefined;

  return {
    loading,
    error,
    refetch,
    userProfile,
  };
}
