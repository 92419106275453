import { ApolloProvider } from "@apollo/client";
import { FC, PropsWithChildren } from "react";

import { useAppApolloClient } from "../hooks/useAppApolloClient";

// eslint-disable-next-line @typescript-eslint/ban-types
export const AppApolloProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const client = useAppApolloClient();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
