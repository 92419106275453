import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import { useEffect, useState } from "react";
import { ScrollView, View, Text, RefreshControl } from "react-native";
import { Button } from "react-native-paper";

import Avatar, { ImageSize } from "../components/Avatar";
import LoadingScreen from "../components/Loading";
import LoadingSpinner from "../components/LoadingSpinner";
import ManageSubscriptions from "../components/ManageSubscriptions";
import { AudioPlayer } from "../components/audio-player/AudioPlayer";
import Account from "../components/settings-page/Account";
import { ConfirmUnsubscribeModal } from "../components/settings-page/ConfirmUnsubscribeModal";
import { TutorialModalAccount } from "../components/tutorial/TutorialModalAccount";
import { useSubscribedCreators } from "../hooks/creators/useSubscribedCreators";
import { useGetFreeProgramsAndEpisodes } from "../hooks/useGetFreeProgramsAndEpisodes";
import { useGetMyProgramsAndEpisodes } from "../hooks/useGetMyProgramsAndEpisodes";
import { useLogin } from "../hooks/useLogin";
import { useUserProfile } from "../hooks/useUserProfile";
import { ScreenNavigationProps } from "../navigation/RootStackParamList";

function Settings() {
  const [navFocus, setNavFocus] = useState("Account");
  const [showConfirmUnsubscribeModal, setShowConfirmUnsubscribeModal] =
    useState<string | undefined>();
  const [userLocation, setUserLocation] = useState<string>();
  const [userBio, setUserBio] = useState<string>();
  const [userImage, setUserImage] = useState<string>();
  const [userUsername, setUserUsername] = useState<string>();
  const navigation = useNavigation<ScreenNavigationProps>();

  const { logout } = useLogin();

  const [isRefreshing, setIsRefreshing] = useState(false);

  const {
    subscribedCreators,
    refetch: creatorSubscriptionRefetch,
    loading: subscribedCreatorsLoading,
  } = useSubscribedCreators();

  const { programs, refetch, loading } = useGetMyProgramsAndEpisodes();
  const {
    programs: freePrograms,
    refetch: freeRefetch,
    loading: freeLoading,
  } = useGetFreeProgramsAndEpisodes();

  async function handleRefresh() {
    setIsRefreshing(true);
    try {
      await Promise.all([
        refetch(),
        freeRefetch(),
        creatorSubscriptionRefetch(),
        userProfileRefetch(),
      ]);
    } catch (e) {
      console.log("Error refreshing settings data", e);
    }
    setIsRefreshing(false);
  }

  const refreshControl = (
    <RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} />
  );

  const { userProfile, refetch: userProfileRefetch } = useUserProfile();

  useEffect(() => {
    if (userProfile) {
      setUserLocation(userProfile.location);
      setUserBio(userProfile.bio);
      setUserUsername(userProfile.username);
      if (userProfile.image && userProfile.image.data?.attributes) {
        setUserImage(userProfile.image.data.attributes.url);
      }
    }
  }, [userProfile]);

  async function handleShowModal() {
    navigation.navigate("EditProfile", {
      userLocation: userLocation || "",
      userBio: userBio || "",
      userUsername: userUsername || "",
    });
  }

  const memberFrom = userProfile?.createdAt
    ? new Date(userProfile.createdAt).getFullYear()
    : "--";

  const isLoading = loading || freeLoading;
  return (
    <View className="h-full bg-main">
      {!isLoading ? (
        <>
          <ScrollView
            nestedScrollEnabled
            scrollEventThrottle={16}
            refreshControl={refreshControl}
            className="mb-[10px]"
          >
            <View className="pt-[60px] md:px-14">
              <View className="flex flex-row h-[50px] w-[90%] justify-left self-center">
                <View className="flex flex-col justify-center h-full w-full">
                  {isRefreshing && (
                    <View className="mb-[20px] -mt-[20px]">
                      <LoadingSpinner />
                    </View>
                  )}
                  <View className="flex flex-row w-full justify-end sm:rounded-sm">
                    <Button onPress={handleShowModal} className="py-[1px]">
                      <View className="flex flex-row space-x-[3px]">
                        <Image
                          className="h-[20px] w-[20px]"
                          source={{
                            uri: `https://peak.audio/wp-content/uploads/2023/04/Edit.png`,
                          }}
                        />
                        <Text className="font-unbounded text-[#80EFAD] text-xs self-center">
                          Account Settings
                        </Text>
                      </View>
                    </Button>
                    <Button className="rounded-md" onPress={() => logout()}>
                      <Text className="font-unbounded text-xs text-[#80EFAD]">
                        Logout
                      </Text>
                    </Button>
                  </View>
                </View>
              </View>
              <View className="flex flex-row h-[150px] w-[53%] justify-left self-start ml-[3%] -mt-[6%] space-x-5">
                <Avatar
                  imageSize={ImageSize.Large}
                  thumbnail={userImage ? userImage : undefined}
                />
                <View className="flex flex-col justify-end mb-[20px] space-y-1">
                  <Text
                    numberOfLines={1}
                    className="text-sm font-unbounded text-white self-start"
                  >{`@${userUsername}`}</Text>
                  <Text
                    numberOfLines={1}
                    className="text-sm font-inter text-white self-start"
                  >
                    {userLocation ? userLocation : "--"}
                  </Text>
                  <Text className="text-sm font-inter text-[#FFFFFF55] self-start">
                    Member from {memberFrom}
                  </Text>
                </View>
              </View>
              <View className="flex flex-row space-x-3 ml-3 mb-[15px]">
                <Text
                  onPress={() => setNavFocus("Account")}
                  className={`text-lg font-unbounded text-white mt-5 ${
                    navFocus === "Account" ? "underline text-[#80EFAD]" : ""
                  }`}
                >
                  Account
                </Text>
                <Text
                  onPress={() => setNavFocus("Bio")}
                  className={`text-lg font-unbounded text-white ml-3 mt-5 ${
                    navFocus === "Bio" ? "underline text-[#80EFAD]" : ""
                  }`}
                >
                  Bio
                </Text>
                <Text
                  onPress={() => setNavFocus("Subscriptions")}
                  className={`text-lg font-unbounded text-white ml-3 mt-5 ${
                    navFocus === "Subscriptions"
                      ? "underline text-[#80EFAD]"
                      : ""
                  }`}
                >
                  Subscriptions
                </Text>
              </View>
              {navFocus === "Account" && (
                <View>
                  <View className="flex flex-row space-x-5 justify-start ml-3">
                    <View className="flex flex-col">
                      <Text className="text-sm font-inter text-[#C4C6D4] mt-2 self-start">
                        Programs
                      </Text>
                      <Text className="text-lg font-unbounded text-[#FFFFFF] self-start ml-1">
                        {programs ? programs.length : 0}
                      </Text>
                    </View>
                    <View className="flex flex-col">
                      <Text className="text-sm font-inter text-[#C4C6D4] ml-3 mt-2 self-start">
                        Subscriptions
                      </Text>
                      <Text className="text-lg font-unbounded text-[#FFFFFF] ml-4 self-start">
                        {subscribedCreators ? subscribedCreators.length : 0}
                      </Text>
                    </View>
                    <View className="flex flex-col">
                      <Text className="text-sm font-inter text-[#C4C6D4] ml-3 mt-2 self-start">
                        Hours Listened
                      </Text>
                      <Text className="text-lg font-unbounded text-[#FFFFFF] ml-4 self-start">
                        ---
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              {navFocus === "Bio" && !userBio && (
                <View className="flex flex-col w-full justify-start mt-[20px] space-y-[15px]">
                  <Text className="text-[#FFFFFF65] text-center">
                    You haven't written your bio yet.
                  </Text>
                  <Button
                    onPress={handleShowModal}
                    className="border border-1 bg-[#80EFAD] rounded-2xl w-[75%] self-center"
                  >
                    <Text className="font-unbounded text-black">
                      Account Settings
                    </Text>
                  </Button>
                </View>
              )}
              {navFocus === "Bio" && userBio && (
                <View className="h-full w-full ml-3 self-center mt-[10px]">
                  <Text className="text-sm font-inter text-white ml-2">{`${userBio}`}</Text>
                </View>
              )}
              {navFocus === "Account" && (
                <Account programs={programs} freePrograms={freePrograms} />
              )}
              {navFocus === "Subscriptions" && (
                <ManageSubscriptions
                  setShowConfirmUnsubscribeModal={
                    setShowConfirmUnsubscribeModal
                  }
                  creators={subscribedCreators}
                  creatorsLoading={subscribedCreatorsLoading}
                />
              )}
            </View>
          </ScrollView>
          {showConfirmUnsubscribeModal && (
            <ConfirmUnsubscribeModal
              creatorUUID={showConfirmUnsubscribeModal}
              setShowConfirmUnsubscribeModal={setShowConfirmUnsubscribeModal}
            />
          )}
        </>
      ) : (
        <LoadingScreen />
      )}
      {!userProfile?.completedTutorial && (
        <View className="absolute h-full w-full bg-[#00000070]">
          <TutorialModalAccount refetch={userProfileRefetch} />
        </View>
      )}
      <AudioPlayer />
    </View>
  );
}

export default Settings;
