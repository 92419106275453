import * as Device from "expo-device";
import {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import { Platform } from "react-native";
import { useSelector } from "react-redux";

import PushNotificationScreen from "../components/PushNotificationScreen";
import { useRegisterPushNotification } from "../hooks/useRegisterPushNotification";
import { RootState } from "../redux/Store";
import { ErrorReportingService } from "../services/ErrorReportingService";

type PushNotificationContextType = object;

export const PushNotificationContext =
  createContext<PushNotificationContextType | null>(null);

export const PushNotificationProvider: FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const jwt = useSelector<RootState, string | null>((state) => state.jwt);
  const { register: registerPushNotification, getStatus } =
    useRegisterPushNotification();
  const [showScreen, setShowScreen] = useState(false);

  useEffect(() => {
    if (!jwt) return;

    async function checkPushNotificationStatus() {
      const status = await getStatus();

      if (
        status === "undetermined" &&
        ["android", "ios"].includes(Platform.OS) &&
        Device.isDevice
      ) {
        setShowScreen(true);
      }
    }

    void checkPushNotificationStatus();
  }, [jwt]);

  async function onSubmit() {
    try {
      await registerPushNotification();
    } catch (e) {
      ErrorReportingService.message("Error registering push notification");
      ErrorReportingService.report(e);
    } finally {
      setShowScreen(false);
    }
  }

  return (
    <PushNotificationContext.Provider value={{}}>
      {showScreen && (
        <PushNotificationScreen
          onSubmit={onSubmit}
          onCancel={() => setShowScreen(false)}
        />
      )}
      {children}
    </PushNotificationContext.Provider>
  );
};
