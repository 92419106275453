import Slider from "@react-native-community/slider";
import { Image } from "expo-image";
import { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";

import TrackPlayer from "../../lib/TrackPlayerWrapper/TrackPlayerWrapper";

export function VolumeBar() {
  const [volume, setVolume] = useState(1);

  useEffect(() => {
    async function initVolumeValue() {
      const curVolume = await TrackPlayer.getVolume();
      setVolume(curVolume);
    }

    void initVolumeValue();
  }, []);

  async function onSlide(value: number) {
    setVolume(value);
    await TrackPlayer.setVolume(value);
  }

  function volumeToggle() {
    if (volume === 0) {
      void onSlide(1);
    } else {
      void onSlide(0);
    }
  }

  return (
    <View className="h-full flex flex-row items-center justify-end">
      <TouchableOpacity onPress={volumeToggle} activeOpacity={volume}>
        <Image
          className={`h-[23px] w-[21px] ${volume === 0 ? "opacity-50" : ""}`}
          source={require("../../../assets/player/speaker.png")}
        />
      </TouchableOpacity>
      <Slider
        minimumTrackTintColor="#FFFFFF"
        maximumTrackTintColor="#FFFFFF65"
        value={volume}
        onValueChange={onSlide}
        thumbImage={require("../../../assets/player/progress-bar-thumb.png")}
        style={{
          paddingHorizontal: 4,
        }}
      />
    </View>
  );
}
