import { updateTrackProgress } from "./ProgressionService";
import { envConfig } from "../../env.config";
import TrackPlayer, {
  Event,
} from "../lib/TrackPlayerWrapper/TrackPlayerWrapper";

const { audioPlayerDebug } = envConfig;

export async function PlaybackService() {
  audioPlayerDebug && console.log("PlaybackService: registered");

  TrackPlayer.addEventListener(Event.RemotePause, () => {
    audioPlayerDebug && console.log("Event.RemotePause");
    void TrackPlayer.pause();
  });

  TrackPlayer.addEventListener(Event.RemotePlay, () => {
    audioPlayerDebug && console.log("Event.RemotePlay");
    void TrackPlayer.play();
  });

  TrackPlayer.addEventListener(Event.RemoteNext, () => {
    audioPlayerDebug && console.log("Event.RemoteNext");
    void TrackPlayer.skipToNext();
  });

  TrackPlayer.addEventListener(Event.RemotePrevious, () => {
    audioPlayerDebug && console.log("Event.RemotePrevious");
    void TrackPlayer.skipToPrevious();
  });

  TrackPlayer.addEventListener(Event.RemoteJumpForward, async (event) => {
    audioPlayerDebug && console.log("Event.RemoteJumpForward", event);
    const position = await TrackPlayer.getPosition();
    void TrackPlayer.seekTo(event.interval + position);
  });

  TrackPlayer.addEventListener(Event.RemoteJumpBackward, async (event) => {
    audioPlayerDebug && console.log("Event.RemoteJumpBackward", event);
    const position = await TrackPlayer.getPosition();
    void TrackPlayer.seekTo(position - event.interval);
  });

  TrackPlayer.addEventListener(Event.RemoteSeek, (event) => {
    audioPlayerDebug && console.log("Event.RemoteSeek", event);
    void TrackPlayer.seekTo(event.position);
  });

  TrackPlayer.addEventListener(Event.RemoteDuck, (event) => {
    audioPlayerDebug && console.log("Event.RemoteDuck", event);
  });

  TrackPlayer.addEventListener(Event.PlaybackQueueEnded, (event) => {
    audioPlayerDebug && console.log("Event.PlaybackQueueEnded", event);
  });

  TrackPlayer.addEventListener(Event.PlaybackTrackChanged, (event) => {
    audioPlayerDebug && console.log("Event.PlaybackActiveTrackChanged", event);
  });

  TrackPlayer.addEventListener(Event.PlaybackState, (event) => {
    audioPlayerDebug && console.log("Event.PlaybackState", event);
  });

  TrackPlayer.addEventListener(Event.PlaybackProgressUpdated, (event) => {
    audioPlayerDebug && console.log("Event.PlaybackProgressUpdated", event);
    void updateTrackProgress(event);
  });

  TrackPlayer.addEventListener(
    Event.PlaybackMetadataReceived,
    async ({ title, artist }) => {
      audioPlayerDebug &&
        console.log("Event.PlaybackMetadataReceived", title, artist);
      const currentTrackIndex = await TrackPlayer.getCurrentTrack();
      const activeTrack = await TrackPlayer.getTrack(currentTrackIndex || 0);
      void TrackPlayer.updateNowPlayingMetadata({
        artist: [title, artist].filter(Boolean).join(" - "),
        title: activeTrack?.title,
        artwork: activeTrack?.artwork,
      });
    }
  );
}
