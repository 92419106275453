import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import * as WebBrowser from "expo-web-browser";
import { useState } from "react";
import {
  View,
  StatusBar,
  Text,
  Platform,
  KeyboardAvoidingView,
  ImageBackground,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Button, Snackbar, Portal, IconButton } from "react-native-paper";

import LoginTextField from "../components/LoginTextField";
import { useForgotPassword } from "../hooks/password/useForgotPassword";
import { LoginScreenNavigationProps } from "../navigation/RootStackParamLoginList";

export const isAndroid = () => Platform.OS === "android";

WebBrowser.maybeCompleteAuthSession();

function RequestReset() {
  const [email, setEmail] = useState("");
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState<string>();
  const [messageVisible, setMessageVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const { forgotPassword } = useForgotPassword({ email });

  const validateInput = () => {
    const errors = !email || email.length === 0;
    return !errors;
  };

  const navigation = useNavigation<LoginScreenNavigationProps>();

  async function handleCompleteRequest() {
    setLoading(true);
    const response = await handlePasswordResetRequest();
    setLoading(false);
    if (response) {
      setMessage("Email sent! Check your inbox for the confirmation code.");
      setMessageVisible(true);
      navigation.navigate("ResetPassword");
      return true;
    } else {
      return false;
    }
  }

  async function handlePasswordResetRequest() {
    // send email with confirmation code
    if (validateInput()) {
      const forgotPasswordVar = await forgotPassword().catch((error) => {
        setError(error.message);
        setVisible(true);
      });
      return forgotPasswordVar;
    } else {
      setError("Please fill out all required fields.");
      setVisible(true);
      return undefined;
    }
  }
  return (
    <ImageBackground
      className="h-full w-full bg-black"
      source={require("../../assets/login-background.png")}
    >
      <ScrollView>
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          keyboardVerticalOffset={10}
          behavior="position"
        >
          <View className="flex flex-row w-full justify-start mt-[140px]">
            <IconButton
              iconColor="black"
              className="ml-[40px] bg-white"
              onPress={() => navigation.navigate("Prelogin")}
              icon="arrow-left"
            />
          </View>
          <View className="h-full flex flex-col justify-start mx-10 pt-7 md:max-w-sm md:ml-40 md:mt-40">
            <Image
              style={{ width: 147, height: 24 }}
              source={require("../../assets/peak-logo-white.png")}
            />
            <>
              <StatusBar backgroundColor="#FFFFFF" barStyle="dark-content" />
            </>
            <View className="flex flex-col mt-10 w-3/4 justify-start space-y-3">
              <Text className="-ml-1 font-unbounded text-white text-xl">
                Reset Your Password
              </Text>
              <Text className="font-inter text-white text-sm">
                Enter your email to request a password reset code.
              </Text>
            </View>
            <View className="h-[16px]" />
            <View className="flex flex-col space-y-3">
              <LoginTextField textHook={setEmail} placeholder="*Email" />
            </View>
            <>
              <View className="h-[16px]" />
              <Button
                className="rounded-none bg-[#80EFAD] border-solid border-black"
                textColor="#000000"
                loading={loading}
                disabled={loading}
                onPress={() => handleCompleteRequest()}
              >
                <Text className="font-unbounded self-center">
                  Request Password Reset
                </Text>
              </Button>
              <View className="h-[16px]" />
              <Button
                onPress={() => navigation.navigate("ResetPassword")}
                className=""
              >
                <Text className="font-inter text-white text-sm underline-offset-1 underline text-left">
                  Already have a reset code?
                </Text>
              </Button>
              <View className="h-[16px]" />
            </>
            <>
              {/**
               * We use a portal component to render
               * the snackbar on top of everything else
               * */}
              <Portal>
                <Snackbar visible={visible} onDismiss={() => setVisible(false)}>
                  {error}
                </Snackbar>
              </Portal>
              <Portal>
                <Snackbar
                  visible={messageVisible}
                  onDismiss={() => setMessageVisible(false)}
                >
                  {message}
                </Snackbar>
              </Portal>
            </>
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    </ImageBackground>
  );
}

export default RequestReset;
