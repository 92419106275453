import { Dispatch, SetStateAction } from "react";
import { Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import Pdf from "react-native-pdf";

type PdfPreviewProps = {
  src: string;
  description: string;
  type: string;
  title: string;
  setShowPdfViewerModal: Dispatch<SetStateAction<undefined | string>>;
};

function PdfPreview({
  title,
  description,
  type,
  src,
  setShowPdfViewerModal,
}: PdfPreviewProps) {
  async function handleClick() {
    if (src) {
      setShowPdfViewerModal(src);
    } else {
      console.error("no pdf link provided");
    }
  }

  const source = { uri: src, cache: true };
  return (
    <View className="h-[200px] w-full mr-[5px] bg-[#00000024] p-[10px] mb-[10px]">
      <TouchableOpacity onPress={() => handleClick()}>
        <View className="w-full flex flex-col space-y-3">
          <View className="flex flex-row justify-between">
            <View className="flex flex-col space-y-3 w-[55%]">
              <View className="flex flex-row">
                <View className="border border-[#676C84] rounded-md px-[3px] py-[1px]">
                  <Text className="text-[#676C84]">{type}</Text>
                </View>
              </View>
              <Text
                numberOfLines={3}
                className="text-[#FFFFFF] font-unbounded min-h-[50px]"
              >
                {title}
              </Text>
              <Text numberOfLines={4} className="text-[#C4C6D4]">
                {description}
              </Text>
            </View>
            <View
              pointerEvents="none"
              className="w-[100px] mb-[10px] h-[130px] overflow-hidden self-center mt-[20px]"
            >
              <Pdf
                source={source}
                style={{
                  height: 140,
                  width: 100,
                  flex: 1,
                  overflow: "hidden",
                }}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}
export default PdfPreview;
