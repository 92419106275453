import { AVPlaybackStatus } from "expo-av";
import { useEffect } from "react";
import type {
  EventsPayloadByEvent,
  useTrackPlayerEvents as libUseTrackPlayerEvents,
} from "react-native-track-player";

import { Event } from "./Event";
import { State } from "./State";
import { WebTrackPlayer } from "./WebTrackPlayer";

function avMetadataToState(metadata: AVPlaybackStatus): State {
  if (!metadata.isLoaded) {
    return State.Connecting;
  }

  if (metadata.isBuffering) {
    return State.Buffering;
  }

  if (metadata.isPlaying) {
    return State.Playing;
  }

  if (metadata.didJustFinish) {
    return State.Stopped;
  }

  if (!metadata.isPlaying) {
    return State.Paused;
  }

  if (metadata.isLoaded) {
    return State.Ready;
  }

  return State.None;
}

export function getTrackPlayerEventsHook(
  webTrackPlayer: WebTrackPlayer
): typeof libUseTrackPlayerEvents {
  const useTrackPlayerEvents: typeof libUseTrackPlayerEvents = <
    T extends Event[],
    H extends (data: EventsPayloadByEvent[T[number]]) => void
  >(
    events: T,
    handler: H
  ) => {
    const event = events[0];
    if (event !== Event.PlaybackState || events.length > 1) {
      throw new Error(
        `The only supported event for web is ${Event.PlaybackState}`
      );
    }

    useEffect(() => {
      function listener(metadata: AVPlaybackStatus) {
        const state = avMetadataToState(metadata);
        handler({ state } as never as EventsPayloadByEvent[T[number]]);
      }

      webTrackPlayer.registerMetadataUpdateListener(listener);
      return () => {
        webTrackPlayer.deregisterMetadataUpdateListener(listener);
      };
    }, []);
  };

  return useTrackPlayerEvents;
}
