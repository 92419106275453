import { store } from "../Store";

export function savePromotionToken(promotionToken: string) {
  store.dispatch({
    type: "PROMOTION_TOKEN_SAVE",
    payload: {
      promotionToken,
    },
  });
}

export function deletePromotionToken() {
  store.dispatch({ type: "PROMOTION_TOKEN_DELETE" });
}
