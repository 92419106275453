import { Dispatch, SetStateAction, useState } from "react";
import { KeyboardAvoidingView, View } from "react-native";
import { Text, Button } from "react-native-paper";

import { useDeactivateAccount } from "../../hooks/useDeactivateAccount";
import { useLogin } from "../../hooks/useLogin";
import { useMyPlayer } from "../../providers/FullscreenPlayerProvider";
import LoadingScreen from "../Loading";

type ConfirmDeactivateModalProps = {
  setShowConfirmDeleteModal: Dispatch<SetStateAction<undefined | boolean>>;
};

export function ConfirmDeactivateModal({
  setShowConfirmDeleteModal,
}: ConfirmDeactivateModalProps) {
  const { currentTrack } = useMyPlayer();

  const { logout } = useLogin();
  const { deactivateAccount } = useDeactivateAccount();
  const [isLoading, setIsLoading] = useState(false);

  async function handleDeactivateAccount() {
    setIsLoading(true);
    await deactivateAccount();
    logout();
    setIsLoading(false);
  }

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior="padding"
      className="absolute z-20 h-screen w-screen flex flex-col justify-end"
    >
      <View className="h-full bg-[#00000095]" />
      {!isLoading && (
        <View
          className={`h-fit pt-[50px] ${
            currentTrack ? "pb-[160px]" : "pb-[100px]"
          } w-screen bg-[#151827] flex flex-col justify-end px-[20px] space-y-[30px]`}
        >
          <Text className="text-white font-unbounded text-lg">
            Are you sure you would like to delete your account?
          </Text>
          <Text className="text-white text-sm">
            Please be sure to deactivate any and all subscriptions related to
            the app to avoid unnecessary payments.
          </Text>
          <View className="flex flex-row w-full mt-2 justify-center content-center space-x-[3%]">
            <Button
              onPress={() => setShowConfirmDeleteModal(false)}
              className="bg-[#1D2033] border-[1px] border-[#80EFAD] rounded-xl w-[47%]"
            >
              <Text className="font-unbounded self-center text-[#80EFAD]">
                Cancel
              </Text>
            </Button>
            <Button
              onPress={() => handleDeactivateAccount()}
              className="bg-[#80EFAD] rounded-xl w-[47%]"
            >
              <Text className="font-unbounded self-center text-[#000000]">
                Delete
              </Text>
            </Button>
          </View>
        </View>
      )}
      {isLoading && <LoadingScreen />}
    </KeyboardAvoidingView>
  );
}
