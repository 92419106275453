import { Image } from "expo-image";
import { useEffect, useMemo } from "react";

import { useCreatorForUserQuery } from "../../graphql/generated";
import {
  getImagePreviewUrlMedium,
  getImagePreviewUrlSmall,
  getImagePreviewUrlThumbnail,
} from "../../utils/image-preview-url";
import { isNew } from "../../utils/is-new";
import { notEmpty } from "../../utils/not-empty";
import { useEpisodesProgressions } from "../useEpisodesProgressions";

export function useCreator(creatorUUID: string) {
  const { data, loading, refetch } = useCreatorForUserQuery({
    variables: {
      creatorUUID,
    },
    fetchPolicy: "cache-and-network",
    skip: !creatorUUID,
  });

  const creatorData = data?.creatorForUser;

  const creator = creatorData
    ? {
        id: creatorData.creatorId.toString(),
        uuid: creatorData.creatorUUID,
        name: creatorData.displayName,
        bio: creatorData.bio,
        location: creatorData.location,
        subscriberCount: creatorData.subscriberCount,
        isSubscribed: creatorData.isSubscribed,
        hoursListened: creatorData.hoursListened,
        avatarUrl: creatorData.avatar
          ? getImagePreviewUrlThumbnail(creatorData.avatar)
          : "",
        creatorSubscriptionProductId: creatorData.revenueCatProductId,
        subscriptionPriceInCentsUsd: creatorData.subscriptionPriceInCents
          ? parseInt(creatorData.subscriptionPriceInCents, 10)
          : null,
        memberSinceYear: new Date(
          creatorData.createdAt ?? "2023"
        ).getFullYear(),
        isPreviewSubscriptionAvailable:
          (creatorData.isPreviewSubscriptionAvailable &&
            !creatorData.isSubscribed) ||
          false,
      }
    : undefined;

  const programsData = data?.creatorForUser?.programs?.filter(notEmpty) || [];

  const programs = programsData.reverse().map((row) => ({
    uuid: row.programUUID,
    id: row.programId.toString(),
    title: row.title || "",
    imageUrl: row.image ? getImagePreviewUrlSmall(row.image) : "",
    episodesCount: row.episodeCount,
    isAccessible: row.isPurchased || creator?.isSubscribed || false,
    programProductId: row.revenueCatProductId ?? undefined,
    isNew: isNew(row.publishedAt),
    priceInCents:
      typeof row.priceInCents === "string" && row.priceInCents.length > 0
        ? parseInt(row.priceInCents, 10)
        : null,
    isFree: row.isFree || false,
  }));

  const episodesData = programsData.flatMap(
    (row) =>
      row.episodes?.filter(notEmpty).map((episode) => ({
        programId: row.programId.toString(),
        programUUID: row.programUUID,
        isAccessible: row.isPurchased || creator?.isSubscribed || false,
        ...episode,
      })) || []
  );

  const episodesWithoutProgression = episodesData.map((row) => ({
    uuid: row.episodeUUID,
    title: row.title,
    imageUrl: row.image ? getImagePreviewUrlSmall(row.image) : "",
    imageUrlMedium: row.image ? getImagePreviewUrlMedium(row.image) : "",
    trackUrl: row.media?.url || "",
    creatorId: creator?.id || "",
    programId: row.programId,
    creatorUUID: creator?.uuid || "",
    programUUID: row.programUUID,
    isAccessible: row.isAccessible,
    progressPercentage: 0,
    progressInSeconds: 0,
  }));

  const events = useMemo(
    () =>
      data?.creatorForUser?.events?.filter(notEmpty).map((row) => ({
        uuid: row.uuid,
        title: row.title,
        imageUrl: row.image ? getImagePreviewUrlThumbnail(row.image) : "",
        status: row.status,
        progressPercentage: row.progressPercentage,
      })) || [],
    [data]
  );

  useEffect(() => {
    Image.prefetch(events.map((r) => r.imageUrl));
  }, [events]);

  const { episodes } = useEpisodesProgressions(episodesWithoutProgression);

  useEffect(() => {
    if (!creator) return;

    Image.prefetch(creator?.avatarUrl);
  }, [creator]);

  useEffect(() => {
    Image.prefetch(programs.map((program) => program.imageUrl));
  }, [programs]);

  useEffect(() => {
    Image.prefetch(episodes.map((episode) => episode.imageUrl));
  }, [episodes]);

  return {
    creator,
    programs,
    episodes,
    events,
    loading,
    refetch,
  };
}
