import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import { ImageBackground, Text, View } from "react-native";
import { Button } from "react-native-paper";
import { InvitationDetails } from "src/graphql/generated";

import { ScreenNavigationProps } from "../../navigation/RootStackParamList";
import Avatar, { ImageSize } from "../Avatar";

type InviteScreenProps = {
  invitation?: InvitationDetails;
  onSubmit: () => void;
};

function Invite({ invitation, onSubmit }: InviteScreenProps) {
  const navigation = useNavigation<ScreenNavigationProps>();

  if (!invitation) {
    return null;
  }

  const { creatorName, creatorUUID, creatorImageUrl } = invitation;

  return (
    <View className="h-full w-full">
      <ImageBackground
        className="h-[100%] bg-black w-full"
        source={require("../../../assets/invite-background.png")}
      >
        <View className="h-[50%] bg-transparent w-full flex flex-col pt-[70px] pb-[30px]">
          <View className="flex flex-row justify-start w-[80%] self-center">
            <Image
              style={{ width: 147, height: 24 }}
              source={require("../../../assets/peak-logo-white.png")}
            />
          </View>
          <View className="flex flex-col justify-end h-full w-full">
            <Text className="text-white text-2xl font-bold font-unbounded self-center mb-[40px]">
              Welcome aboard! 🎉
            </Text>
            <View className="self-center">
              {creatorImageUrl && (
                <Avatar
                  imageSize={ImageSize.Large}
                  thumbnail={creatorImageUrl}
                />
              )}
            </View>
          </View>
        </View>
        <View className="h-[50%]">
          <View className="w-[70%] h-full flex flex-col self-center justify-around mt-[20px pb-[30px] pt-[10px]">
            <View>
              <Text className="text-white text-[16px] self-center mb-[20px] text-center">
                You've been invited by
                <Text className="font-bold"> {creatorName} </Text>
                to explore Peak.audio.
              </Text>
              <Text className="text-white text-[16px] self-center mb-[20px] text-center">
                Enjoy
                <Text className="font-bold"> {creatorName}</Text>
                's programs and share your thoughts with the community in the
                social tab.
              </Text>
              <Text className="text-white text-[16px] self-center mb-[20px] text-center">
                Thank you for being a part of the Peak.audio community.
              </Text>
              <Text className="text-white text-[16px] self-center mb-[20px] text-center font-bold">
                Your journey begins now!
              </Text>
            </View>
            <Button
              className="rounded-2xl bg-[#80EFAD] border-solid border-black"
              textColor="#000000"
              onPress={async () => {
                onSubmit();
                navigation.navigate("Creator", {
                  creatorUUID,
                });
              }}
            >
              <Text className="font-unbounded text-black">Get Started</Text>
            </Button>
          </View>
        </View>
      </ImageBackground>
    </View>
  );
}
export default Invite;
