import { Image } from "expo-image";
import { View } from "react-native";

function LoadingSpinner() {
  return (
    <View className="h-[30px] w-full flex flex-row justify-center">
      <Image
        className="w-10 h-10"
        source={require("../../assets/icons/loading-spinner.gif")}
      />
    </View>
  );
}

export default LoadingSpinner;
