import { useNavigation } from "@react-navigation/native";
import { ScrollView, View, Text } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import Avatar, { ImageSize } from "./Avatar";
import { ScreenNavigationProps } from "../navigation/RootStackParamList";

export type CreatorListRow = {
  uuid: string;
  name: string;
  avatarUrl: string;
  isSubscribed: boolean;
};

type CreatorListProps = {
  creators: CreatorListRow[];
  title: string;
};

function CreatorList({ creators, title }: CreatorListProps) {
  const navigation = useNavigation<ScreenNavigationProps>();
  return (
    <View className="h-[150px] flex flex-col justify-center mb-4">
      <Text className="text-xl font-unbounded text-white mb-[10px] ml-1">
        {title}
      </Text>
      <ScrollView
        showsHorizontalScrollIndicator={false}
        horizontal
        className="flex flex-row space-x-[20px]"
      >
        {creators.map((creator) => {
          return (
            <TouchableOpacity
              key={creator.uuid}
              onPress={() =>
                navigation.navigate("Creator", {
                  creatorUUID: creator.uuid,
                })
              }
            >
              <View className="self-center flex flex-col">
                <Avatar
                  isSubscribed={creator.isSubscribed}
                  imageSize={ImageSize.Medium}
                  thumbnail={creator.avatarUrl}
                />
                <Text
                  numberOfLines={1}
                  className="mt-[5px] text-xs font-unbounded text-white w-[85px] px-[5px]"
                >{`@${creator.name}`}</Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    </View>
  );
}
export default CreatorList;
