import { AVPlaybackStatus, Audio } from "expo-av";
import { EmitterSubscription } from "react-native";
// We can import only types from react-native-track-player because the lib does not have any implementation for web and it breaks the web build & runtime
import type {
  MetadataOptions,
  EventPayloadByEvent,
  Track,
  PlaybackProgressUpdatedEvent,
} from "react-native-track-player";

import { Event } from "./Event";
import { RepeatMode } from "./RepeatMode";
import { TrackPlayerWrapperInterface } from "../TrackPlayerWrapper.interface";

export class WebTrackPlayer implements TrackPlayerWrapperInterface {
  private tracksQueue: Track[];
  private currentTrackIndex: number | null;
  public progressUpdateEventIntervalSeconds: number;
  public currentAudioObject: Audio.Sound | null;
  private listeners: ((status: AVPlaybackStatus) => void)[];
  private progressListeners: ((status: AVPlaybackStatus) => void)[];
  private progressInterval: NodeJS.Timer | null;
  private isProcessingCommand: boolean;
  private nextSeekToMillis: number;
  private repeatMode: RepeatMode;
  private defaultVolume: number;

  constructor() {
    this.tracksQueue = [];
    this.currentTrackIndex = null;
    this.progressUpdateEventIntervalSeconds = 1; // seconds
    this.listeners = [];
    this.currentAudioObject = null;
    this.progressListeners = [];
    this.progressInterval = null;
    this.isProcessingCommand = false;
    this.nextSeekToMillis = 0;
    this.repeatMode = RepeatMode.Off;
    this.defaultVolume = 1;
  }

  registerMetadataUpdateListener(listener: (status: AVPlaybackStatus) => void) {
    this.listeners = [...this.listeners, listener];
  }

  deregisterMetadataUpdateListener(
    listener: (status: AVPlaybackStatus) => void
  ) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  registerPorgressListener(listener: (status: AVPlaybackStatus) => void) {
    this.progressListeners = [...this.progressListeners, listener];

    if (!this.progressInterval) {
      this.progressInterval = setInterval(
        () => this.notifyProgressListeners(),
        this.progressUpdateEventIntervalSeconds * 1000
      );
    }
  }

  deregisterPorgressListener(listener: (status: AVPlaybackStatus) => void) {
    this.progressListeners = this.progressListeners.filter(
      (l) => l !== listener
    );
  }

  addEventListener<T extends Event>(
    event: T,
    listener: EventPayloadByEvent[T] extends never
      ? () => void
      : (event: EventPayloadByEvent[T]) => void
  ): EmitterSubscription {
    // We do not need to support the locked screen controls on web
    // But we need to support the progress updates so we can update the backend track progress
    if (event === Event.PlaybackProgressUpdated) {
      this.registerPorgressListener((metadata) => {
        if (!metadata.isLoaded) {
          return;
        }
        const event: PlaybackProgressUpdatedEvent = {
          track: this.currentTrackIndex ?? 0,
          position: metadata.positionMillis / 1000,
          duration: (metadata.durationMillis ?? 0) / 1000,
          buffered: (metadata.playableDurationMillis ?? 0) / 1000,
        };

        listener(event as EventPayloadByEvent[T]);
      });
    }

    return {} as EmitterSubscription;
  }

  updateNowPlayingMetadata(): Promise<void> {
    // We do not need to support the locked screen controls on web
    return Promise.resolve();
  }

  async skipToPrevious(): Promise<void> {
    if (this.currentTrackIndex === null) {
      return;
    }
    const newTrackIndex = Math.max(this.currentTrackIndex - 1, 0);

    if (newTrackIndex === this.currentTrackIndex) {
      return this.seekTo(0);
    }

    if (this.currentAudioObject) {
      await this.currentAudioObject.unloadAsync();
      this.currentAudioObject = null;
    }

    this.currentTrackIndex = newTrackIndex;
    return this.play();
  }

  async skipToNext(): Promise<void> {
    return this.skipToNextOrJumpToStart({ jumpToStart: true });
  }

  private async skipToNextOrJumpToStart(
    { jumpToStart } = { jumpToStart: false }
  ): Promise<void> {
    if (this.currentTrackIndex === null) {
      return;
    }
    const newTrackIndex = Math.min(
      this.currentTrackIndex + 1,
      this.tracksQueue.length - 1
    );

    if (newTrackIndex === this.currentTrackIndex && !jumpToStart) {
      return;
    }

    if (this.currentAudioObject) {
      await this.currentAudioObject.unloadAsync();
      this.currentAudioObject = null;
      this.nextSeekToMillis = 0;
    }

    if (newTrackIndex === this.currentTrackIndex && jumpToStart) {
      this.currentTrackIndex = 0;
    } else {
      this.currentTrackIndex = newTrackIndex;
    }

    return this.play();
  }

  async getDuration(): Promise<number> {
    if (!this.currentAudioObject) {
      return Promise.resolve(0);
    }

    const status = await this.currentAudioObject.getStatusAsync();

    if (status.isLoaded) {
      const durationMilis = status.durationMillis || 0;
      return durationMilis / 1000;
    }

    return 0;
  }

  async getPosition(): Promise<number> {
    if (!this.currentAudioObject) {
      return Promise.resolve(0);
    }

    const status = await this.currentAudioObject.getStatusAsync();

    if (status.isLoaded) {
      return status.positionMillis / 1000;
    }

    return 0;
  }

  async seekTo(position: number): Promise<void> {
    const positionMillis = position * 1000;
    if (!this.currentAudioObject) {
      this.nextSeekToMillis = positionMillis;
      return;
    }

    await this.currentAudioObject.setPositionAsync(positionMillis);
  }

  async play(): Promise<void> {
    if (this.isProcessingCommand) {
      return;
    }
    this.isProcessingCommand = true;

    try {
      if (this.currentAudioObject) {
        await this.currentAudioObject.playAsync();
        return;
      }

      const track = this.tracksQueue[this.currentTrackIndex ?? 0];
      if (!track) {
        return Promise.reject(new Error("No track to play"));
      }

      const trackUrl = track.url;

      if (typeof trackUrl !== "string" || trackUrl.length === 0) {
        return Promise.reject(new Error("Track url is missing"));
      }

      const { sound } = await Audio.Sound.createAsync(
        { uri: trackUrl },
        {
          shouldPlay: true,
          positionMillis: this.nextSeekToMillis,
          volume: this.defaultVolume,
        },
        (status) => this.handleStatusChange(status)
      );

      this.currentAudioObject = sound;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isProcessingCommand = false;
    }
  }

  async pause(): Promise<void> {
    if (this.currentAudioObject) {
      await this.currentAudioObject.pauseAsync();
    } else {
      console.warn("No audio object to pause");
      return Promise.resolve();
    }
  }

  async reset(): Promise<void> {
    if (this.currentAudioObject) {
      await this.currentAudioObject.unloadAsync();
    }

    this.currentAudioObject = null;
    this.currentTrackIndex = null;
    this.tracksQueue = [];

    return Promise.resolve();
  }

  setupPlayer(): Promise<void> {
    return Promise.resolve();
  }

  // We have to use any because we can not import enum from react-native-track-player because the lib does not have any implementation for web and it breaks the web build & runtime
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setRepeatMode(mode: any): Promise<any> {
    this.repeatMode = mode;
    return Promise.resolve(mode);
  }

  // We have to use any because we can not import enum from react-native-track-player because the lib does not have any implementation for web and it breaks the web build & runtime
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRepeatMode(): Promise<any> {
    return Promise.resolve(this.repeatMode);
  }

  async updateOptions(options?: MetadataOptions | undefined): Promise<void> {
    if (!options?.progressUpdateEventInterval) {
      return Promise.resolve();
    }

    this.progressUpdateEventIntervalSeconds =
      options.progressUpdateEventInterval;

    if (this.progressInterval) {
      clearInterval(this.progressInterval);

      this.progressInterval = setInterval(
        () => this.notifyProgressListeners(),
        this.progressUpdateEventIntervalSeconds * 1000
      );
    }
  }

  skip(trackIndex: number): Promise<void> {
    this.currentTrackIndex = trackIndex;
    return Promise.resolve();
  }

  add(tracks: Track | Track[]): Promise<number | void> {
    if (Array.isArray(tracks)) {
      this.tracksQueue = [...this.tracksQueue, ...tracks];
    } else {
      this.tracksQueue = [...this.tracksQueue, tracks];
    }

    return Promise.resolve();
  }

  getCurrentTrack(): Promise<number | null> {
    return Promise.resolve(this.currentTrackIndex);
  }

  getTrack(trackIndex: number): Promise<Track | null> {
    return Promise.resolve(this.tracksQueue[trackIndex] ?? null);
  }

  getVolume(): Promise<number> {
    return Promise.resolve(this.defaultVolume);
  }

  async setVolume(volume: number): Promise<void> {
    this.defaultVolume = volume;
    await this.currentAudioObject?.setVolumeAsync(volume);
  }

  async registerPlaybackService(factory: () => () => Promise<void>) {
    await factory()();
  }

  private async handleTrackEnd() {
    if (this.repeatMode === RepeatMode.Track) {
      await this.seekTo(0);
      await this.play();
    } else if (this.repeatMode === RepeatMode.Queue) {
      await this.skipToNextOrJumpToStart({ jumpToStart: true });
    } else if (this.repeatMode === RepeatMode.Off) {
      await this.skipToNextOrJumpToStart({ jumpToStart: false });
    }
  }

  private handleStatusChange(status: AVPlaybackStatus) {
    if (status.isLoaded && status.didJustFinish) {
      void this.handleTrackEnd();
      if (status.isPlaying) {
        this.nextSeekToMillis = 0;
      }
    }

    this.listeners.forEach((cb) => cb(status));
  }

  private async notifyProgressListeners() {
    if (!this.currentAudioObject) {
      return;
    }

    const status = await this.currentAudioObject.getStatusAsync();

    if (status.isLoaded) {
      if (!status.isPlaying) return;
      this.progressListeners.forEach((cb) => cb(status));
    }
  }
}
