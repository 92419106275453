import { Image } from "expo-image";
import { useEffect, useState } from "react";
import { View, Text, Platform } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { IconButton } from "react-native-paper";

export function usePWABanner() {
  const [deferredPrompt, setDeferredPrompt] = useState<Event>();
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (Platform.OS !== "web") return;

    const isAndroid = navigator.userAgent.includes("Android");
    if (!isAndroid) return;

    window.addEventListener("beforeinstallprompt", (e) => {
      console.log("beforeinstallprompt event was fired.", e);
      setDeferredPrompt(e);
    });
  }, []);

  async function handleInstall() {
    if (!deferredPrompt) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (deferredPrompt as any).prompt();
    // Wait for the user to respond to the prompt
    console.log("Waiting for user install prompt response...");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { outcome } = await (deferredPrompt as any).userChoice;
    console.log("User response to the install prompt:", outcome);
    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`);
    // We've used the prompt, and can't use it again, throw it away
    setIsHidden(true);
  }

  const showBanner = deferredPrompt && !isHidden;

  const banner = showBanner && (
    <View className="absolute t-0 w-full bg-main z-50 flex flex-row justify-center items-center border-b-subtle border-b-2 py-1">
      <TouchableOpacity
        onPress={handleInstall}
        className="flex flex-row items-center gap-3 cursor-pointer"
      >
        <Image
          className="h-10 w-10 rounded-md cursor-pointer"
          contentFit="contain"
          source={require("../../assets/icon.png")}
        />
        <Text className="font-semibold cursor-pointer text-white">
          Add Peakaudio to Homescreen
        </Text>
      </TouchableOpacity>
      <IconButton
        onPress={() => setIsHidden(true)}
        icon="close"
        iconColor="white"
      />
    </View>
  );

  return { banner };
}
