import { useNavigation } from "@react-navigation/native";
import { View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Text } from "react-native-paper";

import Avatar, { ImageSize } from "../../components/Avatar";
import {
  ProgramPreview,
  ProgramPreviewCreator,
} from "../../hooks/programs/useProgramPreview";
import { useStoreProducts } from "../../hooks/useStoreProducts";
import { ScreenNavigationProps } from "../../navigation/RootStackParamList";
import { notEmpty } from "../../utils/not-empty";

type ProgramPreviewOverviewProps = {
  creator: ProgramPreviewCreator;
  program: ProgramPreview;
};

function usePorgramDescription(program: ProgramPreview) {
  // const { width } = useWindowDimensions();

  if (!program.description) {
    return "No Description Available";
  }

  // if (Platform.OS === "web") {
  return program.description;
  // }

  // return (
  //   <WebView
  //     width={width}
  //     height={100}
  //     style={{ flex: 1 }}
  //     source={{
  //       html: `<html><body>${program.description}</body></html>`,
  //     }}
  //   />
  // );
}

export function ProgramPreviewOverview({
  creator,
  program,
}: ProgramPreviewOverviewProps) {
  const navigation = useNavigation<ScreenNavigationProps>();

  const productIds = [
    program.programProductId,
    creator.creatorSubscriptionProductId,
  ].filter(notEmpty);

  const { products, getPlatformPriceString } = useStoreProducts(productIds);
  const programDescription = usePorgramDescription(program);

  const oneTimeProduct = products.find(
    (product) => product.identifier === program.programProductId
  );

  const subscriptionProduct = products.find(
    (product) => product.identifier === creator.creatorSubscriptionProductId
  );

  const programPrice = getPlatformPriceString(
    oneTimeProduct,
    program.priceInCents
  );

  const subscriptionPrice = getPlatformPriceString(
    subscriptionProduct,
    creator.subscriptionPriceInCents
  );

  return (
    <View className="flex flex-col h-full space-y-6 pb-[30px]">
      <Text className="text-white text-sm mt-5">{programDescription}</Text>
      <View className="flex flex-row justify-items-start w-full">
        <View className="flex flex-col self-start space-y-3">
          <Text className="text-[#FFFFFF85] text-sm w-[50%]">
            One Time Purchase of This Program
          </Text>
          <Text className="text-[#FFFFFF] font-unbounded text-xl w-[50%]">
            {!program?.isFree ? programPrice : "FREE"}
          </Text>
        </View>
        <View className="flex flex-col -ml-8 space-y-3">
          <Text className="text-[#FFFFFF85] text-sm w-[50%]">
            Subscribe for all content from this creator
          </Text>
          <Text className="text-[#FFFFFF] font-unbounded text-xl w-[50%]">
            {subscriptionPrice}/ MO
          </Text>
        </View>
      </View>
      <View className="w-full h-1 bg-[#FFFFFF40]" />
      <Text className="text-[#FFFFFF] text-xl font-unbounded w-[70%]">
        About Creator
      </Text>
      <View className="flex flex-row">
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("Creator", {
              creatorUUID: `${creator.uuid}`,
            })
          }
        >
          <Avatar imageSize={ImageSize.Large} thumbnail={creator.avatarUrl} />
        </TouchableOpacity>
        <View className="flex flex-col justify-end space-y-2 ml-5">
          <TouchableOpacity
            onPress={() =>
              navigation.navigate("Creator", {
                creatorUUID: `${creator.uuid}`,
              })
            }
          >
            <Text
              numberOfLines={2}
              className="text-[#FFFFFF] font-unbounded text-[14px] w-[100%]"
            >
              {`@${creator.name}`}
            </Text>
          </TouchableOpacity>
          <Text className="text-[#FFFFFF] font-unbounded text-xs w-[100%]">
            {creator.location && creator.location}
            {!creator.location && `---`}
          </Text>
          <Text className="text-[#FFFFFF50] font-unbounded text-xs w-[100%]">
            Member from {creator.memberSinceYear}
          </Text>
        </View>
      </View>
      <View className="flex flex-row justify-around">
        <View className="flex flex-col space-y-1">
          <Text className="text-[#FFFFFF80] text-sm w-[100%]">Programs</Text>
          <Text className="text-[#FFFFFF] font-unbounded text-xl w-[100%]">
            {creator.programCount}
          </Text>
        </View>
        <View className="flex flex-col space-y-1">
          <Text className="text-[#FFFFFF80] text-md w-[100%]">Listeners</Text>
          <Text className="text-[#FFFFFF] font-unbounded text-xl w-[100%]">
            {creator.listenerCount}
          </Text>
        </View>
        <View className="flex flex-col space-y-1">
          <Text className="text-[#FFFFFF80] text-md w-[100%]">
            Hours listened
          </Text>
          <Text className="text-[#FFFFFF] font-unbounded text-xl w-[100%]">
            {creator.hoursListened}
          </Text>
        </View>
      </View>
      <Text className="text-white text-sm mt-3">{creator.bio}</Text>
    </View>
  );
}
