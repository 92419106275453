import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import { useRef, useState } from "react";
import {
  ScrollView,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle,
  useWindowDimensions,
} from "react-native";
import { RefreshControl } from "react-native-gesture-handler";
import { IconButton, Button, Text } from "react-native-paper";

import Documents from "../components/Documents";
import LoadingScreen from "../components/Loading";
import LoadingSpinner from "../components/LoadingSpinner";
import { TrialConfirmationScreen } from "../components/TrialConfirmationScreen";
import VideoPlayer from "../components/VideoPlayer";
import { AudioPlayer } from "../components/audio-player/AudioPlayer";
import { FlagContentModal } from "../components/program-preview/FlagContentModal";
import { PdfViewerModal } from "../components/program-preview/PdfViewerModal";
import { ProgramPreviewEpisodes } from "../components/program-preview/ProgramPreviewEpisodes";
import { ProgramPreviewNavigation } from "../components/program-preview/ProgramPreviewNavigation";
import { ProgramPreviewOverview } from "../components/program-preview/ProgramPreviewOverview";
import {
  CreatorForUserDocument,
  GetMyUserProfileInfoDocument,
  MyPurchasedCreatorsDocument,
  ProgramPreviewDocument,
  usePurchaseFreeProgramMutation,
} from "../graphql/generated";
import {
  ProgramPreview,
  useProgramPreview,
} from "../hooks/programs/useProgramPreview";
import { useCreatePreviewSubscription } from "../hooks/useCreatePreviewSubscription";
import { CachedImageBackground } from "../lib/CachedImageBackground/CachedImageBackground";
import {
  ProgramPreviewScreenProps,
  ScreenNavigationProps,
} from "../navigation/RootStackParamList";
import { goBack } from "../navigation/goBack";
import { useMyPlayer } from "../providers/FullscreenPlayerProvider";
import { ErrorReportingService } from "../services/ErrorReportingService";

function ProgramPreviewScreen({ route }: ProgramPreviewScreenProps) {
  const { programUUID } = route?.params || {};

  const [showTrailModal, setShowTrailModal] = useState(false);
  const [showFlagContentModal, setShowFlagContentModal] = useState(undefined);
  const [pdfPreviewSourceUrl, setShowPdfPreviewSourceUrl] = useState<
    string | undefined
  >(undefined);

  const scrollRef = useRef<ScrollView>(null);
  const { width } = useWindowDimensions();

  const [purchaseFreeProgram, { loading: purchaseFreeProgramLoading }] =
    usePurchaseFreeProgramMutation({
      refetchQueries: [
        GetMyUserProfileInfoDocument,
        ProgramPreviewDocument,
        CreatorForUserDocument,
        MyPurchasedCreatorsDocument,
      ],
      onCompleted: () => {
        void refetch();
      },
      onError: (e) => {
        ErrorReportingService.report(e);
        alert("Error unlocking free program");
        void refetch();
      },
    });

  const scrollToTop = () => {
    scrollRef.current?.scrollTo({ x: 0, y: 0, animated: false });
  };
  const {
    program,
    creator,
    documents,
    episodes,
    loading: programPreviewLoading,
    refetch,
  } = useProgramPreview(programUUID);

  const [navFocus, setNavFocus] = useState("Episodes");
  const { playPlaylist, currentTrack } = useMyPlayer();
  const [introVideoModalUrl, setIntroVideoModalUrl] = useState<string>();
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const { createPreviewSubscription, createPreviewSubscriptionLoading } =
    useCreatePreviewSubscription({
      creatorUUID: creator?.uuid || "",
      onRefetch: refetch,
    });

  const loading =
    programPreviewLoading ||
    purchaseFreeProgramLoading ||
    createPreviewSubscriptionLoading;

  const navigation = useNavigation<ScreenNavigationProps>();

  const startListeningDisabled = currentTrack?.programUUID === programUUID;

  const sortedEpisodes = episodes
    .slice()
    .sort((a, b) => a.episodeNumber - b.episodeNumber);

  async function handlePlayProgram() {
    if (startListeningDisabled) {
      console.error("Start listening disabled");
      return;
    }

    if (!episodes[0]) {
      console.error("No episodes found - firstEpisode is empty");
      return;
    }

    return playPlaylist(episodes);
  }

  async function handlePlayPreview() {
    if (!program?.previewAudioUrl) {
      return;
    }

    const previewEpisodes = [
      {
        uuid: null,
        trackUrl: program.previewAudioUrl,
        trackMime: "audio/mpeg",
        title: `Preview: ${program.title}`,
        imageUrlMedium: program.imageUrl,
        progressInSeconds: 0,
        programUUID: program.uuid,
      },
    ];

    return playPlaylist(previewEpisodes);
  }

  function onProgramPurchase(program: ProgramPreview) {
    if (!creator) {
      alert("This program does not have a creator");
      return;
    }

    const {
      isAccessible,
      programProductId,
      isFree,
      uuid: programUUID,
    } = program;
    const { creatorSubscriptionProductId, uuid: creatorUUID } = creator;
    if (isAccessible) {
      alert("You already have access to this program");
      return;
    }

    if (isFree) {
      return purchaseFreeProgram({
        variables: {
          programUUID,
        },
      });
    }

    navigation.navigate("Purchase", {
      creatorUUID,
      creatorSubscriptionProductId: creatorSubscriptionProductId || null,
      programProductId: programProductId || null,
      programUUID,
      programPriceInCentsUsd:
        typeof program.priceInCents === "number" ? program.priceInCents : null,
      subscriptionPriceInCentsUsd: creator.subscriptionPriceInCents,
    });
  }

  async function handleRefresh() {
    setIsRefreshing(true);
    try {
      await Promise.all([refetch()]);
    } catch (e) {
      console.log("Error refreshing homepage data", e);
    }
    setIsRefreshing(false);
  }

  function handleTrialSubmit() {
    setShowTrailModal(false);
    return createPreviewSubscription();
  }

  const refreshControl = (
    <RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} />
  );

  const isWiderThanMobile = width > 1024;

  const scrollViewStyles: StyleProp<ViewStyle> = isWiderThanMobile
    ? {
        flexDirection: "row",
        zIndex: 10,
        marginTop: 80,
        marginHorizontal: 80,
        maxWidth: 1152,
      }
    : { flexDirection: "column", maxWidth: 1152 };

  return (
    <View className="w-full h-full bg-[#1D2033] flex-1">
      {isRefreshing && (
        <View className="mb-[40px] -mt-[30px]">
          <LoadingSpinner />
        </View>
      )}
      {!loading && program && creator ? (
        <ScrollView
          scrollEnabled={showFlagContentModal === undefined}
          ref={scrollRef}
          refreshControl={refreshControl}
          stickyHeaderIndices={isWiderThanMobile ? [0] : []}
          contentContainerStyle={scrollViewStyles}
        >
          {/* Left bar / header */}
          <View
            className="flex flex-col lg:z-10 lg:top-5 lg:w-72 xl:w-96 lg:items-start"
            style={
              isWiderThanMobile
                ? ({ position: "sticky" } as unknown as StyleProp<ViewStyle>)
                : {}
            }
          >
            {/* Mobile header */}
            <CachedImageBackground
              source={{ uri: program.imageUrl }}
              className="h-[255px] lg:hidden"
              cachePolicy="memory-disk"
              contentFit="cover"
              contentPosition="top center"
            >
              <View className="h-[255px] bg-[#00000050]">
                <IconButton
                  iconColor="black"
                  className="ml-[20px] mt-[70px] bg-[#80EFAD]"
                  onPress={() => goBack(navigation)}
                  icon="arrow-left"
                />
                <View className="mt-auto mb-5 mx-7">
                  {program.isNew && (
                    <View className="bg-green-300 rounded-lg py-1 w-14 text-center">
                      <Text className="text-black font-unbounded text-sm text-center">
                        New
                      </Text>
                    </View>
                  )}
                  <Text className="text-white font-unbounded text-xl">
                    {program.title}
                  </Text>
                  <View className="flex flex-row justify-start items-center mt-1">
                    <Image
                      className="w-3 h-3 opacity-100"
                      source={require("../../assets/icons/episodes.png")}
                    />
                    <Text className="text-md font-unbounded text-[#FFFFFF] ml-2 font-thin">
                      {episodes.length}{" "}
                      {episodes.length === 1 ? `Episode` : `Episodes`}
                    </Text>
                  </View>
                </View>
              </View>
            </CachedImageBackground>
            {/* Desktop header - creator */}
            <View className="hidden lg:flex flex-row items-center pl-4 mt-4">
              <Image
                className="rounded-full h-16 w-16 bg-black"
                contentFit="contain"
                source={creator.avatarUrl}
              />
              <View className="flex flex-col">
                <Text className="text-white font-unbounded text-md ml-3">
                  {creator.name}
                </Text>
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate("Creator", {
                      creatorUUID: creator.uuid,
                    })
                  }
                  className="flex flex-row w-full items-center"
                >
                  <Text className="text-[#80EFAD] font-unbounded text-xs ml-3">
                    View Creator Profile
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            {/* Controls */}
            <View className="flex flex-col pt-2 px-3 gap-y-2.5 lg:mt-4">
              {!program.isAccessible && (
                <Button
                  onPress={() => onProgramPurchase(program)}
                  className="bg-green-300 rounded-xl text-black"
                >
                  {program.isFree ? (
                    <Text className="font-unbounded self-center text-black">
                      Get for free
                    </Text>
                  ) : (
                    <Text className="font-unbounded self-center text-black">
                      Purchase Program
                    </Text>
                  )}
                </Button>
              )}
              {program.isAccessible && (
                <Button
                  disabled={startListeningDisabled}
                  onPress={handlePlayProgram}
                  className={
                    startListeningDisabled ? `bg-gray-500` : `bg-green-300`
                  }
                >
                  <Text className="font-unbounded self-center text-black">
                    Start Listening
                  </Text>
                </Button>
              )}
              <View className="flex flex-row w-full justify-center content-center space-x-2">
                {program.previewAudioUrl && (
                  <View className="flex-1 border-[1px] border-[#80EFAD] rounded-xl">
                    <Button
                      icon="play"
                      onPress={handlePlayPreview}
                      labelStyle={{ color: "#80EFAD" }}
                      className="bg-transparent"
                    >
                      <Text className="font-unbounded self-center text-[#80EFAD]">
                        Preview
                      </Text>
                    </Button>
                  </View>
                )}
                {program.isPreviewSubscriptionAvailable &&
                  !program.previewAudioUrl && (
                    <View className="flex-1 border-[1px] border-[#80EFAD] rounded-xl">
                      <Button
                        labelStyle={{
                          color: "#80EFAD",
                        }}
                        className="bg-transparent"
                        onPress={() => setShowTrailModal(true)}
                      >
                        <Text className="font-unbounded self-center text-[#80EFAD]">
                          Trial
                        </Text>
                      </Button>
                    </View>
                  )}
                {program.introVideoUrl && (
                  <View className="flex-1 border-[1px] border-[#80EFAD] rounded-xl">
                    <Button
                      icon={require("../../assets/icons/video.png")}
                      labelStyle={{
                        color: "#80EFAD",
                      }}
                      className="bg-transparent "
                      onPress={() =>
                        setIntroVideoModalUrl(program.introVideoUrl)
                      }
                    >
                      <Text className="font-unbounded self-center text-[#80EFAD]">
                        Intro
                      </Text>
                    </Button>
                  </View>
                )}
              </View>
            </View>
            {/* Program info */}
            <View className="hidden lg:flex flex-col px-3">
              <Image
                className="rounded-lg bg-black w-full mt-5 aspect-square"
                contentFit="contain"
                source={program.imageUrl}
              />
              <Text className="text-white font-unbounded text-3xl mt-6">
                {program.title}
              </Text>
              <View className="flex flex-row justify-start items-center mt-3">
                <Image
                  className="w-3 h-3 opacity-100"
                  source={require("../../assets/icons/episodes.png")}
                />
                <Text className="text-md font-unbounded text-[#FFFFFF] ml-2 font-thin tracking-widest">
                  {episodes.length}{" "}
                  {episodes.length === 1 ? `EPISODE` : `EPISODES`}
                </Text>
              </View>
            </View>
          </View>

          {/* Content */}
          <View className="flex flex-col flex-auto mx-4 lg:ml-10">
            {/* Content navigation and episodes list for the mobile view */}
            <View className="lg:hidden mt-5">
              <ProgramPreviewNavigation
                navEpisodes={() => setNavFocus("Episodes")}
                navOverview={() => setNavFocus("Overview")}
                navSupport={() => setNavFocus("Support")}
                navFocus={navFocus}
              />
              {navFocus === "Episodes" && (
                <ProgramPreviewEpisodes
                  doesHaveAccess={program.isAccessible}
                  episodes={sortedEpisodes}
                  handleScroll={scrollToTop}
                  setShowFlagContentModal={setShowFlagContentModal}
                />
              )}
              {navFocus === "Overview" && (
                <ProgramPreviewOverview program={program} creator={creator} />
              )}
              {navFocus === "Support" && (
                <View className="flex flex-row justify-center mt-3">
                  <Documents
                    documents={documents}
                    shouldShowPurchase={!program.isAccessible}
                    setShowPdfViewerModal={setShowPdfPreviewSourceUrl}
                  />
                </View>
              )}
            </View>
            {/* Episodes list for the desktop view */}
            <View className="hidden lg:flex">
              <ProgramPreviewEpisodes
                doesHaveAccess={program.isAccessible}
                episodes={sortedEpisodes}
                handleScroll={scrollToTop}
                setShowFlagContentModal={setShowFlagContentModal}
              />
            </View>
          </View>
        </ScrollView>
      ) : (
        <LoadingScreen />
      )}
      {showFlagContentModal !== undefined && creator && program && (
        <FlagContentModal
          episodeUUID={showFlagContentModal}
          setShowFlagContentModal={setShowFlagContentModal}
          creatorUUID={creator.uuid}
          programUUID={program.uuid}
        />
      )}
      {showTrailModal && (
        <TrialConfirmationScreen
          onCancel={() => setShowTrailModal(false)}
          onConfirm={handleTrialSubmit}
          creatorImageUrl={creator?.avatarUrl || ""}
          creatorName={creator?.name || ""}
        />
      )}
      {!loading && !program && <Text>No program data</Text>}
      {introVideoModalUrl && (
        <VideoPlayer
          videoUri={introVideoModalUrl}
          onClose={() => setIntroVideoModalUrl(undefined)}
        />
      )}
      {setShowPdfPreviewSourceUrl !== undefined &&
        pdfPreviewSourceUrl !== undefined && (
          <PdfViewerModal
            setShowPdfViewerModal={setShowPdfPreviewSourceUrl}
            pdfSrc={pdfPreviewSourceUrl}
          />
        )}
      {!introVideoModalUrl && <AudioPlayer />}
    </View>
  );
}
export default ProgramPreviewScreen;
