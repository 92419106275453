import { Image } from "expo-image";
import { useEffect } from "react";

import { usePreviousValue } from "./usePreviousValue";
import { useMyProgressionsQuery } from "../graphql/generated";
import { useMyPlayer } from "../providers/FullscreenPlayerProvider";
import {
  getImagePreviewUrlMedium,
  getImagePreviewUrlSmall,
} from "../utils/image-preview-url";
import { notEmpty } from "../utils/not-empty";

export type ContinuableEpisode = {
  uuid: string;
  title: string;
  trackUrl: string;
  imageUrl: string;
  imageUrlMedium: string;
  progressInSeconds: number;
  progressPercentage: number;
  programUUID: string;
};

export function useContinuableEpisodes() {
  const { currentTrack } = useMyPlayer();

  const previousTrack = usePreviousValue(currentTrack);

  const { data, refetch, loading, error } = useMyProgressionsQuery({});

  const items = data?.myProgressions?.filter(notEmpty) || [];

  const continuableEpisodesRes: ContinuableEpisode[] = items
    .filter((item) => item.progressPercentage ?? 0 < 100)
    .map((item) => ({
      uuid: item.episodeUUID,
      title: item.title,
      trackUrl: item.media?.url ?? "",
      trackMime: item.media?.mime ?? "",
      imageUrl: item.image ? getImagePreviewUrlSmall(item.image) : "",
      imageUrlMedium: item.image ? getImagePreviewUrlMedium(item.image) : "",
      progressInSeconds: item.progressInSeconds ?? 0,
      progressPercentage: item.progressPercentage ?? 0,
      programUUID: item.programUUID,
    }));

  const episodeUuids = continuableEpisodesRes.map(({ uuid }) => uuid);

  const continuableEpisodes = continuableEpisodesRes.filter(
    ({ uuid }, index) => !episodeUuids.includes(uuid, index + 1)
  );

  // If previously played song was on the list of continuable episodes, refetch the list to refresch progression
  useEffect(() => {
    const previousEpisodeUUID = previousTrack?.episodeUUID;
    if (!previousEpisodeUUID) {
      return;
    }

    if (
      continuableEpisodes.find(
        (episode) => episode.uuid === previousEpisodeUUID
      )
    ) {
      void refetch();
    }
  }, [currentTrack]);

  useEffect(() => {
    Image.prefetch(continuableEpisodes.map((episode) => episode.imageUrl));
  }, [continuableEpisodes]);

  return {
    loading,
    error,
    refetch,
    continuableEpisodes,
  };
}
