import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import * as WebBrowser from "expo-web-browser";
import { useState } from "react";
import {
  View,
  StyleSheet,
  StatusBar,
  Text,
  Platform,
  KeyboardAvoidingView,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Button, Snackbar, Portal, IconButton } from "react-native-paper";

import LoginTextField from "../components/LoginTextField";
import TermsAndPrivacy from "../components/TermsAndPrivacy";
import { useLogin } from "../hooks/useLogin";
import { LoginScreenNavigationProps } from "../navigation/RootStackParamLoginList";

export const isAndroid = () => Platform.OS === "android";

WebBrowser.maybeCompleteAuthSession();

function SignIn() {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const navigation = useNavigation<LoginScreenNavigationProps>();

  const { login } = useLogin();

  const validateInput = () => {
    const errors =
      !identifier ||
      identifier.length === 0 ||
      !password ||
      password.length === 0;

    return !errors;
  };
  const authenticateUser = async () => {
    if (validateInput()) {
      setLoading(true);
      try {
        await login({ identifier, password });
      } catch (err) {
        setError((err as Error).message);
        setVisible(true);
        setLoading(false);
      }
    } else {
      setError("Please fill out all *required fields");
      setVisible(true);
      setLoading(false);
    }
  };

  return (
    <ImageBackground
      className="h-full w-full bg-black"
      source={require("../../assets/login-background.png")}
    >
      <ScrollView keyboardShouldPersistTaps="handled">
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          keyboardVerticalOffset={10}
          behavior="position"
        >
          <View className="flex flex-row w-full justify-start mt-[140px]">
            <IconButton
              iconColor="black"
              className="ml-[40px] bg-white"
              onPress={() => navigation.navigate("Prelogin")}
              icon="arrow-left"
            />
          </View>
          <View className="h-full flex flex-col justify-start mx-10 pt-7 md:ml-40 md:max-w-sm">
            <Image
              style={{ width: 149, height: 24 }}
              source={require("../../assets/peak-logo-white.png")}
            />
            <>
              <StatusBar backgroundColor="#FFFFFF" barStyle="dark-content" />
            </>
            <View className="flex flex-col mt-10 w-3/4 justify-start space-y-3">
              <Text className="font-unbounded text-white text-xl ml-[2px]">
                Sign In With Username/Email
              </Text>
              <Text className="font-inter text-white text-sm">
                Enter your credentials to log in
              </Text>
            </View>
            <View style={styles.divider} />
            <View className="flex flex-col space-y-3">
              <LoginTextField
                textHook={setIdentifier}
                placeholder="*Username or Email"
              />
              <LoginTextField
                textHook={setPassword}
                placeholder="*Password"
                secure
              />
            </View>
            <View style={styles.divider} />
            <Button
              className="rounded-none bg-[#80EFAD] border-solid border-black"
              textColor="#000000"
              loading={loading}
              disabled={loading}
              onPress={() => authenticateUser()}
            >
              <Text className="font-unbounded self-center"> Sign In </Text>
            </Button>
            <View style={styles.divider} />
            <TouchableOpacity
              onPress={() => navigation.navigate("RequestReset")}
              className="w-fit h-fit"
            >
              <Text
                className="text-white text-xs text-center"
                style={{ textDecorationLine: "underline" }}
              >
                Forgot your password?
              </Text>
            </TouchableOpacity>
            <TermsAndPrivacy />
            <View style={styles.divider} />
            {/**
             * We use a portal component to render
             * the snackbar on top of everything else
             * */}
            <Portal>
              <Snackbar visible={visible} onDismiss={() => setVisible(false)}>
                {error}
              </Snackbar>
            </Portal>
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    </ImageBackground>
  );
}
const styles = StyleSheet.create({
  divider: {
    height: 16,
  },
});
export default SignIn;
