import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import * as WebBrowser from "expo-web-browser";
import { useState } from "react";
import {
  View,
  StatusBar,
  Text,
  Platform,
  KeyboardAvoidingView,
  ImageBackground,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Button, Snackbar, Portal, IconButton } from "react-native-paper";

import LoginTextField from "../components/LoginTextField";
import { useResetPassword } from "../hooks/password/useResetPassword";
import { LoginScreenNavigationProps } from "../navigation/RootStackParamLoginList";

export const isAndroid = () => Platform.OS === "android";

WebBrowser.maybeCompleteAuthSession();

function ResetPassword() {
  const [code, setCode] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const navigation = useNavigation<LoginScreenNavigationProps>();

  const { resetPassword } = useResetPassword({
    newPassword1: password1,
    newPassword2: password2,
    resetToken: code,
  });

  const validateInput = () => {
    if (!code || code.length === 0) {
      setError("Please fill out all *required fields");
      return false;
    }
    if (!password1 || password1.length === 0) {
      setError("Please fill out all *required fields");
      return false;
    }
    if (!password2 || password2.length === 0) {
      setError("Please fill out all *required fields");
      return false;
    }
    if (password1 !== password2) {
      setError("Passwords do not match.");
      return false;
    }
    return true;
  };

  async function authenticateUser() {
    if (validateInput()) {
      setLoading(true);
      const response = await resetPassword().catch((error) => {
        setError(error.message);
        setVisible(true);
      });
      setLoading(false);
      if (response) {
        navigation.navigate("Prelogin");
      }
    } else {
      setVisible(true);
    }
  }

  return (
    <ImageBackground
      className="h-full w-full bg-black"
      source={require("../../assets/login-background.png")}
    >
      <ScrollView>
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          keyboardVerticalOffset={10}
          behavior="position"
        >
          <View className="flex flex-row w-full justify-start mt-[140px]">
            <IconButton
              iconColor="black"
              className="ml-[40px] bg-white"
              onPress={() => navigation.navigate("RequestReset")}
              icon="arrow-left"
            />
          </View>
          <View className="h-full flex flex-col justify-start mx-10 pt-7 md:max-w-sm md:ml-40 md:mt-40">
            <Image
              style={{ width: 147, height: 24 }}
              source={require("../../assets/peak-logo-white.png")}
            />
            <>
              <StatusBar backgroundColor="#FFFFFF" barStyle="dark-content" />
            </>
            <View className="flex flex-col mt-10 w-3/4 justify-start space-y-3">
              <Text className="-ml-1 font-unbounded text-white text-xl">
                Confirm Password Change
              </Text>
              <Text className="font-inter text-white text-sm">
                Check your email inbox for the confirmation code (case
                sensitive).
              </Text>
            </View>
            <View className="h-[16px]" />
            <View className="flex flex-col space-y-3">
              <View className="flex flex-col space-y-44" />
              <LoginTextField
                textHook={setCode}
                placeholder="*Confirmation Code"
              />
              <LoginTextField
                textHook={setPassword1}
                placeholder="*Password"
                secure
              />
              <LoginTextField
                textHook={setPassword2}
                placeholder="*Repeat Password"
                secure
              />
            </View>
            <>
              <View className="h-[16px]" />
              <Button
                className="rounded-none bg-[#80EFAD] border-solid border-black"
                textColor="#000000"
                loading={loading}
                disabled={loading}
                onPress={() => authenticateUser()}
              >
                <Text className="font-unbounded self-center">
                  Reset Password
                </Text>
              </Button>
              <View className="h-[16px]" />
              <View className="h-[16px]" />
            </>
            <>
              {/**
               * We use a portal component to render
               * the snackbar on top of everything else
               * */}
              <Portal>
                <Snackbar visible={visible} onDismiss={() => setVisible(false)}>
                  {error}
                </Snackbar>
              </Portal>
            </>
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    </ImageBackground>
  );
}

export default ResetPassword;
