import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import {
  ScrollView,
  View,
  Image,
  Text,
  RefreshControl,
  TouchableOpacity,
} from "react-native";

import Billboard from "../components/BillboardContainer";
import CreatorList from "../components/CreatorList";
import LoadingScreen from "../components/Loading";
import LoadingSpinner from "../components/LoadingSpinner";
import ProgramGridContainer from "../components/ProgramGridContainer";
import ProgramsThumbnailContainer from "../components/ProgramsThumbnailContainer";
import { StreakScreen } from "../components/StreakScreen";
import { AudioPlayer } from "../components/audio-player/AudioPlayer";
import { EventsContainer } from "../components/events/EventsContainer";
import ContinueListening from "../components/home-page/ContinueListening";
import { TutorialModalHome } from "../components/tutorial/TutorialModalHome";
import { useSubscribedCreators } from "../hooks/creators/useSubscribedCreators";
import { useMyEvents } from "../hooks/events/useMyEvents";
import { useEventCurrentStreak } from "../hooks/leaderboard/useCurrentStreak";
import { useUserLeaderboard } from "../hooks/leaderboard/useUserLeaderboard";
import { useMostPopularPrograms } from "../hooks/programs/useMostPopularPrograms";
import { useContinuableEpisodes } from "../hooks/useContinuableEpisodes";
import { useGetMyProgramsAndEpisodes } from "../hooks/useGetMyProgramsAndEpisodes";
import { useUserProfile } from "../hooks/useUserProfile";
import { ScreenNavigationProps } from "../navigation/RootStackParamList";

function Homepage() {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [streakModalInfo, setStreakVisible] = useState<{
    imageUrl: string;
    currentStreak: number;
    longestStreak: number;
  }>();

  const navigation = useNavigation<ScreenNavigationProps>();

  const {
    userProfile,
    loading: userProfileLoading,
    refetch: userProfileRefetch,
  } = useUserProfile();

  const {
    continuableEpisodes,
    refetch: refetchContinuableEpisodes,
    loading: continuableEpisodesLoading,
  } = useContinuableEpisodes();

  const {
    subscribedCreators,
    loading: creatorSubscriptionLoading,
    refetch: creatorSubscriptionRefetch,
  } = useSubscribedCreators();

  const {
    programs: myPrograms,
    refetch: myProgramsRefetch,
    loading: myProgramsLoading,
  } = useGetMyProgramsAndEpisodes();

  const {
    programs,
    loading: programsLoading,
    refetch: programsRefetch,
  } = useMostPopularPrograms();

  const {
    events: myEvents,
    loading: myEventsLoading,
    refetch: myEventsRefetch,
  } = useMyEvents();

  const {
    streak,
    loading: streakLoading,
    refetch: streakRefetch,
  } = useEventCurrentStreak();

  const {
    users,
    usersMonthly,
    loading: usersLoading,
    refetch: usersRefetch,
  } = useUserLeaderboard();

  // We don't need to wait for creators to load to show the homepage
  const isLoading =
    programsLoading ||
    continuableEpisodesLoading ||
    myProgramsLoading ||
    creatorSubscriptionLoading ||
    userProfileLoading ||
    myEventsLoading ||
    streakLoading;

  async function handleRefresh() {
    setIsRefreshing(true);
    try {
      await Promise.all([
        programsRefetch(),
        myProgramsRefetch(),
        refetchContinuableEpisodes(),
        creatorSubscriptionRefetch(),
        userProfileRefetch(),
        myEventsRefetch(),
        streakRefetch(),
        usersRefetch(),
      ]);
    } catch (e) {
      console.log("Error refreshing homepage data", e);
    }
    setIsRefreshing(false);
  }

  useEffect(() => {
    if (userProfile && isFirstLoad) {
      if (userProfile.isFirstTimeUse) {
        setIsFirstLoad(false);
        navigation.navigate("FirstTime");
      }
    }
  }, [userProfile, userProfileLoading]);

  const refreshControl = (
    <RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} />
  );

  return (
    <View className="flex flex-col h-full bg-main">
      {!isLoading ? (
        <ScrollView
          nestedScrollEnabled
          scrollEnabled
          scrollEventThrottle={16}
          refreshControl={refreshControl}
        >
          <View className="flex flex-col justify-start mt-16 ml-2 mr-2 mb-3 md:px-14">
            {isRefreshing && (
              <View className="mb-[40px] -mt-[30px]">
                <LoadingSpinner />
              </View>
            )}
            {streak && (
              <TouchableOpacity
                onPress={() =>
                  setStreakVisible({
                    imageUrl: userProfile?.imageUrl || "",
                    currentStreak: streak.currentStreakLength,
                    longestStreak: streak.longestStreakLength,
                  })
                }
              >
                <View className="flex flex-row space-x-2 justify-end mr-4 items-center">
                  <Text className="text-[16px] text-white">Streak</Text>
                  <Image
                    className="h-[20px] w-[12px]"
                    source={require("../../assets/icons/streak.png")}
                  />
                  <Text className="text-[16px] font-extrabold text-white">
                    {streak.currentStreakLength}
                  </Text>
                </View>
              </TouchableOpacity>
            )}
            {continuableEpisodes.length > 0 && (
              <ContinueListening
                episodes={continuableEpisodes}
                minEpisodes={3}
              />
            )}
            {myEvents.length !== 0 && (
              <EventsContainer
                title="My Events"
                events={myEvents}
                minItems={3}
              />
            )}
            {myPrograms.length !== 0 && (
              <ProgramsThumbnailContainer
                title="My Programs"
                programs={myPrograms}
              />
            )}
            {subscribedCreators.length !== 0 && (
              <CreatorList
                title="My Subscriptions"
                creators={subscribedCreators}
              />
            )}
            {programs.length !== 0 && (
              <ProgramGridContainer
                title="Recommended"
                programs={programs}
                gridSize={4}
              />
            )}
            <Text className="text-xl font-unbounded text-white mt-5">
              Leaderboard
            </Text>
            {usersLoading ? (
              <LoadingSpinner />
            ) : (
              <Billboard
                users={users}
                monthlyUsers={usersMonthly}
                onUserPress={(user) =>
                  setStreakVisible({
                    imageUrl: user.imageUrl,
                    currentStreak: user.currentStreak,
                    longestStreak: user.longestStreak,
                  })
                }
              />
            )}
          </View>
        </ScrollView>
      ) : (
        <LoadingScreen />
      )}
      {!userProfile?.completedTutorial && !isLoading && (
        <View className="absolute h-full w-full bg-[#00000070]">
          <TutorialModalHome refetch={userProfileRefetch} />
        </View>
      )}
      {streakModalInfo && (
        <StreakScreen
          onCancel={() => setStreakVisible(undefined)}
          imageUrl={streakModalInfo?.imageUrl || ""}
          currentStreak={streakModalInfo?.currentStreak || 0}
          longestStreak={streakModalInfo?.longestStreak || 0}
        />
      )}
      <AudioPlayer />
    </View>
  );
}
export default Homepage;
