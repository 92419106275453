import { useState } from "react";
import { View, ScrollView, RefreshControl } from "react-native";

import CreatorList from "../components/CreatorList";
import LoadingScreen from "../components/Loading";
import LoadingSpinner from "../components/LoadingSpinner";
import ProgramsContainer from "../components/ProgramsContainer";
import { AudioPlayer } from "../components/audio-player/AudioPlayer";
import { EventsContainer } from "../components/events/EventsContainer";
import { TutorialModalDiscover } from "../components/tutorial/TutorialModalDiscover";
import { useDiscoverCreators } from "../hooks/creators/useDiscoverCreators";
import { useDiscoverEvents } from "../hooks/events/useDiscoverEvents";
import { useDiscoverPrograms } from "../hooks/programs/useDiscoverPrograms";
import { useUserProfile } from "../hooks/useUserProfile";

function Discover() {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { userProfile, refetch: userProfileRefetch } = useUserProfile();

  const {
    creators,
    loading: creatorsLoading,
    refetch: creatorsRefetch,
  } = useDiscoverCreators();

  const {
    programs,
    loading: programsLoading,
    refetch: programsRefetch,
  } = useDiscoverPrograms();

  const {
    events,
    loading: eventsLoading,
    refetch: eventsReftech,
  } = useDiscoverEvents();

  async function handleRefresh() {
    setIsRefreshing(true);
    try {
      await Promise.all([
        programsRefetch(),
        creatorsRefetch(),
        userProfileRefetch(),
        eventsReftech(),
      ]);
    } catch (e) {
      console.log("Error refreshing discover data", e);
    }
    setIsRefreshing(false);
  }

  const refreshControl = (
    <RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} />
  );

  // We don't need to wait for episodes to load to show the screen
  const isLoading = programsLoading || creatorsLoading || eventsLoading;

  return (
    <View className="h-full w-full bg-main">
      {!isLoading ? (
        <View className="flex flex-1 h-full">
          <ScrollView
            nestedScrollEnabled
            scrollEventThrottle={16}
            refreshControl={refreshControl}
          >
            <View className="flex flex-col justify-center h-full mx-3 pt-16 md:px-14">
              {isRefreshing && (
                <View className="mb-[20px] -mt-[20px]">
                  <LoadingSpinner />
                </View>
              )}
              {creators.length !== 0 && (
                <CreatorList title="Creators" creators={creators} />
              )}
              {programs.length !== 0 && (
                <ProgramsContainer
                  title="Programs"
                  programs={programs}
                  minPrograms={5}
                />
              )}
              {events.length !== 0 && (
                <EventsContainer title="Events" events={events} minItems={5} />
              )}
            </View>
          </ScrollView>
        </View>
      ) : (
        <LoadingScreen />
      )}
      {!userProfile?.completedTutorial && (
        <View className="absolute h-full w-full bg-[#00000070]">
          <TutorialModalDiscover refetch={userProfileRefetch} />
        </View>
      )}
      <AudioPlayer />
    </View>
  );
}
export default Discover;
