import { ApolloClient, InMemoryCache } from "@apollo/client";

import { envConfig } from "../../env.config";
import { store } from "../redux/Store";

const { backendUrl } = envConfig;

export function getApiClient() {
  const jwt = store.getState().jwt;

  if (!jwt) {
    console.warn("Event.PlaybackProgressUpdated jwt is null");
    throw new Error("Event.PlaybackProgressUpdated jwt is null");
  }

  return new ApolloClient({
    uri: `${backendUrl}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      authorization: `Bearer ${jwt}`,
    },
  });
}
