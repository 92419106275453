import { envConfig } from "../../env.config";
import { useNotificationContext } from "../contexts/NotificationContext";
import { deleteUser, saveUser } from "../redux/actions/UserActions";

const { backendUrl } = envConfig;

const googleUrl = `${backendUrl}/api/auth/google/callback`;
const appleUrl = `${backendUrl}/api/auth/apple/callback`;
const localUrl = `${backendUrl}/api/auth/local`;

export function useLogin() {
  const { refetch: refetchNotifications } = useNotificationContext();

  function logout() {
    deleteUser();
    return true;
  }

  // TODO: add error handling
  async function login(user: { identifier: string; password: string }) {
    const requestConfig = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: user.identifier,
        password: user.password,
      }),
    };
    try {
      const response = await fetch(localUrl, requestConfig);
      const json = await response.json();
      if (json.error) {
        throw new Error(json.error.message);
      }
      saveUser(json.jwt, json.user);
      await refetchNotifications();
      return true;
    } catch (err) {
      throw new Error(err.message);
    }
  }

  // TODO: add error handling
  async function googleLogin(access_token: string) {
    const requestConfig = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${googleUrl}?access_token=${access_token}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        alert(JSON.stringify(json.error.message));
        throw new Error(json.error);
      }
      saveUser(json.jwt, json.user);
      await refetchNotifications();
      return true;
    } catch (err) {
      console.error(JSON.parse(err.message));
      throw new Error("Unable to login with google.");
    }
  }

  async function appleLogin(access_token: string) {
    const requestConfig = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${appleUrl}?access_token=${access_token}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        console.error(json.error);
        const err = JSON.stringify(json.error.message);
        throw new Error(err);
      }
      saveUser(json.jwt, json.user);
      await refetchNotifications();
      return true;
    } catch (err) {
      console.error(err);
      alert(err.message);
      throw new Error("Unable to login with apple.");
    }
  }

  return {
    logout,
    googleLogin,
    appleLogin,
    login,
  };
}
