// Copied from "react-native-track-player" to avoid importing the whole library for web as it breaks the build & runtime
export enum Capability {
  Play,
  PlayFromId,
  PlayFromSearch,
  Pause,
  Stop,
  SeekTo,
  Skip,
  SkipToNext,
  SkipToPrevious,
  JumpForward,
  JumpBackward,
  SetRating,
  Like,
  Dislike,
  Bookmark,
}
