import { FC, PropsWithChildren, createContext, useContext } from "react";
import { useSelector } from "react-redux";

import ChallengeWelcomeScreen from "../components/notifications/ChallengeWelcomeScreen";
import Invite from "../components/notifications/Invite";
import {
  NotificationSource,
  useMarkNotificationAsReadMutation,
  useMyUnreadNotificationsQuery,
} from "../graphql/generated";
import { RootState } from "../redux/Store";

type NotificationCtx = {
  refetch: () => void;
};

const NotificationContext = createContext<NotificationCtx | null>(null);

export const NotificationProvider: FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const userSession = useSelector<RootState, string | null>(
    (state) => state.jwt
  );

  const [markNotificationAsRead] = useMarkNotificationAsReadMutation();

  const { data, refetch } = useMyUnreadNotificationsQuery({
    skip: !userSession,
    pollInterval: 1000 * 30, // 30 seconds
  });

  const invitationNotification = data?.myUnreadNotifications?.find(
    (n) => n?.source === NotificationSource.Invitation
  );

  const challengeNotification = data?.myUnreadNotifications?.find(
    (n) => n?.source === NotificationSource.Challenge
  );

  async function handleNotificationRead(notificationUUID: string) {
    await markNotificationAsRead({
      variables: {
        notificationUUID,
      },
    });

    await refetch();
  }

  const context: NotificationCtx = {
    refetch,
  };

  return (
    <NotificationContext.Provider value={context}>
      {invitationNotification && (
        <Invite
          invitation={invitationNotification.invitation ?? undefined}
          onSubmit={() => handleNotificationRead(invitationNotification.uuid)}
        />
      )}
      {challengeNotification && (
        <ChallengeWelcomeScreen
          challenge={challengeNotification.challenge ?? undefined}
          onSubmit={() => handleNotificationRead(challengeNotification.uuid)}
        />
      )}
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotificationContext() {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotificationContext must be used within a NotificationProvider"
    );
  }
  return context;
}
