import { useForgotPasswordMobileMutation } from "../../graphql/generated";

type ForgotPasswordProps = {
  email: string;
};

export function useForgotPassword({ email }: ForgotPasswordProps) {
  const [forgotPassword] = useForgotPasswordMobileMutation({
    variables: { email },
  });
  return {
    forgotPassword,
  };
}
