import { AVPlaybackStatus } from "expo-av";
import { useEffect, useState } from "react";
import type {
  Progress,
  useProgress as libUseProgress,
} from "react-native-track-player";

import { WebTrackPlayer } from "./WebTrackPlayer";

const emptyState = {
  position: 0,
  buffered: 0,
  duration: 0,
};

export function getProgressHook(
  webTrackPlayer: WebTrackPlayer
): typeof libUseProgress {
  const useProgress: typeof libUseProgress = (): Progress => {
    const [progress, setProgress] = useState(emptyState);

    useEffect(() => {
      function handler(status: AVPlaybackStatus) {
        if (status?.isLoaded) {
          const position = status.positionMillis / 1000;
          const buffered = (status.playableDurationMillis || 0) / 1000;
          const duration = (status.durationMillis || 0) / 1000;

          // Less updates, less re-renders
          setProgress((prevState) => {
            if (
              prevState.position === position &&
              prevState.buffered === buffered &&
              prevState.duration === duration
            ) {
              return prevState;
            }

            return {
              position,
              buffered,
              duration,
            };
          });
        } else {
          setProgress(emptyState);
        }
      }

      webTrackPlayer.registerPorgressListener(handler);

      return () => {
        webTrackPlayer.deregisterPorgressListener(handler);
      };
    }, []);

    return progress;
  };

  return useProgress;
}
