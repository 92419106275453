import React from "react";
import { Linking, Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

function TermsAndPrivacy() {
  async function openPrivacyPolicy() {
    await Linking.openURL("https://peak.audio/privacy");
  }

  async function openTermsOfService() {
    await Linking.openURL("https://peak.audio/terms");
  }

  return (
    <View className="flex flex-col justify-center mt-6">
      <Text className="text-white text-xs text-center">
        By using Peak.audio, you agree to our
      </Text>
      <TouchableOpacity onPress={openTermsOfService} className="w-fit h-fit">
        <Text
          className="text-white text-xs text-center"
          style={{ textDecorationLine: "underline" }}
        >
          Terms of Service
        </Text>
      </TouchableOpacity>
      <Text className="text-xs text-white mt-3 text-center">
        Learn how we process your data.
      </Text>
      <TouchableOpacity className="w-fit h-fit" onPress={openPrivacyPolicy}>
        <Text
          className="text-white text-xs self-center"
          style={{ textDecorationLine: "underline" }}
        >
          Privacy Policy
        </Text>
      </TouchableOpacity>
    </View>
  );
}
export default TermsAndPrivacy;
