import * as FileSystem from "expo-file-system";

import { envConfig } from "../../env.config";
import { store } from "../redux/Store";

const { backendUrl } = envConfig;

export type StrapiUploadProps = {
  resource_uri: string;
};

export function useStrapiUpload() {
  async function uploadToStrapi({ resource_uri }: StrapiUploadProps) {
    try {
      const uploading = await FileSystem.uploadAsync(
        `${backendUrl}/api/upload`,
        `${resource_uri}`,

        {
          uploadType: FileSystem.FileSystemUploadType.MULTIPART,
          headers: {
            Authorization: `Bearer ${store.getState().jwt}`,
            "Content-Type": "application/json",
          },
          fieldName: "files",
        }
      ).catch((err) =>
        console.log("File Upload Err: ", JSON.stringify(err, null, 2))
      );
      //returns server response
      return uploading;
    } catch (error) {
      console.log("File Upload Err:", error);
      return error;
    }
  }

  return {
    uploadToStrapi,
  };
}
