import { View } from "react-native";
import { Text } from "react-native-paper";

import ProgramThumbnail from "./ProgramThumbnail";

type ProgramGridContainerItem = {
  uuid: string;
  title: string;
  imageUrl: string;
  isAccessible: boolean;
  episodesCount: number;
  programProductId: string | undefined;
  isNew: boolean;
  isFree: boolean;
  priceInCents: number | null;
};

type ProgramGridContainerProps = {
  programs: ProgramGridContainerItem[];
  title?: string;
  gridSize: number;
};

function ProgramGridContainer({
  programs,
  title,
  gridSize,
}: ProgramGridContainerProps) {
  const usablePrograms = gridSize ? programs.slice(0, gridSize) : programs;
  return (
    <View className="flex flex-col justify-start">
      {title && (
        <View className="flex flex-row w-full justify-between ml-1">
          <Text className="text-xl font-unbounded text-white mb-3">
            {title}
          </Text>
        </View>
      )}
      <View className="flex flex-row flex-wrap">
        {usablePrograms.map((program) => (
          <View
            className="p-1 w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6 md:max-w-xs"
            key={program.uuid}
          >
            <ProgramThumbnail program={program} shouldExpand />
          </View>
        ))}
      </View>
    </View>
  );
}
export default ProgramGridContainer;
