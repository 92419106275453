import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { View, ScrollView, Platform } from "react-native";
import { IconButton, Button, Text } from "react-native-paper";
import { PurchasesStoreProduct } from "react-native-purchases";

import LoadingScreen from "../components/Loading";
import { AudioPlayer } from "../components/audio-player/AudioPlayer";
import { OneTimeProductRow } from "../components/purchase/OneTimeProductRow";
import { SubscriptionProductRow } from "../components/purchase/SubscriptionProductRow";
import { useStorePurchase } from "../contexts/StorePurchasesContext";
import {
  useInitializeProgramPurchaseMutation,
  useInitializeSubscriptionPurchaseMutation,
} from "../graphql/generated";
import { useStoreProducts } from "../hooks/useStoreProducts";
import {
  PurchaseScreenProps,
  ScreenNavigationProps,
} from "../navigation/RootStackParamList";
import { goBack } from "../navigation/goBack";
import { ErrorReportingService } from "../services/ErrorReportingService";
import { notEmpty } from "../utils/not-empty";

enum WebProductType {
  OneTime = "OneTime",
  Subscription = "Subscription",
}

function centsToPriceString(cents: number) {
  return `$ ${cents / 100}`;
}

function Purchase({
  route: {
    params: {
      programProductId,
      creatorSubscriptionProductId,
      creatorUUID,
      programUUID,
      programPriceInCentsUsd,
      subscriptionPriceInCentsUsd,
    },
  },
}: PurchaseScreenProps) {
  const navigation = useNavigation<ScreenNavigationProps>();

  const { purchaseProduct } = useStorePurchase();

  const productIds = [programProductId, creatorSubscriptionProductId].filter(
    notEmpty
  );

  const { products, loading: productsLoading } = useStoreProducts(productIds);

  const [purchaseInProgress, setPurchaseInProgress] = useState(false);
  const [initializeProgramPurchase, { loading: webProgramPurchaseLoading }] =
    useInitializeProgramPurchaseMutation();

  const [
    initializeSubscriptionPurchase,
    { loading: webSubscriptionPurchaseLoading },
  ] = useInitializeSubscriptionPurchaseMutation();

  const [selectedProduct, setSelectedProduct] =
    useState<PurchasesStoreProduct>();

  const [selectedWebProduct, setSelectedWebProduct] =
    useState<WebProductType>();

  async function purchaseStoreProduct(product: PurchasesStoreProduct) {
    if (purchaseInProgress) return;

    try {
      setPurchaseInProgress(true);
      await purchaseProduct(product);
      navigation.navigate("SuccessfulTransaction", {
        creatorUUID,
      });
    } catch (err) {
      console.log(
        `purchaseStoreProduct failed with error: ${err?.message}`,
        err
      );
      navigation.navigate("FailedTransaction", {
        creatorUUID,
      });
    } finally {
      setPurchaseInProgress(false);
    }
  }

  function handleContinueToPayment() {
    if (Platform.OS === "web") {
      return handleWebContinueToPayment();
    } else {
      return handleNativeContinueToPayment();
    }
  }

  async function handleWebContinueToPayment() {
    if (selectedWebProduct === WebProductType.OneTime) {
      if (!programUUID) {
        alert("Program UUID not found");
        return;
      }
      try {
        const res = await initializeProgramPurchase({
          variables: {
            programUUID,
            successUrl: `${window.location.origin}/successful-transaction?creatorUUID=${creatorUUID}`,
            cancelUrl: `${window.location.origin}/failed-transaction?creatorUUID=${creatorUUID}`,
          },
        });

        const paymentUrl = res.data?.initializeProgramPurchase?.paymentUrl;
        if (!paymentUrl) {
          alert("Error initializing program purchase, try again later");
          return;
        }

        window.location.href = paymentUrl;
      } catch (e) {
        alert("Error initializing program purchase, try again later");
        ErrorReportingService.report(e);
      }
    } else if (selectedWebProduct === WebProductType.Subscription) {
      try {
        const res = await initializeSubscriptionPurchase({
          variables: {
            creatorUUID,
            successUrl: `${window.location.origin}/successful-transaction?creatorUUID=${creatorUUID}`,
            cancelUrl: `${window.location.origin}/failed-transaction?creatorUUID=${creatorUUID}`,
          },
        });

        const paymentUrl = res.data?.initializeSubscriptionPurchase?.paymentUrl;
        if (!paymentUrl) {
          alert("Error initializing subscription purchase, try again later");
          return;
        }

        window.location.href = paymentUrl;
      } catch (e) {
        alert("Error initializing subscription purchase, try again later");
        ErrorReportingService.report(e);
      }
    } else {
      alert("Please select a product");
    }
  }

  function handleNativeContinueToPayment() {
    if (!selectedProduct) {
      alert("Please select a product");
      return;
    }

    return purchaseStoreProduct(selectedProduct);
  }

  const subscriptionProduct = products.find(
    (product) => product.identifier === creatorSubscriptionProductId
  );

  const oneTimeProduct = products.find(
    (product) => product.identifier === programProductId
  );

  useEffect(() => {
    if (Platform.OS === "web") {
      // set web product as selected
      if (programUUID && programPriceInCentsUsd !== null) {
        setSelectedWebProduct(WebProductType.OneTime);
      } else if (creatorUUID && subscriptionPriceInCentsUsd !== null) {
        setSelectedWebProduct(WebProductType.Subscription);
      }
      return;
    }

    if (oneTimeProduct) {
      setSelectedProduct(oneTimeProduct);
    } else {
      if (subscriptionProduct) {
        setSelectedProduct(subscriptionProduct);
      }
    }
  }, [oneTimeProduct, subscriptionProduct]);

  const loading =
    productsLoading ||
    purchaseInProgress ||
    webProgramPurchaseLoading ||
    webSubscriptionPurchaseLoading;

  return (
    <View className="flex flex-col justify-top h-full w-full bg-main">
      <ScrollView className="flex flex-col h-full space-y-[20px] ml-[5%]">
        <IconButton
          iconColor="black"
          className="mt-[80px] bg-[#80EFAD]"
          onPress={() => goBack(navigation)}
          icon="arrow-left"
        />
        <Text className="text-sm text-[#C4C6D4] w-[95%]">
          You can purchase this program one-time or subscribe to this creator
          and access their full catalog, including future content
        </Text>
        <Text className="text-sm text-white">Select purchase method</Text>
        <View className="flex flex-col space-y-[10px]">
          {productsLoading && <LoadingScreen />}
          {oneTimeProduct && (
            <OneTimeProductRow
              onPress={() => setSelectedProduct(oneTimeProduct)}
              priceString={oneTimeProduct.priceString}
              selected={selectedProduct === oneTimeProduct}
            />
          )}
          {subscriptionProduct && (
            <SubscriptionProductRow
              priceString={subscriptionProduct.priceString}
              selected={selectedProduct === subscriptionProduct}
              onPress={() => setSelectedProduct(subscriptionProduct)}
            />
          )}
          {Platform.OS === "web" && (
            <>
              {programUUID && programPriceInCentsUsd !== null && (
                <OneTimeProductRow
                  onPress={() => setSelectedWebProduct(WebProductType.OneTime)}
                  priceString={centsToPriceString(programPriceInCentsUsd)}
                  selected={selectedWebProduct === WebProductType.OneTime}
                />
              )}
              {creatorUUID && subscriptionPriceInCentsUsd !== null && (
                <SubscriptionProductRow
                  priceString={centsToPriceString(subscriptionPriceInCentsUsd)}
                  selected={selectedWebProduct === WebProductType.Subscription}
                  onPress={() =>
                    setSelectedWebProduct(WebProductType.Subscription)
                  }
                />
              )}
            </>
          )}
        </View>
        <Button
          onPress={handleContinueToPayment}
          disabled={(!selectedProduct && !selectedWebProduct) || loading}
          className="bg-[#80EFAD] border-green-300 border-[1px] w-[95%] rounded-xl"
        >
          {!loading && (
            <Text className="font-unbounded text-black self-center">
              Continue to Payment
            </Text>
          )}
          {loading && (
            <Text className="font-unbounded text-black self-center">
              Loading...
            </Text>
          )}
        </Button>
      </ScrollView>
      <AudioPlayer />
    </View>
  );
}

export default Purchase;
