import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import { View, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";

import { useStoreProducts } from "../hooks/useStoreProducts";
import { CachedImageBackground } from "../lib/CachedImageBackground/CachedImageBackground";
import { ScreenNavigationProps } from "../navigation/RootStackParamList";
import { notEmpty } from "../utils/not-empty";

type ProgramsThumbnailContainerItem = {
  uuid: string;
  title: string;
  imageUrl: string;
  isAccessible: boolean;
  episodesCount: number;
  programProductId: string | undefined;
  isNew: boolean;
  isFree: boolean;
  priceInCents: number | null;
};

type ProgramsThumbnailContainerProps = {
  program: ProgramsThumbnailContainerItem;
  shouldExpand?: boolean;
};

function ProgramThumbnail({
  program,
  shouldExpand,
}: ProgramsThumbnailContainerProps) {
  const navigation = useNavigation<ScreenNavigationProps>();

  function onProgramPress(program: ProgramsThumbnailContainerItem) {
    navigation.navigate("ProgramPreview", {
      programUUID: program.uuid,
    });
  }

  const productIds = [program.programProductId].filter(notEmpty);

  const { products, getPlatformPriceString } = useStoreProducts(productIds);

  const oneTimeProduct = products.find(
    (product) => product.identifier === program.programProductId
  );

  const priceUsd = getPlatformPriceString(oneTimeProduct, program.priceInCents);

  return (
    <TouchableOpacity
      activeOpacity={0.75}
      onPress={() => onProgramPress(program)}
      key={program.uuid}
      className="rounded-xl overflow-hidden relative"
    >
      <CachedImageBackground
        className={shouldExpand ? "aspect-square" : "w-[165px] h-[165px]"}
        cachePolicy="memory-disk"
        contentFit="cover"
        source={program.imageUrl}
      >
        <LinearGradient
          colors={["rgba(0,0,0,0.0)", "rgba(0,0,0,0.5)", "rgba(0,0,0,1)"]}
          style={{
            flex: 1,
            justifyContent: "flex-end",
            paddingBottom: 15,
          }}
        >
          <View className="w-[90%] ml-[5%] flex flex-col justify-end space-y-2">
            {program.isNew && (
              <View className=" bg-[#80EFAD] h-[22px] px-1 w-[40px] flex flex-col justify-center rounded-lg">
                <Text className="text-black text-center font-bold text-xs">
                  NEW
                </Text>
              </View>
            )}
            <Text
              numberOfLines={2}
              className="text-sm font-unbounded text-white self-start"
            >
              {program.title}
            </Text>
            <View className="flex flex-row justify-start space-x-2">
              {!program.isFree && !program.isAccessible && (
                <Text
                  numberOfLines={2}
                  className="text-xs font-unbounded text-white self-start"
                >
                  {priceUsd}
                </Text>
              )}
              {program.isAccessible && (
                <Text
                  numberOfLines={2}
                  className="text-xs font-unbounded text-[#FFFFFF] self-start"
                >
                  Owned
                </Text>
              )}
              {program.isFree && !program.isAccessible && (
                <View className="bg-[#E3A326] rounded-sm px-1">
                  <Text
                    numberOfLines={2}
                    className="text-xs font-inter font-bold text-black self-start"
                  >
                    FREE
                  </Text>
                </View>
              )}
              <Text
                numberOfLines={2}
                className="text-xs font-unbounded text-white self-start text-[#FFFFFF75]"
              >
                |
              </Text>
              <Text
                numberOfLines={2}
                className="text-xs font-unbounded text-white self-start text-[#FFFFFF75]"
              >
                {program.episodesCount} EP
              </Text>
            </View>
          </View>
        </LinearGradient>
      </CachedImageBackground>
    </TouchableOpacity>
  );
}
export default ProgramThumbnail;
