import { useEventForUserQuery } from "../../graphql/generated";
import { getImagePreviewUrlMedium } from "../../utils/image-preview-url";
import { notEmpty } from "../../utils/not-empty";
import { ProgramPreviewEpisode } from "../programs/useProgramPreview";
import { useEpisodesProgressions } from "../useEpisodesProgressions";

export function useEventPreview(eventUUID: string) {
  const { data, loading, refetch } = useEventForUserQuery({
    variables: {
      eventUUID,
    },
    skip: !eventUUID,
  });

  const event = data?.eventForUser;

  const episodesWithoutProgression: ProgramPreviewEpisode[] =
    event?.episodes?.filter(notEmpty)?.map((episode) => ({
      uuid: episode.uuid,
      trackUrl: episode.media?.url ?? "",
      trackMime: episode.media?.mime ?? "",
      title: episode.title,
      imageUrlMedium: episode.image
        ? getImagePreviewUrlMedium(episode.image)
        : "",
      progressInSeconds: 0,
      programUUID: episode.uuid,
      progressPercentage: 0,
      episodeNumber: episode.episodeNumber,
      imageUrl: episode.image ? getImagePreviewUrlMedium(episode.image) : "",
      streams: 0,
      description: episode.description,
      lengthInSeconds: episode.lengthInSeconds,
    })) ?? [];

  const { episodes } = useEpisodesProgressions(episodesWithoutProgression);

  return {
    event,
    episodes,
    loading,
    refetch,
  };
}
