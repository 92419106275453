import {
  useMyProgressionsForEpisodesQuery,
  useProgramPreviewQuery,
} from "../../graphql/generated";
import {
  getImagePreviewUrlMedium,
  getImagePreviewUrlThumbnail,
} from "../../utils/image-preview-url";
import { notEmpty } from "../../utils/not-empty";

export type ProgramEpisode = {
  uuid: string;
  title: string;
  description: string;
  trackUrl: string;
  trackMime: string;
  imageUrl: string;
  imageUrlMedium: string;
  lengthInSeconds: number;
  episodeNumber: number;
  streams: number;
  progressPercentage: number;
  progressInSeconds: number;
  programUUID: string;
};

export function useProgramEpisodes() {
  const { refetch: fetchProgram } = useProgramPreviewQuery({
    fetchPolicy: "cache-and-network",
    skip: true,
  });

  const { refetch: fetchProgressions } = useMyProgressionsForEpisodesQuery({
    skip: true,
  });

  async function fetch(programUUID: string): Promise<ProgramEpisode[]> {
    const res = await fetchProgram({
      programUUID,
    });

    const episodesWithoutProgression: ProgramEpisode[] = (
      res.data.programPreview.episodes || []
    )
      .filter(notEmpty)
      .map((episode) => ({
        uuid: episode.uuid,
        title: episode.title,
        description: episode.description || "",
        trackUrl: episode.media?.url || "",
        trackMime: episode.media?.mime || "",
        imageUrl: episode.image
          ? getImagePreviewUrlThumbnail(episode.image)
          : "",
        imageUrlMedium: episode.image
          ? getImagePreviewUrlMedium(episode.image)
          : "",
        lengthInSeconds: episode.lengthInSeconds || 0,
        episodeNumber: episode.episodeNumber || 0,
        streams: episode.streams || 0,
        programUUID,
        progressPercentage: 0,
        progressInSeconds: 0,
      }));

    const episodeUUIDs = episodesWithoutProgression.map(
      (episode) => episode.uuid
    );

    const { data: progressionsData } = await fetchProgressions({
      episodeUUIDs,
    });

    const progressions =
      progressionsData?.myProgressionsForEpisodes
        ?.filter(notEmpty)
        .map((row) => ({
          uuid: row.episodeUUID,
          progressPercentage: row.progressPercentage,
          progressInSeconds: row.progressInSeconds,
        })) || [];

    const episodesWithProgressions = episodesWithoutProgression.map(
      (episode) => {
        const progression = progressions.find(
          (progression) => progression.uuid === episode.uuid
        );
        if (progression) {
          return {
            ...episode,
            progressPercentage: progression.progressPercentage,
            progressInSeconds: progression.progressInSeconds,
          };
        } else {
          return episode;
        }
      }
    );

    return episodesWithProgressions;
  }

  return {
    fetch,
  };
}
