import { useEffect } from "react";

import { usePreviousValue } from "./usePreviousValue";
import { useMyProgressionsForEpisodesQuery } from "../graphql/generated";
import { useMyPlayer } from "../providers/FullscreenPlayerProvider";
import { notEmpty } from "../utils/not-empty";

export function useEpisodesProgressions<T extends { uuid: string }>(
  episodes: T[]
) {
  const { currentTrack } = useMyPlayer();

  const previousTrack = usePreviousValue(currentTrack);

  const episodeUUIDs = episodes.map((episode) => episode.uuid);

  const { data, refetch } = useMyProgressionsForEpisodesQuery({
    variables: {
      episodeUUIDs,
    },
  });

  useEffect(() => {
    const previousEpisodeUUID = previousTrack?.episodeUUID;
    if (!previousEpisodeUUID) {
      return;
    }

    if (episodes.find((episode) => episode.uuid === previousEpisodeUUID)) {
      void refetch();
    }
  }, [currentTrack]);

  const progressions =
    data?.myProgressionsForEpisodes?.filter(notEmpty).map((row) => ({
      uuid: row.episodeUUID,
      progressPercentage: row.progressPercentage,
      progressInSeconds: row.progressInSeconds,
    })) || [];

  const episodesWithProgressions = episodes.map((episode) => {
    const progression = progressions.find(
      (progression) => progression.uuid === episode.uuid
    );
    if (progression) {
      return {
        ...episode,
        progressPercentage: progression.progressPercentage,
        progressInSeconds: progression.progressInSeconds,
      };
    } else {
      return episode;
    }
  });

  return {
    episodes: episodesWithProgressions,
  };
}
