import { useState } from "react";
import { View, Text } from "react-native";
import { Button } from "react-native-paper";

import { useProgramEpisodes } from "../../hooks/episodes/useProgramEpisodes";
import { ContinuableEpisode } from "../../hooks/useContinuableEpisodes";
import TrackPlayer from "../../lib/TrackPlayerWrapper/TrackPlayerWrapper";
import { useMyPlayer } from "../../providers/FullscreenPlayerProvider";
import { SquareEpisode } from "../episode/SquareEpisode";

type ContinueListeningProps = {
  episodes: ContinuableEpisode[];
  minEpisodes: number;
};

function ContinueListening({ episodes, minEpisodes }: ContinueListeningProps) {
  const { fetch: fetchProgramEpisodes } = useProgramEpisodes();
  const { playPlaylist, isPlaying } = useMyPlayer();

  async function onPress(episode: ContinuableEpisode) {
    if (isPlaying) {
      await TrackPlayer.pause();
      return;
    }
    const programEpisodes = await fetchProgramEpisodes(episode.programUUID);
    const index = programEpisodes.findIndex((e) => e.uuid === episode.uuid);
    return playPlaylist(programEpisodes, index);
  }

  const [isMinimized, setIsMinimized] = useState(true);

  const viewableEpisodes = isMinimized
    ? episodes.slice(0, minEpisodes)
    : episodes;

  return (
    <View className="flex flex-col justify-center w-full mb-[30px] max-w-2xl">
      <View className="flex flex-row w-full justify-between">
        <Text className="text-xl font-unbounded text-white mb-3">
          Continue Listening
        </Text>
        {episodes.length >= minEpisodes && (
          <View>
            {isMinimized ? (
              <Button
                onPress={() => setIsMinimized(false)}
                className="bg-transparent rounded-none flex flex-col justify-center"
              >
                <Text className="font-unbounded self-center text-green-300 h-full">
                  View All
                </Text>
              </Button>
            ) : (
              <Button
                onPress={() => setIsMinimized(true)}
                className="bg-transparent rounded-none flex flex-col justify-center"
              >
                <Text className="font-unbounded self-center text-green-300 h-full">
                  Minimize
                </Text>
              </Button>
            )}
          </View>
        )}
      </View>
      {viewableEpisodes.map((episode) => (
        <SquareEpisode
          key={episode.uuid}
          episode={episode}
          onPress={() => onPress(episode)}
        />
      ))}
    </View>
  );
}

export default ContinueListening;
