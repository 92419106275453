import { Image } from "expo-image";
import { useEffect, useMemo } from "react";

import { useEventsDiscoverQuery } from "../../graphql/generated";
import { getImagePreviewUrlThumbnail } from "../../utils/image-preview-url";
import { notEmpty } from "../../utils/not-empty";

export function useDiscoverEvents() {
  const { data, loading, error, refetch } = useEventsDiscoverQuery();

  const events = useMemo(
    () =>
      data?.eventsDiscover?.filter(notEmpty).map((row) => ({
        uuid: row.uuid,
        title: row.title,
        imageUrl: row.image ? getImagePreviewUrlThumbnail(row.image) : "",
        status: row.status,
        progressPercentage: row.progressPercentage,
      })) || [],
    [data]
  );

  useEffect(() => {
    Image.prefetch(events.map((r) => r.imageUrl));
  }, [events]);

  return {
    events,
    loading,
    error,
    refetch,
  };
}
