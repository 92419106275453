import { Image } from "expo-image";
import React, { Dispatch, SetStateAction } from "react";
import { View, Text, Linking } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import { useStorePurchase } from "../contexts/StorePurchasesContext";
import { useStripeCustomerPortalQuery } from "../graphql/generated";

type SubscriptionManageButtonProps = {
  creatorUUID: string;
  creatorName: string;
  creatorAvatarUrl: string;
  isFreeSubscription: boolean;
  setShowConfirmUnsubscribeModal: Dispatch<SetStateAction<undefined | string>>;
};

function SubscriptionManageButton({
  creatorUUID,
  isFreeSubscription,
  creatorName,
  creatorAvatarUrl,
  setShowConfirmUnsubscribeModal,
}: SubscriptionManageButtonProps) {
  const { managementUrl } = useStorePurchase();
  const { data: customerPortalRes } = useStripeCustomerPortalQuery({
    variables: {
      creatorUUID,
      returnUrl: window?.location?.href || "https://peak.audio",
    },
  });

  const customerPortalManagementUrl = customerPortalRes?.stripeCustomerPortal;

  function onPress() {
    if (isFreeSubscription) {
      setShowConfirmUnsubscribeModal(creatorUUID);
    } else {
      if (customerPortalManagementUrl) {
        void Linking.openURL(customerPortalManagementUrl);
      } else if (managementUrl) {
        void Linking.openURL(managementUrl);
      } else {
        alert("No subscription management URL found");
      }
    }
  }

  return (
    <View className="w-full flex flex-row justify-between">
      <View className="w-[65%] flex flex-row justify-start space-x-2">
        <View className="h-full flex-col justify-center">
          <Image
            style={{ width: 60, height: 60 }}
            className="rounded-full bg-main"
            source={{
              uri: creatorAvatarUrl,
            }}
            cachePolicy="memory-disk"
          />
        </View>
        <Text
          numberOfLines={1}
          className="self-center w-[50%] font-inter text-sm text-[#FFFFFF]"
        >{`@${creatorName}`}</Text>
      </View>
      <TouchableOpacity
        onPress={onPress}
        className="self-center w-[90%] mr-[15px] flex flex-col justify-center h-full"
      >
        <Text
          numberOfLines={1}
          className="self-center font-unbounded text-xs text-[#FFFFFF80] "
        >
          {isFreeSubscription ? `Unsubscribe` : `Manage`}
        </Text>
      </TouchableOpacity>
    </View>
  );
}

export default SubscriptionManageButton;
