import { Dispatch, SetStateAction } from "react";
import { Text, View } from "react-native";

import PdfPreview from "./PdfPreview";
import { ProgramPreviewDocument } from "../hooks/programs/useProgramPreview";

type DocumentsProps = {
  documents: ProgramPreviewDocument[];
  shouldShowPurchase: boolean;
  setShowPdfViewerModal: Dispatch<SetStateAction<undefined | string>>;
};

function Documents({
  documents,
  shouldShowPurchase,
  setShowPdfViewerModal,
}: DocumentsProps) {
  const noDocuments = documents.length === 0;
  return (
    <View className="w-full">
      {!shouldShowPurchase &&
        documents.map((doc) => (
          <PdfPreview
            key={doc.uuid}
            title={doc.title}
            description={doc.description}
            type="PDF"
            src={doc.fileUrl}
            setShowPdfViewerModal={setShowPdfViewerModal}
          />
        ))}
      {shouldShowPurchase && (
        <View className="h-full w-[90%] ml-[5%] flex flex-row justify-center">
          <Text className="text-[#FFFFFF50] mt-[100px] text-sm font-unbounded text-center">
            Purchase this program to access all of its materials!
          </Text>
        </View>
      )}
      {!shouldShowPurchase && noDocuments && (
        <View className="h-full w-[90%] ml-[5%] flex flex-row justify-center">
          <Text className="text-[#FFFFFF50] mt-[100px] text-sm font-unbounded text-center">
            There are no documents associated with this program.
          </Text>
        </View>
      )}
    </View>
  );
}
export default Documents;
