import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import { RootStackParamLoginList } from "./RootStackParamLoginList";
import Prelogin from "../screens/Prelogin";
import RequestReset from "../screens/RequestReset";
import ResetPassword from "../screens/ResetPassword";
import SignIn from "../screens/SignIn";
import Signup from "../screens/Signup";

export const Tab = createBottomTabNavigator<RootStackParamLoginList>();

function PreloginNav() {
  return (
    <Tab.Navigator
      backBehavior="history"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Tab.Screen
        options={{
          tabBarButton: () => null,
          tabBarStyle: { display: "none" },
        }}
        name="Prelogin"
        component={Prelogin}
      />
      <Tab.Screen
        options={{
          tabBarButton: () => null,
          tabBarStyle: { display: "none" },
        }}
        name="SignIn"
        component={SignIn}
      />
      <Tab.Screen
        options={{
          tabBarStyle: { display: "none" },
          tabBarButton: () => null,
        }}
        name="Signup"
        component={Signup}
      />
      <Tab.Screen
        options={{
          tabBarStyle: { display: "none" },
          tabBarButton: () => null,
        }}
        name="ResetPassword"
        component={ResetPassword}
      />
      <Tab.Screen
        options={{
          tabBarStyle: { display: "none" },
          tabBarButton: () => null,
        }}
        name="RequestReset"
        component={RequestReset}
      />
    </Tab.Navigator>
  );
}

export default PreloginNav;
