import { is } from "date-fns/locale";
import { Platform } from "react-native";

import { getApiClient } from "./ApiClientService";
import {
  AppType,
  UpdateMyProgressionByEpisodeUuidDocument,
} from "../graphql/generated";
import TrackPlayer, {
  PlaybackProgressUpdatedEvent,
} from "../lib/TrackPlayerWrapper/TrackPlayerWrapper";
import { MyTrack } from "../providers/FullscreenPlayerProvider";

type UpdateRequestState = {
  episodeUUID: string;
  progressInSeconds: number;
};

// eslint-disable-next-line functional/no-let
let previousUpdateRequest: UpdateRequestState | null = null;

export async function updateTrackProgress(event: PlaybackProgressUpdatedEvent) {
  const progressInSeconds = Math.floor(event.position);
  const duration = Math.floor(event.duration) - 1; // -1 to handle corner cases on track end. Progress interval fetching is 1s.

  if (progressInSeconds === 0) {
    return;
  }

  // Progress is saved every 5 seconds
  // For the last one/two seconds, the progress is saved every second to make sure we cover 100% completion corner case
  if (progressInSeconds % 5 !== 0 && progressInSeconds < duration - 7) {
    return;
  }

  const track = (await TrackPlayer.getTrack(event.track)) as MyTrack | null;

  if (!track) {
    console.warn("Event.PlaybackProgressUpdated track is null", event, track);
    return;
  }

  const episodeUUID = track?.episodeUUID;

  if (!episodeUUID) {
    return;
  }

  const isNotComplete = progressInSeconds < duration - 7; // Boolean check to see whether we have reached seven second completion margin

  const unsafeProgress = Math.ceil((progressInSeconds / duration) * 100); // Ceil to cover corner cases on track end
  const progressPercentage = isNotComplete ? Math.min(unsafeProgress, 100) : 0; //set percent progress to zero if track is within 7 second margin

  const progressInSecondsFinal = isNotComplete ? progressInSeconds : 0; // set progress in seconds to complete if track is within 7 second margin

  // To avoid sending the same request multiple times in rare cases
  if (
    previousUpdateRequest?.episodeUUID === episodeUUID &&
    previousUpdateRequest?.progressInSeconds === progressInSecondsFinal
  ) {
    return;
  }

  previousUpdateRequest = {
    episodeUUID,
    progressInSeconds: progressInSecondsFinal,
  };

  try {
    await getApiClient().mutate({
      mutation: UpdateMyProgressionByEpisodeUuidDocument,
      variables: {
        progressInSeconds: progressInSecondsFinal,
        progressPercentage,
        episodeUUID,
        appType: getAppType(),
      },
    });
  } catch (error) {
    console.error(
      "Event.PlaybackProgressUpdated: failed to update progression of track",
      track,
      error
    );
  }
}

function getAppType(): AppType {
  const platform = Platform.OS;

  switch (platform) {
    case "android":
      return AppType.Android;
    case "ios":
      return AppType.Ios;
    case "web":
      if (navigator.userAgent.includes("Android")) return AppType.Android;
      return AppType.Web;
    default:
      return AppType.Unknown;
  }
}
